/* eslint-disable max-len */
import {IMPORT_ACTIVITY_STATE as STATE} from './importActivity.types';

export const updateImportNotification = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_IMPORT_NOTIF));
    dispatch(requestSuccess(STATE.UPDATE_IMPORT_NOTIF_SUCCESS, params));
  };
};

export const updateImportNotificationStatus = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_IMPORT_NOTIF));
    dispatch(requestSuccess(STATE.UPDATE_IMPORT_NOTIF_STATUS, params));
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

// DISPATCH

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});
