export const initialState = {
  data: {
    authorization: '',
    profile: [],
    organization: [],
    menus: [],
  },
  isImpersonaMode: false,
  impersonaData: {
    organization_name: '',
  },
};
