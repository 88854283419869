const drawerWidth = 270;

export default (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  dropdownMenu: {
    top: 50,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  guideLink: {
    fontSize: 13,
    fontWeight: 500,
  },
  guideLinkPopup: {
    'fontSize': 13,
    'fontWeight': 500,
    'cursor': 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  defaultBtnAgreement: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'width': '100%',
  },
  hasError: {
    color: 'red',
  },
  photo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  filterBtn: {
    marginTop: 25,
    marginRight: 15,
    marginLeft: 10,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 15,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 4,
    ...theme.mixins.toolbar,
  },
  drawerButton: {
    position: 'absolute',
    right: 7,
    top: 7,
    marginTop: 4,
  },
  drawerButtonShift: {
    display: 'none',
    position: 'absolute',
    right: 7,
    top: 7,
    marginTop: 4,
  },
  appBar: {
    top: 'unset',
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#00AFF0',
    boxShadow: 'none',
  },
  menuShift: {
    display: 'none',
  },
  MenuIconShift: {
    marginLeft: 22,
  },
  titleShift: {
    marginLeft: 12,
    flexGrow: 1,
    textTransform: 'capitalize',
    fontSize: 22,
    fontWeight: '600',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rootWhenOpen: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  rootWhenClosed: {
    width: 'calc(100% - 65px)',
    marginLeft: 65,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoutBtn: {
    'color': 'white',
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#d5d5d5',
    },
    'color': '#000000',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    textTransform: 'capitalize',
    fontSize: 22,
    fontWeight: '600',
  },
  titleApp: {
    position: 'absolute',
    left: 20,
    fontFamily: 'Poppins !important',
    fontSize: '1.5em',
    margin: 'auto',
    fontWeight: 600,
  },
  drawerPaper: {
    'whiteSpace': 'nowrap',
    'width': drawerWidth,
    'transition': theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      width: '20px',
      duration: 120,
    }),
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ededed',
      borderRadius: 10,
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      width: '20px',
    }),
    width: 65,
    [theme.breakpoints.up('sm')]: {
      width: 65,
    },
  },
  headerDrawerOpen: {
    position: 'fixed',
    width: drawerWidth,
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 5px 1px rgba(0,0,0, 0.03)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      width: '20px',
      duration: 120,
    }),
    zIndex: 1300,
  },
  headerDrawerClose: {
    width: 65,
    boxShadow: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      width: '20px',
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  contentWrapper: {
    overflow: 'auto',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: 54,
    marginTop: 8,
    marginBottom: 8,
  },
  nestedActive: {
    paddingLeft: 54,
    textDecoration: 'none',
    backgroundColor: '#dadef1',
    marginTop: 8,
    marginBottom: 8,
  },
  listActive: {
    textDecoration: 'none',
    backgroundColor: '#dadef1',
    marginTop: 8,
    marginBottom: 8,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
  },
  copyright: {
    marginTop: 20,
    bottom: 0,
    display: 'block',
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '0.7rem',
    textAlign: 'center',
    backgroundColor: '#00AFF0',
    color: '#ffff',
    zIndex: 1100,
  },
  chipLoading: {
    marginRight: 20,
    background: 'grey',
    color: 'lightgrey',
  },
  chipFree: {
    'marginRight': 20,
    'backgroundColor': '#e53935',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#ef5350',
    },
    '&:focus': {
      backgroundColor: '#e53935',
    },
  },
  chipPremium: {
    'marginRight': 20,
    'background': '#8bc34a',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#689f38',
    },
    '&:focus': {
      backgroundColor: '#8bc34a',
    },
  },
  chipWaitingPayment: {
    'marginRight': 20,
    'background': '#eaa744',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#d89533',
    },
    '&:focus': {
      backgroundColor: '#d89533',
    },
  },
  chipWaitingApproval: {
    'marginRight': 20,
    'background': '#eaa744',
    'color': 'white',
    // '&:hover': {
    //   backgroundColor: '#d89533',
    // },
    // '&:focus': {
    //   backgroundColor: '#d89533',
    // },
  },
  chipPremiumBankPayment: {
    'marginRight': 20,
    'background': '#8bc34a',
    'color': 'white',
    '&:hover': {
      backgroundColor: '#689f38',
    },
    '&:focus': {
      backgroundColor: '#689f38',
    },
  },
  chipPremiumBank: {
    'marginRight': 20,
    'background': '#8bc34a',
    'color': 'white',
    // '&:hover': {
    //   backgroundColor: '#d89533',
    // },
    // '&:focus': {
    //   backgroundColor: '#d89533',
    // },
  },

  chipIcon: {
    color: 'white',
  },
  avatarMini: {
    width: '40px',
    height: '40px',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    margin: 'auto 5px',
  },
  avatarMiniImg: {
    display: 'inline',
    margin: '0 auto',
    // marginTop: '-25%',
    height: 'auto',
    width: '100%',
  },
  alert: {
    display: 'block',
    textalign: 'center',
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
  },
  alertText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertLink: {
    cursor: 'pointer',
  },
  alertIcon: {
    fontSize: '1.3rem',
    marginRight: 10,
  },
  alertClose: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  logoutContent: {
    minWidth: 400,
  },
  logoCompany: {
    marginRight: 12,
    marginLeft: 10,
    width: 'auto',
    height: 40,
  },
  logoPagii: {
    marginRight: 12,
    marginLeft: 18,
    width: 'auto',
    height: 40,
  },
  logoPagiiShift: {
    marginRight: 12,
    marginLeft: 23,
    width: 'auto',
    height: 40,
  },
  logoCompanyShift: {
    marginRight: 8,
    marginLeft: 13,
    marginBottom: 4,
    width: 'calc(4em - 12px)',
    height: 'auto',
  },
  companyName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textalign: 'start',
    width: '100%',
    fontSize: '1.3em',
    fontFamily: 'Poppins !important',
    margin: '0px 0px 0px 8px',
    fontWeight: 600,
    color: '#656464',
  },
  companyNameShift: {
    display: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textalign: 'center',
    width: '100%',
    fontSize: '1.3em',
    fontFamily: 'Poppins !important',
    margin: 'auto',
    fontWeight: 600,
    color: '#656464',
  },
  MuiButton: {
    startIcon: {
      marginRight: '0',
    },
  },
  loaderColor: {
    margin: 5,
    color: '#00AFF0',
  },
  dialogFooter: {
    padding: 20,
  },
  dialogFooterAgreement: {
    padding: 32,
  },
  dialogContent: {
    paddingTop: '0 !important',
  },
  dialogContentAgreement: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    padding: '32px !important',
  },

  agreementDesc: {
    fontSize: 14,
    fontWeight: 400,
    color: '#444B55',
  },

  paperAgreement: {
    backgroundColor: '#F4F4F4',
    padding: 24,
    border: 0,
    borderRadius: 8,
    overflowY: 'scroll',
    maxHeight: 500,
  },

  agreementTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#444B55',
    marginBottom: 8,
  },

  agreementSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#444B55',
  },
  agreementChildTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: '#444B55',
  },
  agreementChildSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#444B55',
    whiteSpace: 'pre-line',
    verticalAlign: 'bottom',
  },
  wrapperFloating: {
    position: 'fixed',
    bottom: 0,
    right: 20,
    zIndex: 1000,
  },
});
