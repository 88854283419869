/* eslint-disable max-len */
import React from 'react';
import {CKEditor} from 'ckeditor4-react';
import DOMPurify from 'dompurify';
import * as yup from 'yup';
import {isValidURL} from '../../helpers';
import './styles.css';

const MyEditor = ({editorUrl, config, value, onChange, wrapperClass, ...rest}) => {
  return (
    <div className={'my-custom-ck-editor ' + (wrapperClass || '')}>
      <CKEditor
        editorUrl={editorUrl || 'https://cdn.ckeditor.com/4.18.0/standard-all/ckeditor.js'}
        config={{
          extraPlugins: 'autogrow',
          autoGrow_minHeight: 200,
          autoGrow_maxHeight: 800,
          autoGrow_bottomSpace: 50,
          contentsCss: [
            'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
            `body { font-family: 'Poppins'; line-height: 1.0; font-size: 14px; font-weight: 400; min-height: auto; }`,
          ],
          versionCheck: false,
          on: {
            instanceReady: (evt) => {
              const editor = evt.editor;
              editor.on('paste', (evt) => {
                const data = evt.data.dataValue;
                const value = new DOMParser().parseFromString(data || '', 'text/html')?.body?.textContent?.trim();
                const phonePattern = /(^(\+62|62|0)(\d*-?\d+)*)/g;

                if (isValidURL(value)) {
                  evt.data.dataValue = `<a href="${value}" target="_blank">${data}</a>`;
                } else if (phonePattern.test(value)) {
                  evt.data.dataValue = `<a href="tel:${value}" target="_blank">${data}</a>`;
                } else if (yup.string().email().isValidSync(value)) {
                  evt.data.dataValue = `<a href="mailto:${value}" target="_blank">${data}</a>`;
                }
              });
            },
          },
          ...config,
        }}
        initData={value}
        data={value}
        onChange={(event) => {
          const value = event?.editor?.getData();
          onChange && onChange(DOMPurify.sanitize(value));
        }}
        {...rest}
      />
    </div>
  );
};

export default MyEditor;
