/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  additionalPaper: {
    paddingTop: 10,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  paperEmployeeFacilities: {
    paddingTop: 2,
    paddingBottom: 4,
    paddingLeft: 18,
    paddingRight: 18,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  paperMobileFeatureSetting: {
    paddingTop: 2,
    paddingBottom: 18,
    paddingLeft: 18,
    paddingRight: 18,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
  },
  paperTitle: {
    fontSize: '18px',
    fontWeight: '700',
    marginTop: '8px',
    marginBottom: '16px',
  },
  formControl: {
    marginBottom: 24,
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlQR: {
    marginBottom: 16,
    marginTop: 16,
    minWidth: 120,
    marginRight: 16,
  },
  addBtn: {
    'width': '140px',
    'height': '36px',
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  btnAddMore: {
    'marginTop': '36px',
    'marginBottom': '24px',
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  btnAddNew: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'bottom': '5px',
    'height': '45px',
  },
  defaultBtnQR: {
    'height': 38,
    'marginRight': 14,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'paddingLeft': '16px',
    'paddingRight': '16px',
  },
  btnPrint: {
    'height': 40,
    'width': 100,
    'marginBottom': 8,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    'color': '#fff',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#00A2DE',
    },
  },
  infoBtn: {
    'marginTop': 20,
    'backgroundColor': '#4051B6',
    '&:hover': {
      backgroundColor: '#344193',
    },
    'color': '#fff',
  },
  dangerBtn: {
    'float': 'right',
    'minHeight': 45,
    'marginTop': 20,
    'backgroundColor': '#B64040',
    '&:hover': {
      backgroundColor: '#923333',
    },
    'color': '#fff',
  },
  mr: {
    marginRight: 10,
  },
  btnAddContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'flex-start',
    paddingLeft: 12,
  },
  btnAdd: {
    'height': 45,
    'backgroundColor': '#4CAF50',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    'marginRight': 10,
    'minWidth': '96px',
    'fontSize': '0.6125rem',
  },
  btnGenerateContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'flex-start',
    marginBottom: '42px',
  },
  btnSubmitJob: {
    'backgroundColor': '#4CAF50',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  dialogFooter: {
    padding: 20,
  },
  dialogContent: {
    paddingTop: '0 !important',
    minWidth: 600,
  },
  qr: {
    height: 400,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  qrTitle: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: '700',
  },
  subTitle: {
    marginTop: 12,
    fontSize: 16,
    fontWeight: '700',
  },
  subTitleEmployeeFacilities: {
    marginTop: 12,
    marginBottom: 16,
    fontSize: 16,
    fontWeight: '700',
  },
  subTitleQR: {
    marginTop: 4,
    fontSize: 18,
    fontWeight: '700',
  },
  companySubTitle: {
    marginTop: 12,
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 12,
  },
  companyAddress: {
    marginTop: 12,
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 28,
  },
  loaderContainer: {
    height: 400,
    width: 400,
    display: 'flex',
  },
  loader: {
    display: 'flex',
    margin: 'auto',
  },
  loaderText: {
    marginTop: 1,
    marginLeft: 13,
  },
  hidden: {
    visibility: 'hidden',
  },
  qrContainer: {
    display: 'inline-grid',
  },

  cardCompany: {
    marginTop: 12,
  },
  switch: {
    justifyContent: 'center',
    marginBottom: 10,
  },
  photo: {
    width: 'auto',
    height: '190px',
    backgroundSize: 'contain',
    backgroundPosition: 'left',
    right: '11px',
    position: 'relative',
    marginTop: '8px',
    marginBottom: '32px',
  },
  btnRemove: {
    'height': 45,
    'backgroundColor': '#E5AF5A',
    'boxShadow': 'none',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    'marginRight': 10,
  },
  mb10: {
    marginBottom: theme.spacing(2),
  },
  mr30: {
    marginRight: theme.spacing(4),
  },

  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
    'color': 'rgba(0, 0, 0, 0.87);',
  },

  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  tablePaper: {
    color: theme.palette.text.secondary,
    borderRadius: 18,
    boxShadow: 'none',
  },
  tablePaper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    marginBottom: 10,
    borderRadius: 18,
    boxShadow: 'none',
  },
  datatable: {
    marginTop: 25,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  checkBox: {
    width: 70,
  },

  parentFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '16px !important',
    color: '#656464',
  },
  rowFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '450 !important',
    fontSize: '14px !important',
  },
  createUserTitle: {
    color: '#656464',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  hidden: {
    display: 'none',
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  hasError: {
    color: 'red',
  },
  photo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  filterBtn: {
    marginTop: 25,
    marginRight: 15,
    marginLeft: 10,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 15,
  },
  filter: {
    marginRight: 25,
  },
  filterSearch: {
    minWidth: 300,
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  dialogFooter: {
    padding: 20,
  },
  dialogContent: {
    paddingTop: '0 !important',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnCrop: {
    marginTop: 10,
  },
  searchInput: {
    width: '350px',
  },
  syncTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  syncFalse: {
    color: '#34a3ed',
    fontSize: 20,
  },
  syncWarn: {
    color: '#FFCC00',
    fontSize: 20,
  },
  textLink: {
    'color': '#00AFF0',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#00A2DE',
    },
  },
  btnReset: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  btnExport: {
    'backgroundColor': '#00AFF0',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
  },
  btnStyle: {
    width: '2px',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  dropZoneArea: {
    padding: 15,
    minHeight: 160,
    minWidth: 500,
  },
  dropZoneText: {
    color: '#8D8D97',
    fontSize: '1.4em',
  },
  dropZoneIcon: {
    color: '#8D8D97',
  },
  hidden: {
    minWidth: 500,
    visibility: 'hidden',
    height: 0,
    minHeight: 0,
  },
  primary: {
    color: '#00AFF0',
  },
  divisionTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: '#777777',
    marginBottom: 4,
  },
  divisionName: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
    textTransform: 'capitalize',
  },
  backTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#656464',
    marginLeft: 16,
  },
  btnBack: {
    fontSize: 18,
    fontWeight: 700,
    color: '#656464',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#656464',
    marginRight: 8,
    textTransform: 'capitalize',
  },
  approvalList: {
    fontSize: 12,
    fontWeight: 400,
    color: '#969696',
  },
  orderTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#656464',
  },
  paperCheckbox: {
    backgroundColor: '#F9F9FA',
    boxShadow: 'none',
    paddingLeft: 10,
    paddingRight: 16,
    borderRadius: 8,
    marginBottom: 12,
  },
  paperDrag: {
    backgroundColor: '#F9F9FA',
    boxShadow: 'none',
    paddingLeft: 10,
    paddingRight: 16,
    borderRadius: 8,
    marginBottom: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  approvalType: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#959595',
  },
  orderList: {
    fontSize: 18,
    fontWeight: 600,
    color: '#656464',
  },
  approvalTooltip: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
  fontGuide: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  guideLink: {
    fontSize: 14,
    fontWeight: 500,
  },
  guideLinkPopup: {
    'fontSize': 14,
    'fontWeight': 500,
    'cursor': 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  titleGuide: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
  subtitleGuide: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  ApprovalTypeGuide: {
    fontSize: 16,
    fontWeight: 700,
    color: '#656464',
    marginBottom: 12,
  },
  ApprovalOrderGuide: {
    fontSize: 16,
    fontWeight: 700,
    color: '#656464',
    marginBottom: 12,
    marginTop: 32,
  },
  approvalUser: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  dragTextHelper: {
    fontSize: 14,
    fontWeight: 400,
    color: '#BCBCBC',
    fontStyle: 'italic',
    marginTop: 18,
    marginBottom: 18,
  },
  toolTipWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  btnContainer: {
    marginTop: 12,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
});
