/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Link,
  Grid,
  Typography,
} from '@material-ui/core';
import {REGISTER_STATE as STATE} from '../../redux/registerUser/registerUser.types';
import {toast, isObject, checkMobile} from '../../helpers';
import {MInput, MButton, MInputMask, MCheckboxItem} from '../../components/form';
import styles from './styles';
import {getOrganizationTypeWithoutAuth} from '../../redux/organization/organization.actions';
import {submitRegister, clearState} from '../../redux/registerUser/registerUser.actions';
import Recaptcha from 'react-recaptcha';
import registerBackground from '../../../src/assets/img/login/registerBG.png';
import pagiiLogo from '../../../src/assets/img/login/pagiiLogo.png';
import {apiService, ENDPOINTS} from '../../config';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const REGISTER_LINK = '/register-user';

class RegisterUser extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      name: null,
      email: null,
      password: null,
      password_confirm: null,
      phone: null,
      gender: null,
      // purpose: null,
      // organization_name: null,
      // organization_addres: null,
      // pic_name: null,
      // pic_email: null,
      // pic_phone: null,
      // organization_type_id: null,
      // number_of_employees: null,
      // feature: {remote: false, office: false, business: false},
      // referral_id: null,
      // disabled_reff_id: false,
      showOthers: false,
      errorMessage: null,
      isVerified: false,
      tnc: false,
    };
    this.initialState = this.state;
  }

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
      },
      secondary: {
        main: '#FFFFFF',
      },
    },
    overrides: {
      MuiTypography: {
        body1: {
          color: '#FFFFFF',
          fontSize: '13px',
        },
        colorPrimary: {
          color: '#FFBD56',
        },
      },
      MuiButton: {
        label: {
          fontWeight: 'bold',
        },
      },
      MuiIconButton: {
        label: {
          color: '#FFFFFF',
          marginLeft: '4px',
        },
      },
      MuiFormLabel: {
        root: {
          color: '#FFFFFF !important',
          fontSize: '18px !important',
          fontFamily: 'Poppins !important',
          marginBottom: '18px !important',
        },
      },
      MuiInput: {
        formControl: {
          marginTop: '18px !important',
        },
        underline: {
          '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 1) !important',
          },
        },
      },
      MuiInputBase: {
        input: {
          color: '#C6F0FF !important',
          fontSize: '14px! important',
          fontFamily: 'Poppins !important',
        },
      },
      MuiInputLabel: {
        formControl: {
          fontSize: '18px !important',
          left: 'inherit',
        },
      },
    },
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;
    const detectEventId = localStorage.getItem('event_id') ?? null;
    const redirectToPagiiEvent = process.env.REACT_APP_PAGII_EVENT_WEB ?? '';
    const paramsStorage = localStorage.getItem('event_params');

    if (isSuccess) {
      if (trigger === STATE.REGISTER_SUCCESS) {
        this.handleResetForm(true);

        if (detectEventId) {
          apiService.post(
            ENDPOINTS.AUTH.LOGIN,
            {
              email: this.state.email,
              password: this.state.password,
            },
            {
              headers: {platform: null},
            },
          ).then(
            (response) => {
              const {data} = response?.data;
              let _url = `${redirectToPagiiEvent}post-register`;
              localStorage.removeItem('event_id');
              localStorage.removeItem('event_params');

              const _urlParams = new URLSearchParams();

              try {
                const _params = JSON.parse(paramsStorage);
                _urlParams.append('token', data?.userToken);

                _params.forEach((item) => {
                  if (item[0] && item[1]) {
                    _urlParams.append(item[0], item[1]);
                  }
                });

                _url = _url + '?' + _urlParams.toString();
                window.location.replace(_url);
              } catch (error) {
                console.log('ERROR PARSING STORAGE', {error});
              }
            },
            (error) => {
              const response = error?.response?.data;
              toast('error', response?.data?.message || 'Please open Pagii app to login with your new account');
            },
          );
        }
      }
    }

    if (isError && message && isObject(message)) this.setState({errorMessage: message});
    if (isError && message && !isObject(message)) toast('error', message);
  }

  saveEventId = () => {
    const isMobile = checkMobile();
    const {
      location: {
        search,
      },
    } = this.props;

    const event_id = new URLSearchParams(search).get('event_id');

    if (event_id && search && search?.length) {
      const params = Array.from(new URLSearchParams(search).entries());
      localStorage.setItem('event_params', JSON.stringify(params));
    }

    if (event_id) {
      localStorage.setItem('event_id', event_id);
    } else {
      if (!isMobile) window.location.href = 'https://pagii.co/#section-cta';
    }
  }

  componentDidMount() {
    this.saveEventId();

    this.props.getOrganizationTypeWithoutAuth();
    this.props.history.push(REGISTER_LINK);
  }

  componentWillUnmount() {
    this.props.clearState();
    this.props.history.push(REGISTER_LINK);
  }

  checkSelectedFeature = () => {
    const {errorMessage} = this.state;
    const {remote, office, business} = this.state.feature;
    const validate = () => {
      if (!remote && !office && !business) return false;
      return true;
    };

    const isValid = validate();
    const message = 'Please select at least 1 (one) attendance type';
    if (!isValid) this.setState({errorMessage: {...errorMessage, feature: message}});
    return isValid;
  }

  handleResetForm = () => {
    this.setState(this.initialState);
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    const regexp = new RegExp(/\d/g);
    const validateInt = value.match(regexp);
    if (name === 'name') {
      if (value.length === 0) {
        this.setState({
          [name]: value,
          errorMessage: {
            ...this.state.errorMessage,
            [name]: null,
          },
        });
      }
      if (validateInt) {
        this.setState({
          [name]: value,
          errorMessage: {
            ...this.state.errorMessage,
            [name]: `maaf nama tidak boleh menggunakan angka`,
          },
        });
      } else {
        this.setState({
          [name]: value,
          errorMessage: {
            ...this.state.errorMessage,
            [name]: null,
          },
        });
      }
      } else {
        if (value.length === 0) {
          this.setState({
            [name]: value,
            errorMessage: {
              ...this.state.errorMessage,
              [name]: null,
            },
          });
        } else {
          this.setState({
            [name]: value,
            errorMessage: {
              ...this.state.errorMessage,
              [name]: null,
            },
          });
        }
    }
  }

  handleTypeChange = (event) => {
    const {name, value} = event.target;
    const showOthers = (value === 0) ? true : false;
    this.setState({
      showOthers,
      [name]: value,
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleBackToNative = (e) => {
    const payload = {type: 'backToNative'};
    const json = (JSON.stringify(payload));
    window.postMessage(json, '*');
  }

  handleBackToWeb = (e) => {
    e.preventDefault();
    const baseURL = window.location.origin;
    window.location.href = baseURL + '/login';
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name, email, phone, password, password_confirm, isVerified, gender, tnc,
    } = this.state;

    const event_id = localStorage.getItem('event_id');

    if (event_id && !tnc) {
      toast('error', 'Please make sure you have agreed to our terms and conditions');
      return;
    }

    if (isVerified) {
      const payload = {
        ...(name && {name: name.trim()}),
        ...(email && {email: email.trim()}),
        ...(password && {password: password.trim()}),
        ...(password_confirm && {password_confirm: password_confirm.trim()}),
        ...(phone && {phone: phone.trim()}),
        gender,
        // ...(purpose && {purpose}),
        // ...(organization_name && {organization_name}),
        // ...(pic_name && {pic_name}),
        // ...(pic_email && {pic_email}),
        // ...(pic_phone && {pic_phone}),
        // ...(organization_type_id && {organization_type_id}),
        // ...(organization_addres && {organization_addres}),
        // ...(number_of_employees && {number_of_employees}),
        // ...(referral_id && {referral_id}),
      };
      const params = {event_id: event_id};

      this.props.submitRegister(payload, params);
    } else {
      toast('error', 'Please verify captcha');
    }
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  onloadCallback = () => {
    this.setState({
      isVerified: false,
    });
  }
  expiredCallback = (response) => {
    if (!response) {
      this.setState({
        isVerified: false,
      });
    }
  }

  handleCheckboxChange = (event) => {
    const {errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState({
      errorMessage: {...errorMessage, [name]: null},
      [name]: checked,
    });
  }

  renderForm = () => {
    const {classes, isLoading} = this.props;
    const {
      name, email, phone, password, password_confirm, errorMessage, gender, tnc,
    } = this.state;

    const isMobile = checkMobile();
    const detectEventId = localStorage.getItem('event_id') ?? null;

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <div className={classes.formWrapper}>
          <form onSubmit={this.handleSubmit}>
            {/* <Grid container>
              <Grid item xs={12} style={{marginLeft: '2px', marginBottom: '20px', marginTop: '8px'}}>
                <a href="/" >
                  <img src={buttonBack} alt="back" />
                </a>
              </Grid>
            </Grid> */}
            <Grid container>
              <Grid item xs={12} style={{marginTop: '12px'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={pagiiLogo}
                    style={{
                      marginBottom: '36px',
                    }}
                    alt="pagiiLogo"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    fontFamily: 'Poppins !important',
                    marginLeft: '4px',
                  }}>
                  User Register
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'regular',
                    fontFamily: 'Poppins !important',
                    marginLeft: '5px',
                    marginTop: '6px',
                    marginBottom: '6px',
                  }}>
                  Create account to start using Pagii
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="full-name"
                  name="name"
                  label="Name"
                  placeholder="Your fullname"
                  defaultValue={name}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.name ? errorMessage.name : undefined}
                  autoFocus
                  fullWidth
                  variant={isMobile ? 'standard' : 'standard'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControl style={{margin: 0, marginTop: '8px'}}>
                  <Typography style={{marginLeft: '4px'}}>Gender</Typography>

                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender"
                    onChange={this.handleInputChange}
                    value={gender}
                    style={{display: 'flex', alignItems: 'center', gap: '16px'}}
                  >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                  </RadioGroup>

                  {!errorMessage?.gender ? null : <FormHelperText style={{color: 'red', textTransform: 'capitalize'}}>{errorMessage.gender}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Your email address"
                  defaultValue={email}
                  onBlur={this.handleInputChange}
                  error={errorMessage?.email?.toLowerCase()?.search('registered') ? 'You already registered as Pagii user. Please continue to join this event from Pagii mobile on menu More Apps' : errorMessage?.email}
                  fullWidth
                  type="email"
                  variant={isMobile ? 'standard' : 'standard'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInputMask
                  classNameFC={classes.formControl}
                  name="phone"
                  label="Phone Number"
                  placeholder="Phone"
                  type="number"
                  defaultValue={phone}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.phone ? errorMessage.phone : undefined}
                  fullWidth
                  variant={isMobile ? 'standard' : 'standard'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="password"
                  name="password"
                  label="Password"
                  placeholder="Your Password"
                  defaultValue={password}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.password ? errorMessage.password : undefined}
                  fullWidth
                  type='password'
                  variant={isMobile ? 'standard' : 'standard'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="password_confirm"
                  name="password_confirm"
                  label="Password Confirm"
                  placeholder="Confirm your password"
                  defaultValue={password_confirm}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.password_confirm ? errorMessage.password_confirm : undefined}
                  fullWidth
                  type='password'
                  variant={isMobile ? 'standard' : 'standard'}
                />
              </Grid>
            </Grid>
            <div className={classes.btnContainer} style={{marginTop: 12}}>
              <Recaptcha
                sitekey={RECAPTCHA_SITE_KEY}
                render="explicit"
                elementID="a1"
                onloadCallback={this.onloadCallback}
                expiredCallback={this.expiredCallback}
                verifyCallback={this.verifyCallback}
                // onloadCallback={true}
                // expiredCallback={true}
                // verifyCallback={true}
              />
            </div>
            {!detectEventId ? null :
              <Grid item xs={12} style={{marginBottom: '16px'}}>
                <MCheckboxItem
                  name="tnc"
                  label="By proceeding, you acknowledge that your information will be visible to the event creator. We value your privacy and assure you that your data will be handled securely. Additionally, by this you consent to receive our future offerings or information."
                  onChange={(e) => {
                    const {name, checked} = e?.target;
                    this.setState({...this.state, [name]: checked});
                  }}
                  checked={tnc}
                />
              </Grid>}
            <div className={classes.btnContainer} style={{marginBottom: '18px'}}>
              <MButton
                className={classes.registerBtn}
                id="submit-register"
                label="Submit"
                color="primary"
                type="submit"
                icon
                loading={isLoading}
                // disabled={!tnc}
              />
            </div>
            <Grid item xs={12}>
              <div style={{fontSize: 13, marginBottom: 18, textAlign: 'center'}}>
                <span style={{color: 'white'}}>By registering, you are agree to our </span>
                <Link href="https://pagii.co/kebijakan-privasi" target="_blank" rel="noopener noreferrer">
                  <span>Privacy Policy </span>
                </Link>
                <span style={{color: 'white'}}>also </span>
                <Link href="https://pagii.co/ketentuan-pengguna/" target="_blank" rel="noopener noreferrer">
                  <span>Terms and Conditions</span>
                </Link>
              </div>
            </Grid>
          </form>
        </div>

      </MuiThemeProvider>
    );
  };

  renderThankYou = () => {
    const {classes} = this.props;
    const isMobile = checkMobile();
    return (
      <div className={classes.center}>
        <h5 className={`${classes.formTitle} ${classes.center}`}>Thank You for Registering in Pagii</h5>
        <h7 className={classes.center}>
          Please check your E-mail for the confirmation.
        </h7>
        <div className={classes.btnThankYouContainer}>
          <Link
            href={'/finish-register'}
            className={classes.registerBtn}
            onClick={isMobile ? this.handleBackToNative : this.handleBackToWeb}
          >
            Back to Login
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const {classes, isSuccess} = this.props;
    const isMobile = checkMobile();
    const detectEventId = localStorage.getItem('event_id') ?? null;

    return (
      <div className={`${classes.root} ${classes.adjustHeight}`}
        style={{
          backgroundImage: `url(${registerBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={classes.paper}>
          {
              isSuccess ?
                this.renderThankYou() :
                (isMobile || detectEventId) ?
                  this.renderForm() :
                  undefined
          }
        </div>
      </div>
    );
  }
}

const stateToProps = ({auth, organization, register}) => ({
  token: auth.data.authorization,
  isLoading: register.isLoading,
  isError: register.isError,
  isSuccess: register.isSuccess,
  message: register.message,
  trigger: register.trigger,
  orgTypeData: organization.orgTypeData,
});

const dispatchToProps = (dispatch) => ({
  getOrganizationTypeWithoutAuth: (params) => dispatch(getOrganizationTypeWithoutAuth(params)),
  submitRegister: (params, payload) => dispatch(submitRegister(params, payload)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(RegisterUser));
