export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  importActivityData: [],
  importActivityDataByOrg: [],
  importActivityMeta: {page: 1, total: 0},
  message: {},
  importNotificationData: [],
};

export const IMPORT_ACTIVITY_STATE = {
  REQUEST: 'IMPORT_ACTIVITY_REQUEST',
  REQUEST_FAILED: 'IMPORT_ACTIVITY_REQUEST_FAILED',
  CLEAR_STATE: 'IMPORT_ACTIVITY_CLEAR_STATE',
  FETCH: 'IMPORT_ACTIVITY_FETCH',
  FETCH_SUCCCESS: 'IMPORT_ACTIVITY_FETCH_SUCCCESS',
  FETCH_FAILED: 'IMPORT_ACTIVITY_FETCH_FAILED',
  UPDATE_IMPORT_NOTIF: 'UPDATE_IMPORT_NOTIF',
  UPDATE_IMPORT_NOTIF_FAILED: 'UPDATE_IMPORT_NOTIF_FAILED',
  UPDATE_IMPORT_NOTIF_SUCCESS: 'UPDATE_IMPORT_NOTIF_SUCCESS',
  UPDATE_IMPORT_NOTIF_STATUS: 'UPDATE_IMPORT_NOTIF_STATUS',
};
