/* eslint-disable max-len */
import {REGISTER} from '../../services';
import {REGISTER_STATE as STATE} from './register.types';
import {transformObject, isArray} from '../../helpers';

export const submitRegister = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.REQUEST));
    REGISTER.postRegister(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.REGISTER_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.REGISTER_FAILED, message));
            },
        );
  };
};
export const registerSSO = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.REQUEST));
    REGISTER.registerSSO(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.REGISTER_SSO_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.REGISTER_SSO_FAILED, message));
            },
        );
  };
};

export const submitApproval = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.APPROVAL));
    REGISTER.postApproval(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.APPROVAL_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.APPROVAL_FAILED, message));
            },
        );
  };
};

export const ssoApproval = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.APPROVAL_SSO));
    REGISTER.ssoApproval(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.APPROVAL_SSO_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.APPROVAL_SSO_FAILED, message));
            },
        );
  };
};

export const submitApprovalUser = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.APPROVAL_USER));
    REGISTER.postApprovalUser(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.APPROVAL_USER_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.APPROVAL_USER_FAILED, message));
            },
        );
  };
};

export const getRegisterAnalytics = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.ANALYTICS_REGISTER));
    REGISTER.getRegisterAnalytics(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ANALYTICS_REGISTER_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.ANALYTICS_REGISTER_FAILED, response.data.message));
            },
        );
  };
};

export const getRegisterAnalyticsDetails = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ANALYTICS_DETAILS));
    REGISTER.getRegisterAnalyticsDetails(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ANALYTICS_DETAILS_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_ANALYTICS_DETAILS_FAILED, response.data.message));
            },
        );
  };
};

export const submitApprovalOrg = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.APPROVAL));
    REGISTER.postApprovalOrg(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.APPROVAL_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.APPROVAL_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});
