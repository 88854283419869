/* eslint-disable max-len */
import {initialState, LOGIN_STATE as STATE} from './login.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        trigger: action.type,
      };
    case STATE.LOGIN_SSO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        trigger: action.type,
      };
    case STATE.SSO_VALIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        trigger: action.type,
        ssoData: action.payload,
      };
    case STATE.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.LOGIN_SSO_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.LOGIN_AS_HR:
      return {
        ...state,
        isSuccessAssignHR: true,
        isLoadingAssignHR: false,
        isErrorAssignHR: false,
        message: action?.payload?.data?.message,
      };
    case STATE.LOGIN_AS_HR_SUCCESS:
      return {
        ...state,
        isSuccessAssignHR: true,
        isLoadingAssignHR: false,
        isErrorAssignHR: false,
        message: action?.payload?.data?.message,
      };
    case STATE.LOGIN_AS_HR_FAILED:
      return {
        ...state,
        isSuccessAssignHR: false,
        isLoadingAssignHR: false,
        isErrorAssignHR: true,
        message: action?.payload?.data?.message,
      };
    case STATE.LOGOUT_AS_HR:
      return {
        ...state,
        isSuccessAssignHR: true,
        isLoadingAssignHR: false,
        isErrorAssignHR: false,
        message: action?.payload?.data?.message,
      };
    case STATE.LOGOUT_AS_HR_SUCCESS:
      return {
        ...state,
        isSuccessAssignHR: true,
        isLoadingAssignHR: false,
        isErrorAssignHR: false,
        message: action?.payload?.data?.message,
      };
    case STATE.LOGOUT_AS_HR_FAILED:
      return {
        ...state,
        isSuccessAssignHR: false,
        isLoadingAssignHR: false,
        isErrorAssignHR: true,
        message: action?.payload?.data?.message,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    case STATE.REDIRECT:
      return {
        ...state,
        redirectURL: action.payload,
      };
    case STATE.REDIRECT_SSO:
      return {
        ...state,
        redirectURL: action.payload,
      };
    default:
      return state;
  }
};
