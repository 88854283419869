export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  message: {
    name: '',
    email: '',
  },
  redirectURL: null,
  redirectSSO: null,
  ssoData: null,

  isLoadingAssignHR: false,
  isSuccessAssignHR: false,
  isErrorAssignHR: false,
};

export const LOGIN_STATE = {
  REQUEST: 'LOGIN_REQUEST',
  REQUEST_FAILED: 'LOGIN_REQUEST_FAILED',
  CLEAR_STATE: 'LOGIN_CLEAR_STATE',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
  REDIRECT: 'LOGIN_SET_REDIRECT',

  LOGIN_SSO: 'LOGIN_SSO',
  LOGIN_SSO_SUCCESS: 'LOGIN_SSO_SUCCESS',
  LOGIN_SSO_FAILED: 'LOGIN_SSO_FAILED',
  LOGOUT_SSO: 'LOGOUT_SSO',
  REDIRECT_SSO: 'LOGIN_SET_REDIRECT_SSO',

  SSO_VALIDATE: 'SSO_VALIDATE',
  SSO_VALIDATE_SUCCESS: 'SSO_VALIDATE_SUCCESS',
  SSO_VALIDATE_FAILED: 'SSO_VALIDATE_FAILED',

  UPDATE_AUTHORIZATION: 'UPDATE_AUTHORIZATION',
  UPDATE_AUTHORIZATION_SUCCESS: 'UPDATE_AUTHORIZATION_SUCCESS',
  UPDATE_AUTHORIZATION_FAILED: 'UPDATE_AUTHORIZATION_FAILED',

  LOGIN_AS_HR: 'REQUEST_LOGIN_AS_HR',
  LOGIN_AS_HR_SUCCESS: 'REQUEST_LOGIN_AS_HR_SUCCESS',
  LOGIN_AS_HR_FAILED: 'REQUEST_LOGIN_AS_HR_FAILED',

  LOGOUT_AS_HR: 'REQUEST_LOGOUT_AS_HR',
  LOGOUT_AS_HR_SUCCESS: 'REQUEST_LOGOUT_AS_HR_SUCCESS',
  LOGOUT_AS_HR_FAILED: 'REQUEST_LOGOUT_AS_HR_FAILED',
};
