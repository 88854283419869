/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {
  withStyles,
  Paper,
  Grid,
  FormControlLabel,
  FormGroup,
  Typography,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {HelpOutline} from '@material-ui/icons';
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import styles from './styles';
import {MButton} from '../../../components/form';
import {ReactComponent as Send} from '../../../assets/icon/mobile-feature-setting/send.svg';
import {useDispatch, useSelector} from 'react-redux';
import {updateMobileFeatureSettings} from '../../../redux/organization/organization.actions';

const getMuiTheme = () =>
  createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
  });

const tooltipTheme = () =>
  createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  });

function MobileFeatureSettings({classes}) {
  const dispatch = useDispatch();
  const {orgStatusData: data, isLoading} = useSelector((state) => state?.organization);
  const {data: settings} = useSelector((state) => state?.generalSetting);

  const [state, setState] = useState({
    is_reminder_checkout_office: Boolean(data?.is_reminder_checkout_office),
    is_reminder_checkout_remote: Boolean(data?.is_reminder_checkout_remote),
    is_more_apps: Boolean(data?.more_apps),
    cash_advance: Boolean(settings?.find((item) => item?.key === 'cash_advance')?.value),
    office_inventory: Boolean(settings?.find((item) => item?.key === 'office_inventory')?.value),
  });

  useEffect(() => {
    setState({
      is_reminder_checkout_office: Boolean(data?.is_reminder_checkout_office),
      is_reminder_checkout_remote: Boolean(data?.is_reminder_checkout_remote),
      is_more_apps: Boolean(data?.more_apps),
      cash_advance: Boolean(settings?.find((item) => item?.key === 'cash_advance')?.value),
      office_inventory: Boolean(settings?.find((item) => item?.key === 'office_inventory')?.value),
    });
  }, [data, settings]);

  const handleChange = (event) => {
    const {name, checked} = event?.target || {};

    setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = () => {
    dispatch(updateMobileFeatureSettings(state));
  };

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paperMobileFeatureSetting}>
        <div>
          <h5 className={classes.subTitleEmployeeFacilities}>
            Employee Facilities Setting
          </h5>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Grid container>
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="office_inventory"
                        checked={state?.office_inventory}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography>Activate office inventory</Typography>
                        <MuiThemeProvider theme={tooltipTheme()}>
                          <Tooltip
                            title="Turning on the toggle will activate feature Office Inventory on Pagii mobile and allow you to recap the data on this website. You have to set the default data on Master Inventory first. Turning it off will disable the feature."
                            style={{marginLeft: 6, bottom: 1}}
                          >
                            <IconButton>
                              <HelpOutline />
                            </IconButton>
                          </Tooltip>
                        </MuiThemeProvider>
                      </div>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="cash_advance"
                        checked={state?.cash_advance}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography>Activate cash advance</Typography>
                        <MuiThemeProvider theme={tooltipTheme()}>
                          <Tooltip
                            title="Turning on the toggle will activate feature Cash Advance on Pagii mobile and allow you to recap the data on this website. Turning it off will disable the feature."
                            style={{marginLeft: 6, bottom: 1}}
                          >
                            <IconButton>
                              <HelpOutline />
                            </IconButton>
                          </Tooltip>
                        </MuiThemeProvider>
                      </div>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </div>

        <div>
          <h5 className={classes.subTitleEmployeeFacilities}>
            Show More Feature on Mobile User
          </h5>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Grid container>
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="is_more_apps"
                        checked={state?.is_more_apps}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography>Button More Apps</Typography>
                        <MuiThemeProvider theme={tooltipTheme()}>
                          <Tooltip
                            title="Turning on the toggle will show the button 'More Apps' on Pagii mobile. Turning it off will disable the feature."
                            style={{marginLeft: 6, bottom: 1}}
                          >
                            <IconButton>
                              <HelpOutline />
                            </IconButton>
                          </Tooltip>
                        </MuiThemeProvider>
                      </div>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </div>

        <div>
          <h5 className={classes.subTitleEmployeeFacilities}>
            Reminder Notification
          </h5>
          <MuiThemeProvider theme={getMuiTheme()}>
            <Grid container>
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="is_reminder_checkout_remote"
                        checked={state?.is_reminder_checkout_remote}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography>Activate remote working</Typography>
                        <MuiThemeProvider theme={tooltipTheme()}>
                          <Tooltip
                            title="Turning on the toggle will activate checking-out reminder on Pagii mobile for Remote Working feature. Turning it off will disable the feature."
                            style={{marginLeft: 6, bottom: 1}}
                          >
                            <IconButton>
                              <HelpOutline />
                            </IconButton>
                          </Tooltip>
                        </MuiThemeProvider>
                      </div>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="is_reminder_checkout_office"
                        checked={state?.is_reminder_checkout_office}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography>Activate office working</Typography>
                        <MuiThemeProvider theme={tooltipTheme()}>
                          <Tooltip
                            title="Turning on the toggle will activate checking-out reminder on Pagii mobile for Office Working feature. Turning it off will disable the feature."
                            style={{marginLeft: 6, bottom: 1}}
                          >
                            <IconButton>
                              <HelpOutline />
                            </IconButton>
                          </Tooltip>
                        </MuiThemeProvider>
                      </div>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid container>
                <MButton
                  className={classes.defaultBtn}
                  label="Update Setting"
                  icon={<Send />}
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </div>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(MobileFeatureSettings);
