/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {
  Button,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Refresh,
  Send,
  ArrowBack,
  Edit,
  Delete,
} from '@material-ui/icons';
import EditLogo from '../../../assets/img/edit.svg';
import DeleteLogo from '../../../assets/img/delete.svg';
import {toast, isObject, showPopup, convDate} from '../../../helpers';
import {MInput, MKeyBoardDatepicker, MButton, ModalTitle, MSelect, MDatepicker} from '../../../components/form';
import {Datatable} from '../../../components';
import _ from 'lodash';
import moment from 'moment';
import {MASTER_HOLIDAY_STATE as STATE} from '../../../redux/masterHoliday/masterHoliday.types';
import {LEAVE_CONFIG_STATE as LEAVE_STATE} from '../../../redux/leaveConfig/leaveConfig.types';
import {
  getMasterHoliday,
  createMasterHoliday,
  deleteMasterHoliday,
  updateMasterHoliday,
  clearState,
} from '../../../redux/masterHoliday/masterHoliday.actions';
import {getLeaveConfig} from '../../../redux/leaveConfig/leaveConfig.actions';

class MasterHoliday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      completed: null,
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      modalFormShow: false,
      isEdit: false,
      duration_total: 0,
      remaining_quota: null,
      isLoading: false,
      dateShow: false,
      leaveData: null,
      formData: {
        id: null,
        name: null,
        leave_id: null,
        start_date: null,
        end_date: null,
        total_days: '0',
        is_leave: true,
      },
      totalDuration: 0,
      creatable: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchLeave();
    this.fetchMaster();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // const {trigger, isSuccess, isError, exportData} = nextProps;
    const {trigger, isSuccess, isError, message, leaveTrigger, leaveSuccess, leaveConfigData} = nextProps;

    // if (trigger === STATE.EXPORT_SUCCESS && isSuccess && exportData) {
    //   toast('success', 'File successfully exported & downloaded');
    // }

    // if (isError && message && !isObject(message)) toast('error', message);

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS || trigger === STATE.UPDATE_SUCCESS || trigger === STATE.DROP_SUCCESS) {
        this.handleResetForm(true);
        this.handleReload();
        toast('success', message);
      }
    }

    if (leaveSuccess) {
      if (trigger === STATE.FETCH_SUCCCESS) {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get('id') * 1;
        const currentYear = moment().year();

        for (let i = 0; i < leaveConfigData.length; i++) {
          if (leaveConfigData[i].id === id) {
            this.setState({
              leaveData: leaveConfigData[i],
            });
            this.setState({
              creatable: leaveConfigData[i].year >= currentYear,
            });
          }
        }
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentDidMount() {
    // this.handleCheckQueryParam();
  }

  fetchLeave() {
    const {page, limit} = this.state;
    this.props.getLeaveConfig({page, limit});
  }

  fetchMaster() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const {page, limit} = this.state;
    this.props.getMasterHoliday(id, {page, limit});
    // this.setState({remaining_quota: getData[0].remaining_quota})
  }

  handleFormToggle = (type, data = null) => {
    const {masterHolidayData, leaveConfigData} = this.props;
    const {leaveData} = this.state;


    const masterMap = masterHolidayData;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id_url = url.searchParams.get('id');
    const data_quota = leaveConfigData;
    // let getData = data_quota.filter((value) => {
    //   return value.id === id_url
    // })
    const datas = masterMap.filter((value) => {
      if (data !==null) {
        return value.name === data[1];
      }
    });


    if (type === 'edit') {
      this.setState({
        isEdit: true,
        modalFormShow: !this.state.modalFormShow,
        remaining_quota: leaveData.remaining_quota,
        formData: {
          id: datas[0].id,
          name: datas[0].name,
          leave_id: parseInt(datas[0].leave_id),
          start_date: datas[0].start_date,
          end_date: datas[0].end_date,
          total_days: datas[0].total_days,
          is_leave: datas[0].is_leave === 1 ? true : false,
        },
        totalDuration: datas[0].total_days + leaveData.remaining_quota,
      });
    } else {
      this.handleResetForm();
      this.setState({
        isEdit: false,
        modalFormShow: !this.state.modalFormShow,
        remaining_quota: leaveData.remaining_quota,
        totalDuration: leaveData.remaining_quota,
      });
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      formData: {
        name: null,
        leave_id: null,
        start_date: null,
        end_date: null,
        total_days: null,
        is_leave: true,
      },
      errorMessage: null,
      modalFormShow: false,
    });
    if (closeModal) this.setState({showModal: false});
  }

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
    };
    this.setState(state, () => this.fetchMaster());
    // this.toggleResetDatatable(true);
  };

  handleReload = (params) => {
    this.setState(
      params,
      () => this.fetchMaster(), this.fetchLeave(),
    );
  }

  handleDateChange = (params) => {
    const new_end_date = params.name === 'start_date' && moment(params.value).diff(moment(this.state.formData.end_date), 'days') > 0 ? convDate(params.value) : convDate(params.value) || this.state.formData.end_date;

    this.setState({
      dateShow: false,
      formData: {
        ...this.state.formData,
        [params.name]: params.value ? convDate(params.value) : null,
        end_date: new_end_date,
      },
      errorMessage: {...this.state.errorMessage, [params.name]: null},
    }, () => {
      const {start_date, end_date} = this.state.formData;
      if (start_date && end_date) {
        const new_start_date = start_date.split('-');
        const new_end_date = end_date.split('-');
        const a = moment([new_end_date[0], new_end_date[1] - 1, new_end_date[2]]);
        const b = moment([new_start_date[0], new_start_date[1] - 1, new_start_date[2]]);
        const c = a.diff(b, 'days');
        this.setState({
          dateShow: false,
          formData: {
            ...this.state.formData,
            total_days: c + 1,
          },
          errorMessage: {...this.state.errorMessage, [params.name]: null},

        });
      }
    });
  }


  handleSelectChange = (event) => {
    this.setState({
      formData: {...this.state.formData,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    this.setState({
      formData: {...this.state.formData,
        leave_id: id,
        [name]: value ? value : null,
      },
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleConfirmDelete = (data) => {
    const {masterHolidayData} = this.props;
    const masterMap = masterHolidayData;
    const idGet = masterMap.filter((value) => {
      return value.name === data[1];
    });
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteMasterHoliday(idGet[0].id),
    );
  }

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState({[name]: value, page: null}, () => this.fetchMaster());
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null}, () => this.fetchMaster());
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {createMasterHoliday, updateMasterHoliday, leaveConfigData, masterHolidayData} = this.props;
    const {isEdit, leaveData, totalDuration} = this.state;
    const {id, name, leave_id, start_date, end_date, total_days, is_leave} = this.state.formData;
    const newData = masterHolidayData.filter((value) => {
return value.id === id;
});

    if (total_days > totalDuration && is_leave) {
      return toast('error', `You have ${totalDuration} days remaining quota`);
    }


    let params;
    if (isEdit) {
      if (newData[0].start_date === start_date && newData[0].end_date === end_date ) {
        params = {
          ...(name && {name}),
          ...(leave_id && {leave_id}),
          ...(start_date && {start_date}),
          ...(end_date && {end_date}),
          ...(total_days && {total_days}),
          is_leave,
        };
      } else if (newData[0].end_date === end_date) {
        params = {
          ...(name && {name}),
          ...(leave_id && {leave_id}),
          ...(start_date && {start_date}),
          ...(end_date && {end_date}),
          ...(total_days && {total_days}),
          is_leave,
        };
      } else if (newData[0].start_date === start_date) {
        params = {
          ...(name && {name}),
          ...(leave_id && {leave_id}),
          ...(start_date && {start_date}),
          ...(end_date && {end_date}),
          ...(total_days && {total_days}),
          is_leave,
        };
      } else {
        params = {
          ...(name && {name}),
          ...(leave_id && {leave_id}),
          ...(start_date && {start_date}),
          ...(end_date && {end_date}),
          ...(total_days && {total_days}),
          is_leave,
        };
      }
    } else {
      params = {
        ...(name && {name}),
        ...(leave_id && {leave_id}),
        ...(start_date && {start_date}),
        ...(end_date && {end_date}),
        ...(total_days && {total_days}),
        is_leave,
      };
    }
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id_url = parseInt(url.searchParams.get('id'));
    const datas = leaveConfigData;
    const getData = _.find(datas, {id: id_url});
    this.setState({
      remaining_quota: getData.remaining_quota,
    });

    const errors = {};

    if (!name || !start_date || !end_date) {
      if (!name) errors.name = 'Name Leave is required';
      if (!start_date) errors.start_date = 'Start Date is required';
      if (!end_date) errors.end_date = 'End Date is required';
      this.setState({errorMessage: errors});
    } else {
      return (!isEdit) ? createMasterHoliday(params) : updateMasterHoliday(id, params);
    }
  }

  renderModalForm = (params) => {
    const {
      classes,
      leaveConfigData,
    } = this.props;
    const {
      modalFormShow,
      isEdit,
      isLoading,
      errorMessage,
      remaining_quota,
    } = this.state;
    const {name, start_date, end_date, is_leave, total_days} = this.state.formData;
    const titleDialog = isEdit ? 'Update Master Holiday' : 'Create Master Holiday';
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const datas = leaveConfigData;
    const getData = datas?.find((value) => {
      return value.id === Number(id);
    });
    const options = [
      {id: true, name: 'Memotong Cuti Tahunan'},
      {id: false, name: 'Tidak Memotong Cuti Tahunan'},
    ];
    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
        maxWidth='md'
        fullWidth={true}
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs={6} className="mb-4">
                <MInput
                  classNameFC={classes.formControl}
                  name="years"
                  label="Years"
                  placeholder="2020"
                  value={getData?.year || ''}
                  type="number"
                  // onBlur={this.handleInputChange}
                  maxLength={4}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <MInput
                  classNameFC={classes.formControl}
                  name="quota"
                  label="Quota Leave"
                  placeholder="13"
                  value={remaining_quota !==null ? remaining_quota : getData?.remaining_quota || ''}
                  // onBlur={this.handleInputChange}
                  type="number"
                  maxLength={2}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs={6} className="pb-0">
                <MInput
                  classNameFC={classes.formControl}
                  name="name"
                  label="Name Leave"
                  placeholder="Public Holiday"
                  defaultValue={name}
                  type="text"
                  onBlur={this.handleInputChange}
                  maxLength={4}
                  error={
                    errorMessage && errorMessage.name ?
                      errorMessage.name :
                      undefined
                  }
                  autoFocus
                />
              </Grid>
              <Grid item xs={6} className="pb-0">
              <MSelect
                autoFocus
                fullWidth
                classNameFC={classes.formControl}
                name="is_leave"
                label="Status"
                placeholder="Confirm status"
                keyPair={['id', 'name']}
                options={options}
                value={JSON.stringify(is_leave)}
                onChange={this.handleSelectChange}
                error={errorMessage && errorMessage.status ? errorMessage.status : undefined}
              />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs={6}>
                <MDatepicker
                  className={classes.formControl}
                  name="start_date"
                  label="Start Date"
                  value={start_date}
                  onDateChange={(params) => this.handleDateChange(params)}
                  error={errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined}
                  placeholder="dd-mm-yyyy"
                  minDate={moment([getData?.year]).startOf('year').toDate()}
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                  <MDatepicker
                  className={classes.formControl}
                  name="end_date"
                  label="End Date"
                  value={end_date}
                  onDateChange={(params) => this.handleDateChange(params)}
                  error={errorMessage && errorMessage.end_date ? errorMessage.end_date : undefined}
                  placeholder="dd-mm-yyyy"
                  minDate={start_date ? new Date(start_date) : moment([getData?.year]).startOf('year').toDate()}
                  maxDate={moment([getData?.year]).endOf('year').toDate()}
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs={6}>
                <MInput
                  classNameFC={classes.formControl}
                  name="total_days"
                  label="Duration"
                  placeholder="1"
                  value={total_days}
                  type="number"
                  maxLength={2}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              style={{
                dropShadow: 'none',
              }}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={'Save'}
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };
  renderActions = (params, id = 1) => {
    const {leaveData} = this.state;
    const currentYear = moment().year();

    return leaveData?.year >= currentYear && (
      <div>
        <Tooltip title="EDIT">
          <Button
            variant="contained"
            // color="primary"
            style={{
              'backgroundColor': '#00AFF0',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#00A2DE',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
              'marginRight': '4px',
            }}
            size="small"
            startIcon={<Edit />}
            onClick={() => this.handleFormToggle('edit', params)}
          >
          </Button>
        </Tooltip>
        <Tooltip title="DELETE">
          <Button
            variant="contained"
            // color="primary"
            size="small"
            style={{
              'backgroundColor': '#E5AF5A',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#D09F53',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
            }}
            onClick={() => this.handleConfirmDelete(params)}
            startIcon={<Delete />}>
          </Button>
        </Tooltip>
      </div>
    );
  };

  renderAddButton = () => {
    const {classes} = this.props;

    return (
      <div className={classes.MUIDataTableToolbar}>
        <div className="d-flex">
          <Link to="/leave-config" className={classes.btnBack}>
            <ArrowBack/>
          </Link>
          <Typography className={classes.tableTitle}>Master Holiday</Typography>
          {/* <span className="">Remaining Quota: {remaining_quota}</span>   */}
        </div>
        {/* <Tooltip title="ADD">
          <button
            onClick={() => this.handleFormToggle('add')}
            style={{
              'border': 'none',
              'width': '32px',
              'height': '32px',
              'borderRadius': '50px',
              'backgroundColor': '#FFBD54',
              'color': '#fff',
              'position': 'absolute',
              'right': '20px',
              'fontSize': '20px',
              'zIndex': '999',
              'top': '15px',
            }}
            >&#43;</button>
        </Tooltip> */}
      </div>
    );
  }

 renderFilter = () => {
    const {classes} = this.props;
    const {startDate, endDate, errorMessage, type} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'true', name: 'Memotong Cuti Tahunan'},
        {id: 'false', name: 'Tidak Memotong Cuti Tahunan'},
      ];
      return options;
    };
    return (
      <Paper className={classes.paper}>
        <MKeyBoardDatepicker
          className={classes.filterDatePicker}
          name="startDate"
          label="Start Date"
          value={startDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          maxDate={endDate ? new Date(endDate) : undefined}
          error={errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined}
        />
        <MKeyBoardDatepicker
          className={classes.filterDatePicker}
          name="endDate"
          label="End Date"
          value={endDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          minDate={new Date(startDate)}
          error={errorMessage && errorMessage.end_date ? errorMessage.end_date : undefined}
        />
        <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={`${classes.formControl} ${classes.filter}`}
          variant="standard"
          name="type"
          label="Type"
          keyPair={['id', 'name']}
          options={renderMethodOptions()}
          value={type}
          onChange={this.handleFilterChange}
        />
        <div className={classes.btnFilterContainer}>
          <MButton
            className={classes.filterBtn}
            label="Reset"
            color="primary"
            icon={<Refresh />}
            onClick={this.handleReset}
          />
        </div>
      </Paper>
    );
  };

  validateMinDate = (params, isEdit, type, start_date) => {
    const countParams = params.length;
    const newParams = _.first(params);
    if (countParams > 0) {
      if (!isEdit) {
        if (start_date) {
          return start_date;
        } else {
          return newParams.year + '-01-01';
        }
      } else {
        if (start_date) {
          return start_date;
        }
        return newParams.year + '-01-01';
      }
    }
  }

  validateMaxDate = (params, isEdit, type, end_date) => {
    const countParams = params.length;
    const newParams = _.first(params);
    if (countParams > 0) {
      if (!isEdit) {
        if (end_date) {
          return end_date;
        } else {
          return newParams.year + '-12-31';
        }
      } else {
        return newParams.year + '-12-31';
      }
    }
  }

  render() {
    const {classes, isLoading, masterHolidayData, masterHolidayMeta} = this.props;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'name', label: 'Name Holiday', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'start_date', label: 'Start Date', display: true, customBodyRender: (value) => value ? convDate(value, 'D MMMM YYYY') : '-'},
      {name: 'end_date', label: 'End Date', display: true, customBodyRender: (value) => value ? convDate(value, 'D MMMM YYYY') : '-'},
      {name: 'is_leave', label: 'Status', display: true, customBodyRender: (value) => value ? 'Memotong Cuti Tahunan' : 'Tidak memotong Cuti Tahunan'},
    ];

    return (
      <div className={classes.root}>
        {/* {this.renderFilter()} */}
        <Datatable
          creatable={this.state.creatable}
          title={this.renderAddButton()}
          loading={isLoading}
          dataSource={masterHolidayData}
          handleReload={(params) => this.handleReload(params)}
          total={masterHolidayMeta ? masterHolidayMeta.total : 0}
          page={masterHolidayMeta ? masterHolidayMeta.page: 1 }
          columns={columns}
          customActions={(params) => this.renderActions(params)}
          handleCreate={() => this.handleFormToggle('add')}
          manualNumbering={true}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const StateToProps = ({masterHoliday, leaveConfig}) => ({
  isLoading: masterHoliday.isLoading,
  isSuccess: masterHoliday.isSuccess,
  isError: masterHoliday.isError,
  message: masterHoliday.message,
  trigger: masterHoliday.trigger,
  leaveTrigger: leaveConfig.isSuccess,
  leaveSuccess: leaveConfig.trigger,
  masterHolidayData: masterHoliday.masterHolidayData,
  leaveConfigData: leaveConfig.leaveConfigData,
  masterHolidayMeta: masterHoliday.masterHolidayMeta,
});

const DispatchToProps = (dispatch) => ({
  getMasterHoliday: (id, params) => dispatch(getMasterHoliday(id, params)),
  getLeaveConfig: (params) => dispatch(getLeaveConfig(params)),
  createMasterHoliday: (params) => dispatch(createMasterHoliday(params)),
  updateMasterHoliday: (id, params) => dispatch(updateMasterHoliday(id, params)),
  deleteMasterHoliday: (id) => dispatch(deleteMasterHoliday(id)),
  clearState: () => dispatch(clearState()),

});

export default connect(StateToProps, DispatchToProps)(withStyles(styles)(MasterHoliday));
