import {ENDPOINTS, apiService} from '../config';

export const getOrganization = (params) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.ALL, {params});
};

export const getOrganizationList = (params) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.LIST, {params});
};

export const getChildOrganizationForHR = (params) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.CHILD, {params});
};

export const getChildsOrganizationForAdmin = (id, params) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.CHILDS + id, {params});
};

export const getOrganizationById = (id, params) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.DETAIL + id, {params});
};

export const getUsersWithoutOrg = (params) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.ALL_USER, {params});
};

export const getDetail = (id) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.DETAIL + id);
};

export const addOrganization = (params) => {
  return apiService.post(ENDPOINTS.ORGANIZATION.ADD, params, {
    headers: {'Content-Type': 'multipart/form-data'},
  });
};

export const addChildOrganization = (id, params) => {
  return apiService.post(ENDPOINTS.ORGANIZATION.ADD_CHILD + id, params);
};

export const updateOrganization = (id, params) => {
  return apiService.put(ENDPOINTS.ORGANIZATION.UPDATE + id, params, {
    headers: {'Content-Type': 'multipart/form-data'},
  });
};

export const dropOrganization = (id) => {
  return apiService.delete(ENDPOINTS.ORGANIZATION.DELETE + id);
};

export const dropChildOrganization = (params) => {
  return apiService.delete(ENDPOINTS.ORGANIZATION.DELETE_CHILD, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    data: params,
  });
};

export const invitationOrganization = (params) => {
  return apiService.post(ENDPOINTS.ORGANIZATION.INVITATION, params);
};

export const activateInvitationOrganization = (params) => {
  return apiService.post(ENDPOINTS.ORGANIZATION.ACTIVATE_INVITATION, params);
};

export const activateParentInvitationOrganization = (params) => {
  return apiService.post(
      ENDPOINTS.ORGANIZATION.ACTIVATE_PARENT_INVITATION,
      params,
  );
};

export const confirmInvitationChildOrganization = (params) => {
  return apiService.post(
      ENDPOINTS.ORGANIZATION.CONFIRM_CHILD_INVITATION,
      params,
  );
};

export const checkStatus = () => {
  return apiService.get(ENDPOINTS.ORGANIZATION.CHECK_STATUS);
};

export const getOrganizationType = () => {
  return apiService.get(ENDPOINTS.ORGANIZATION.TYPES);
};

export const getOrganizationTypeWithoutAuth = () => {
  return apiService.get(ENDPOINTS.ORGANIZATION.TYPES_NO_AUTH);
};

export const getMyOrganizationProfile = () => {
  return apiService.get(ENDPOINTS.ORGANIZATION.SHOW_LOGO);
};

export const getOrganizationAnalytics = (id, params) => {
  return apiService.get(ENDPOINTS.ORGANIZATION.ANALYTICS + id + '/analytics', {
    params,
  });
};

export const getOrgAnalyticsDetails = (id, params) => {
  return apiService.get(
      ENDPOINTS.ORGANIZATION.ANALYTICS + id + '/analytics/details',
      {params},
  );
};

export const checkOdoo = (params) => {
  return apiService.post(ENDPOINTS.ORGANIZATION.CHECK_ODOO, params);
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.ORGANIZATION.EXPORT,
      params,
  );
};

export const acceptTermsAndCondition = (params) => {
  return apiService.post(
      ENDPOINTS.ORGANIZATION.ACCEPT_TERMS,
      params,
  );
};

export const updateMobileFeatureSettings = (params) => {
  return apiService.post(
      ENDPOINTS.ORGANIZATION.UPDATE_MOBILE_FEATURE_SETTINGS,
      params,
  );
};

export const assignOrganizationHR = (params) => {
  return apiService.post(
    ENDPOINTS.ORGANIZATION.ASSIGN_ORG,
    params,
  );
};

export const orgHrInvitation = (params) => {
  return apiService.post(
    ENDPOINTS.ORGANIZATION.ORG_HR_INVITATION,
    params,
  );
};

export const getListOrgInvitedByHr = (params) => {
  return apiService.get(
    ENDPOINTS.ORGANIZATION.ORG_INVITED_BY_HR,
    {params},
  );
};

export const loginAsHr = (id) => {
  return apiService.post(
    ENDPOINTS.ORGANIZATION.ORG_INVITED_BY_HR + '/' + id,
  );
};

export const logoutAsHr = () => {
  return apiService.post(
    ENDPOINTS.ORGANIZATION.LOGOUT_AS_HR,
  );
};
