/* eslint-disable max-len */
export const initialState = {
  isLoading: false,
  isLoadingShift: false,
  isLoadingManager: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  userData: [],
  userDataByShift: [],
  managerData: [],
  oneManagerData: [],
  userMeta: {page: 1, total: 0},
  roleLoading: false,
  roleData: [],
  jobLoading: false,
  jobData: [],
  profileData: [],
  isLoadingProfile: false,
  isErrorProfile: false,
  isSuccessProfile: false,
  triggerProfile: '',
  attendanceTypes: {remote: false, office: false, business: false, overtime: false, paidleave: false},
  menus: [],
  message: {
    name: '',
    dob: '',
    email: '',
    phone: '',
    password: '',
    job_title_id: '',
    role_id: '',
    nik: '',
  },
  profileMessage: {
    name: '',
    dob: '',
    email: '',
    phone: '',
    password: '',
    job_title_id: '',
    role_id: '',
    nik: '',
  },
  exportLoading: false,
  exportData: {file: null},
  downloadLoading: false,
  downloadData: {file: null},
  importLoading: false,
  notif: null,

  bankData: [],
  isSuccessBank: false,
  isErrorBank: false,
  isLoadingBank: false,
  triggerBank: '',
  messagebank: '',

  employeeDetailData: [],
  isErrorGet: false,
  isSuccessGet: false,
  triggerGet: '',
  messageGet: '',

  isErrorUpdate: false,
  isSuccessUpdate: false,
  isLoadingUpdate: false,
  triggerUpdate: '',
  messageUpdate: '',

  isErrorContract: false,
  isSuccessContract: false,
  isLoadingContract: false,
  triggerContract: '',
  messageContract: '',
  contractData: [],
  contractMeta: {page: 1, total: 0},

  udpateHistoryData: [],
  udpateHistoryMeta: {page: 1, total: 0},
  leaveHistoryData: [],
  leaveHistoryMeta: {page: 1, total: 0},
  isSuccessLeave: null,
  isLoadingLeave: null,

  created_id: null,

  isSuccessJob: false,
  isErrorJob: false,
  isLoadingJob: false,
  triggerJob: '',

  isLoadingLeaveHistory: false,
  isErrorLeaveHistory: false,
  isSuccessLeaveHistory: false,
  isLoadingUpdateHistory: false,
  isErrorUpdateHistory: false,
  isSuccessUpdateHistory: false,
  isLoadingUserPersonalLeave: false,
  isErrorUserPersonalLeave: false,
  isSuccessUserPersonalLeave: false,
  triggerUserPersonalLeave: '',
  userPersonalLeaveData: [],
  userPersonalLeaveMeta: {page: 1, total: 0},
  userPersonalLeaveMessasge: {
  },


  isLoadingAddAdjustment: false,
  isErrorAddAdjustment: false,
  isSuccessAddAdjutment: false,
  triggerAddAdjustment: '',
  messageAddAdjustment: {},

  employeeTypeList: [],
  isLoadingEmployeeType: false,
  isErrorEmployeeType: false,
  isSuccessEmployeeType: false,
  triggerEmployeeType: '',
};

export const USER_STATE = {
  REQUEST: 'USER_REQUEST',
  REQUEST_FAILED: 'USER_REQUEST_FAILED',
  REQUEST_JOB: 'USER_REQUEST_JOB',
  REQUEST_JOB_FAILED: 'USER_REQUEST_JOB_FAILED',
  REQUEST_CONTRACT: 'USER_REQUEST_CONTRACT',
  REQUEST_CONTRACT_FAILED: 'USER_REQUEST_CONTRACT_FAILED',
  REQUEST_UPDATE_HISTORY: 'USER_REQUEST_UPDATE_HISTORY',
  REQUEST_UPDATE_HISTORY_FAILED: 'USER_REQUEST_UPDATE_HISTORY_FAILED',
  REQUEST_LEAVE_HISTORY: 'USER_REQUEST_LEAVE_HISTORY',
  REQUEST_LEAVE_HISTORY_FAILED: 'USER_REQUEST_LEAVE_HISTORY_FAILED',
  REQUEST_PERSONAL_LEAVE: 'USER_REQUEST_PERSONAL_LEAVE',
  REQUEST_PERSONAL_LEAVE_FAILED: 'USER_REQUEST_PERSONAL_LEAVE_FAILED',
  CLEAR_STATE: 'USER_CLEAR_STATE',
  CLEAR_LOADER_STATE: 'CLEAR_LOADER_STATE',
  FETCH_USER: 'USER_FETCH_USER',
  FETCH_PROFILE: 'USER_FETCH_PROFILE',
  FETCH_MANAGER: 'USER_FETCH_MANAGER',
  FETCH_BANK: 'USER_FETCH_BANK',
  FETCH_ROLE: 'USER_FETCH_ROLE',
  FETCH_JOB: 'USER_FETCH_JOB',
  FETCH_USER_SUCCCESS: 'USER_FETCH_USER_SUCCCESS',
  FETCH_PROFILE_SUCCCESS: 'USER_FETCH_PROFILE_SUCCCESS',
  FETCH_MANAGER_SUCCCESS: 'USER_FETCH_MANAGER_SUCCCESS',
  FETCH_ROLE_SUCCCESS: 'USER_FETCH_ROLE_SUCCCESS',
  FETCH_JOB_SUCCCESS: 'USER_FETCH_JOB_SUCCCESS',
  FETCH_USER_FAILED: 'USER_FETCH_USER_FAILED',
  FETCH_PROFILE_FAILED: 'USER_FETCH_PROFILE_FAILED',
  FETCH_MANAGER_FAILED: 'USER_FETCH_MANAGER_FAILED',
  FETCH_ROLE_FAILED: 'USER_FETCH_ROLE_FAILED',
  FETCH_JOB_FAILED: 'USER_FETCH_JOB_FAILED',
  CREATE: 'USER_CREATE',
  CREATE_FAILED: 'USER_CREATE_FAILED',
  CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  DROP: 'USER_DROP',
  DROP_SUCCESS: 'USER_DROP_SUCCESS',
  DROP_FAILED: 'USER_DROP_FAILED',
  UPDATE: 'USER_UPDATE',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  UPDATE_FAILED: 'USER_UPDATE_FAILED',
  CHECK_STATUS: 'USER_CHECK_STATUS',
  CHECK_STATUS_SUCCESS: 'USER_CHECK_STATUS_SUCCESS',
  CHECK_STATUS_FAILED: 'USER_CHECK_STATUS_FAILED',

  FETCH_USER_BY_SHIFT: 'FETCH_USER_BY_SHIFT',
  FETCH_USER_BY_SHIFT_SUCCESS: 'FETCH_USER_BY_SHIFT_SUCCESS',
  FETCH_USER_BY_SHIFT_FAILED: 'FETCH_USER_BY_SHIFT_FAILED',

  FETCH_SINGLE_MANAGER: 'USER_FETCH_SINGLE_MANAGER',
  FETCH_SINGLE_MANAGER_SUCCESS: 'USER_FETCH_SINGLE_MANAGER_SUCCESS',
  FETCH_SINGLE_MANAGER_FAILED: 'USER_FETCH_SINGLE_MANAGER_FAILED',

  SYNC_ADVANTECH: 'SYNC_ADVANTECH',
  SYNC_ADVANTECH_SUCCESS: 'SYNC_ADVANTECH_SUCCESS',
  SYNC_ADVANTECH_FAILED: 'SYNC_ADVANTECH_FAILED',
  CANCEL_SYNC: 'CANCEL_SYNC',
  CANCEL_SYNC_SUCCESS: 'CANCEL_SYNC_SUCCESS',
  CANCEL_SYNC_FAILED: 'CANCEL_SYNC_FAILED',

  EXPORT: 'USER_EXPORT',
  EXPORT_SUCCESS: 'USER_EXPORT_SUCCESS',
  EXPORT_FAILED: 'USER_EXPORT_FAILED',
  IMPORT: 'USER_IMPORT',
  IMPORT_FAILED: 'USER_IMPORT_FAILED',
  IMPORT_SUCCESS: 'USER_IMPORT_SUCCESS',
  CANCEL_IMPORT: 'CANCEL_IMPORT',
  CANCEL_IMPORT_SUCCESS: 'CANCEL_IMPORT_SUCCESS',
  CANCEL_IMPORT_FAILED: 'CANCEL_IMPORT_FAILED',

  READ_NOTIF: 'READ_NOTIF_USER',
  READ_NOTIF_SUCCESS: 'READ_NOTIF_USER',
  READ_NOTIF_FAILED: 'READ_NOTIF_USER',

  DOWNLOAD_TEMPLATE: 'USER_DOWNLOAD_TEMPLATE',
  DOWNLOAD_TEMPLATE_FAILED: 'USER_DOWNLOAD_TEMPLATE_FAILED',
  DOWNLOAD_TEMPLATE_SUCCESS: 'USER_DOWNLOAD_TEMPLATE_SUCCESS',

  GET_DETAIL: 'USER_GET_DETAIL',
  GET_DETAIL_SUCCESS: 'USER_GET_DETAIL_SUCCESS',
  GET_DETAIL_FAILED: 'USER_GET_DETAIL_FAILED',

  UPDATE_PERSONAL: 'USER_UPDATE_PERSONAL',
  UPDATE_PERSONAL_SUCCESS: 'USER_UPDATE_PERSONAL_SUCCESS',
  UPDATE_PERSONAL_FAILED: 'USER_UPDATE_PERSONAL_FAILED',

  UPDATE_EMPLOYMENT: 'USER_UPDATE_EMPLOYMENT',
  UPDATE_EMPLOYMENT_SUCCESS: 'USER_UPDATE_EMPLOYMENT_SUCCESS',
  UPDATE_EMPLOYMENT_FAILED: 'USER_UPDATE_EMPLOYMENT_FAILED',

  UPDATE_REMOTE: 'USER_UPDATE_REMOTE',
  UPDATE_REMOTE_SUCCESS: 'USER_UPDATE_REMOTE_SUCCESS',
  UPDATE_REMOTE_FAILED: 'USER_UPDATE_REMOTE_FAILED',

  UPDATE_ACCOUNT: 'USER_UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_SUCCESS: 'USER_UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILED: 'USER_UPDATE_ACCOUNT_FAILED',

  GET_BANK: 'USER_GET_BANK',
  GET_BANK_SUCCESS: 'USER_GET_BANK_SUCCESS',
  GET_BANK_FAILED: 'USER_GET_BANK_FAILED',

  GET_CONTRACT: 'USER_GET_CONTRACT',
  GET_CONTRACT_SUCCESS: 'USER_GET_CONTRACT_SUCCESS',
  GET_CONTRACT_FAILED: 'USER_GET_CONTRACT_FAILED',

  ADD_CONTRACT: 'USER_ADD_CONTRACT',
  ADD_CONTRACT_SUCCESS: 'USER_ADD_CONTRACT_SUCCESS',
  ADD_CONTRACT_FAILED: 'USER_ADD_CONTRACT_FAILED',

  UPDATE_CONTRACT: 'USER_UPDATE_CONTRACT',
  UPDATE_CONTRACT_SUCCESS: 'USER_UPDATE_CONTRACT_SUCCESS',
  UPDATE_CONTRACT_FAILED: 'USER_UPDATE_CONTRACT_FAILED',

  UPDATE_CONTRACT_STATUS: 'USER_UPDATE_CONTRACT_STATUS',
  UPDATE_CONTRACT_STATUS_SUCCESS: 'USER_UPDATE_CONTRACT_STATUS_SUCCESS',
  UPDATE_CONTRACT_STATUS_FAILED: 'USER_UPDATE_CONTRACT_STATUS_FAILED',

  UPDATE_EMPLOYEE_STATUS: 'USER_UPDATE_EMPLOYEE_STATUS',
  UPDATE_EMPLOYEE_STATUS_SUCCESS: 'USER_UPDATE_EMPLOYEE_STATUS_SUCCESS',
  UPDATE_EMPLOYEE_STATUS_FAILED: 'USER_UPDATE_EMPLOYEE_STATUS_FAILED',

  DELETE_CONTRACT: 'USER_DELETE_CONTRACT',
  DELETE_CONTRACT_SUCCESS: 'USER_DELETE_CONTRACT_SUCCESS',
  DELETE_CONTRACT_FAILED: 'USER_DELETE_CONTRACT_FAILED',

  UPDATE_DEDUCTED: 'USER_UPDATE_DEDUCTED',
  UPDATE_DEDUCTED_SUCCESS: 'USER_UPDATE_DEDUCTED_SUCCESS',
  UPDATE_DEDUCTED_FAILED: 'USER_UPDATE_DEDUCTED_FAILED',

  UPDATE_HISTORY: 'USER_UPDATE_HISTORY',
  UPDATE_HISTORY_SUCCESS: 'USER_UPDATE_HISTORY_SUCCESS',
  UPDATE_HISTORY_FAILED: 'USER_UPDATE_HISTORY_FAILED',

  LEAVE_HISTORY: 'USER_LEAVE_HISTORY',
  LEAVE_HISTORY_SUCCESS: 'USER_LEAVE_HISTORY_SUCCESS',
  LEAVE_HISTORY_FAILED: 'USER_LEAVE_HISTORY_FAILED',

  USER_PERSONAL_LEAVE: 'USER_USER_PERSONAL_LEAVE',
  USER_PERSONAL_LEAVE_SUCCESS: 'USER_USER_PERSONAL_LEAVE_SUCCESS',
  USER_PERSONAL_LEAVE_FAILED: 'USER_USER_PERSONAL_LEAVE_FAILED',

  CREATE_JOB: 'USER_CREATE_JOB',
  CREATE_JOB_FAILED: 'USER_CREATE_JOB_FAILED',
  CREATE_JOB_SUCCESS: 'USER_CREATE_JOB_SUCCESS',

  CREATE_ADJUSTMENT_LEAVE: 'USER_CREATE_ADJUSTMENT_LEAVE',
  CREATE_ADJUSTMENT_LEAVE_FAILED: 'USER_CREATE_ADJUSTMENT_LEAVE_FAILED',
  CREATE_ADJUSTMENT_LEAVE_SUCCESS: 'USER_CREATE_ADJUSTMENT_LEAVE_SUCCESS',
  REQUEST_ADJUSTMENT_LEAVE: 'USER_REQUEST_ADJUSTMENT_LEAVE',
  REQUEST_ADJUSTMENT_LEAVE_FAILED: 'USER_REQUEST_ADJUSTMENT_LEAVE_FAILED',

  UPDATE_EMPLOYEE: 'USER_UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_SUCCESS: 'USER_UPDATE_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_FAILED: 'USER_UPDATE_EMPLOYEE_FAILED',

  REQUEST_EMPLOYEE_TYPE: 'USER_REQUEST_EMPLOYEE_TYPE',
  REQUEST_EMPLOYEE_TYPE_SUCCESS: 'USER_REQUEST_EMPLOYEE_TYPE_SUCCESS',
  REQUEST_EMPLOYEE_TYPE_FAILED: 'USER_REQUEST_EMPLOYEE_TYPE_FAILED',
};
