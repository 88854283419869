/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import buttonTheme from '../../components/theme/theme';
import {
  IconButton,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  CardMedia,
  Paper,
  Tooltip,
  Typography,
  Switch,
  Link,
} from '@material-ui/core';
import {
  Delete,
  Send,
  Visibility,
  VisibilityOff,
  Search,
  Crop,
  Refresh,
  Sync,
  CheckCircleOutline,
  WarningOutlined,
  CancelOutlined,
  HourglassEmpty,
  GetApp,
  Publish,
  Edit,
  HelpOutline,
  Info,
} from '@material-ui/icons';
import {checkPremium} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {USER_STATE} from '../../redux/userManagement/userManagement.types';
import {ORGANIZATION_STATE} from '../../redux/organization/organization.types';
import InputMask from 'react-input-mask';
import {
  isObject,
  toast,
  showPopup,
  convDate,
  checkSuperadmin,
  checkFileUploadSize,
  blobToFile,
  getTimeDiff,
  isArray,
} from '../../helpers';
import {
  MInput,
  MInputCurrency,
  MSelect,
  MButton,
  ModalTitle,
  MDatepicker,
  MInputMask,
  MSelectRole,
} from '../../components/form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Datatable} from '../../components';
import ImageCropper from '../../components/cropper';
import UpgradeAccountModal from '../upgradeAccount';
import {getJobTitleByOrg} from '../../redux/jobTitle/jobTitle.actions';
import {
  getNewUsers,
  getAllUser,
  getAllManager,
  getAllRole,
  postUser,
  postNewUser,
  dropUser,
  updateUser,
  updateNewUser,
  clearState,
  getOneManager,
  syncAdvantech,
  cancelAdvSync,
  exportExcelUser,
  importExcelUser,
  cancelImportUser,
  downloadExcelTemplate,
  createUserJobTitle,
} from '../../redux/userManagement/userManagement.actions';
import {checkStatus, getOrganizationList} from '../../redux/organization/organization.actions';
import _ from 'lodash';
import {XENDIT_STATE} from '../../redux/xendit/xendit.types';
import {DropzoneArea} from 'material-ui-dropzone';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';

import {
  getDivision,
  createDivision,
  clearState as clearStateDivision,
} from './../../redux/divisionManagement/divisionManagement.actions';
import {DIVISION_STATE} from '../../redux/divisionManagement/divisionManagement.types';
import {Snackbar, ThemeProvider, createTheme as themeTemplate} from '@mui/material';
import {updateExportNotification} from '../../redux/exportActivity/exportActivity.actions';


const INVOICE_PERIOD = process.env.REACT_APP_API_INVOICE_PERIOD;


class UserManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      type: '',
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        manager: '',
        join_date: null,
        role_id: '',
        job_title_id: null,
        limit_remote: null,
        additional_limit_remote: 0,
        division_id: null,
        approval_type: 'none',
      },
      disableApprovalType: false,
      remoteWorking: false,
      isEdit: false,
      resetPage: null,
      resetDatatable: null,
      modalFormShow: false,
      showPassword: false,
      showUpgradeModal: false,
      nameFilter: null,
      nikFilter: null,
      searchFilterManager: null,
      errorMessage: null,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,
      showMethod: null,
      invoiceUrl: null,
      query: '',
      sortBy: null,
      order: 'desc',
      advantech: null,
      modalImportShow: false,
      file: null,
      import_users: false,
      errorMessageRemote: null,
      errorMessageAdditionalRemote: null,
      errorMessageDivision: null,
      errorMessageJobTitle: null,
      errorMessageEmail: null,
      modalGuideShow: false,
      modalApprovalInformation: false,
      modalCreateDivision: false,
      modalCreateJobTitle: false,
      division_name: null,
      description: null,
      job_name: null,
      job_description: null,
      employment_status: 'active',
      approval_type: 'all',
      createdDivision: false,
      createdJobTitle: false,
      userDatatable: [],
      hr_remaining: null,
      isLoading: null,
      snackbar: false,
      snackbarVertical: 'top',
      snackbarHorizontal: 'right',
    };
    this.resetForm = this.state.formData;
  }


  modalTitleTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: 20,
          fontWeight: 600,
          color: '#656464',
        },
      },
    },
  });

  snackbarTheme = () => themeTemplate({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: '#000000 !important',
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
  });

  tooltipTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.props.getAllRole();
    this.props.checkStatus();
    this.props.checkStatusUser();
    this.props.getDivision({limit: 100});
    this.props.isSuperAdmin && this.props.getOrganizationList();
    this.fetchUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message, userData, data, isSuperAdmin, orgStatusData, triggerOrg, isSuccessOrg, divisionData, isErrorDivision, divisionMessage, isSuccessDivision, triggerDivision, isSuccessJob, isErrorJob, isLoadingJob, triggerJob, jobData, isLoading} = nextProps;
    const {limit_remote, additional_limit_remote} = this.state.formData;
    const {remoteWorking} = this.state;
    const {statusData, exportData} = nextProps;

    if (data.length !== 0 && this.state.advantech === null && !isSuperAdmin) {
      const filtered = this.filterSetting(data);
      const advantech = filtered && filtered[0] ? filtered[0] : false;
      // if (checkInSetting && checkInSetting.value) checkInSetting.value = moment(checkInSetting.value, 'hh:mm');
      this.setState({
        advantech: advantech && advantech.value === false ? false : advantech.value === true ? true : null,
      });
    }

    if (trigger === USER_STATE.EXPORT_SUCCESS && isSuccess) {
      this.setState({snackbar: true});
      this.props.updateExportNotification({
        fileName: 'User Management',
        ...exportData,
      });
    }

    if (isSuccess === undefined) {
      if (trigger === USER_STATE.FETCH_USER_SUCCCESS) {
        this.setState({
          userDatatable: userData,
        });
      }
    }

    if (isSuccessOrg) {
      if (triggerOrg === ORGANIZATION_STATE.CHECK_STATUS_SUCCESS && orgStatusData && orgStatusData.import_users !== this.state.import_users) {
        this.setState({
          import_users: orgStatusData.import_users,
        }, () => this.fetchUser());
      }
    }

    if (isSuccessDivision) {
      if (triggerDivision === DIVISION_STATE.CREATE_SUCCESS) {
        this.setState({
          errorMessageDivision: null,
          division_name: null,
          description: null,
          createdDivision: true,
          modalCreateDivision: false,
        });
        toast('success', divisionMessage);
        this.props.getDivision({limit: 100});
      }
      if (triggerDivision === DIVISION_STATE.FETCH_SUCCCESS) {
        if (this.state.createdDivision) {
          let createdID = 0;
          for (let i = 0; i < divisionData.length; i++) {
            if (divisionData[i].id > createdID) {
              createdID = {id: divisionData[i].id, name: divisionData[i].division_name};
            }
          }
          this.setState({
            formData: {
              ...this.state.formData,
              division_id: createdID,
            },
            createdDivision: false,
            modalCreateDivision: false,
          });
        }
      }
    }

    if (isSuccessJob) {
      if (triggerJob === USER_STATE.CREATE_JOB_SUCCESS) {
        this.setState({
          errorMessageJobTitle: null,
          job_name: null,
          job_description: null,
          createdJobTitle: true,
          modalCreateJobTitle: false,
        });
        toast('success', divisionMessage);
        this.props.getJobTitleByOrg();
      }
      if (triggerDivision === DIVISION_STATE.FETCH_SUCCCESS) {
        if (this.state.createdJobTitle) {
          let createdID = 0;
          for (let i = 0; i < jobData.length; i++) {
            if (jobData[i].id > createdID) {
              createdID = {id: jobData[i].id, name: jobData[i].name};
            }
          }
          this.setState({
            formData: {
              ...this.state.formData,
              job_title_id: createdID,
            },
            createdJobTitle: false,
            modalCreateJobTitle: false,
          });
        }
      }
    }

    if (isSuccess) {
      if (trigger === USER_STATE.FETCH_USER_SUCCCESS) {
        this.fetchUser();
      }

      if (trigger === USER_STATE.FETCH_MANAGER_SUCCCESS) {
        this.fetchManager();
      }

      if (trigger === USER_STATE.CREATE_SUCCESS) {
        this.props.clearState();
        this.handleReset();
        this.fetchUser();
        this.props.getAllRole();
        toast('success', message.message);
      }

      if (trigger === USER_STATE.IMPORT_SUCCESS) {
        this.handleReset();
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.DROP_SUCCESS) {
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.UPDATE_EMPLOYEE_SUCCESS) {
        this.props.clearState();
        this.handleReset();
        this.fetchUser();
        this.props.getAllRole();
        toast('success', message);
      }

      if (trigger === USER_STATE.IMPORT_SUCCESS && orgStatusData && !orgStatusData.import_users) {
        showPopup(
            'info',
            'Feel free to continue your task. This process going to take awhile at the background.',
        );
        this.props.checkStatus();
        toast('success', message);
      }

      if (trigger === USER_STATE.CANCEL_IMPORT_SUCCESS && orgStatusData && !orgStatusData.import_users) {
        this.props.checkStatus();
        toast('success', message);
      }

      if (trigger === USER_STATE.SYNC_ADVANTECH_SUCCESS && orgStatusData && !orgStatusData.adv_sync) {
        showPopup(
            'info',
            'Feel free to continue your task. This process going to take awhile at the background.',
        );
        this.props.checkStatus();
        toast('success', message);
      }
      if (trigger === USER_STATE.CANCEL_SYNC_SUCCESS && orgStatusData && orgStatusData.adv_sync) {
        this.props.checkStatus();
        toast('success', message);
      }
    }

    const {isSuccessXendit, triggerXendit, xenditStatus} = nextProps;

    if (xenditStatus && xenditStatus.invoice_url !== this.state.invoiceUrl) {
      this.setState({invoiceUrl: xenditStatus.invoice_url});
    }


    if (isSuccessXendit) {
      if (
        triggerXendit === XENDIT_STATE.CHECK_XENDIT_SUCCESS && this.state.invoiceUrl !== xenditStatus.invoice_url
      ) {
        this.setState({
          invoiceUrl: xenditStatus.invoice_url,
        });
      }
    }

    const errorData = {};
    if (isError && message) {
      if (isObject(message)) {
        if (remoteWorking && (limit_remote === null || limit_remote === '')) {
          Object.assign(errorData, {limit_remote: 'Remote working quota is required'});
        }
        if (remoteWorking && (additional_limit_remote === null || additional_limit_remote === '')) {
          Object.assign(errorData, {additional_limit_remote: 'Additional remote limit quota is required'});
        }
        if (remoteWorking && additional_limit_remote > 0 && limit_remote === 0) {
          Object.assign(errorData, {additional_limit_remote: 'Remote working quota must be more than 0 to add additional remote limit'});
        }

        this.setState({
          errorMessage: message,
          errorMessageRemote: remoteWorking && (limit_remote === null || limit_remote === '') ? 'Remote working quota is required' : null,
          errorMessageAdditionalRemote: remoteWorking && (additional_limit_remote === null || additional_limit_remote === '') ? 'Additional remote limit quota is required' :
          remoteWorking && additional_limit_remote > 0 && limit_remote === 0 ? 'Remote working quota must be more than 0 to add additional remote limit' : null,
        });
      }
      if (!isObject(message)) toast('error', message);
    }


    if (isErrorDivision && divisionMessage) {
      if (isObject(divisionMessage)) this.setState({errorMessageDivision: divisionMessage});
      if (!isObject(divisionMessage)) toast('error', divisionMessage);
    }

    if (isErrorJob && message) {
      if (isObject(message)) this.setState({errorMessageJobTitle: message});
      if (!isObject(message)) toast('error', message);
    }
  }


  componentWillUnmount() {
    this.props.clearState();
  }

  componentDidMount() {
    const state = this.props.location.state;
    const {isSuperAdmin} = this.props;

    if (!isSuperAdmin) this.props.getJobTitleByOrg();

    if (state?.editProfileHR === true) {
      this.handleReset();
      this.setState({
        isEdit: true,
        modalFormShow: !this.state.modalFormShow,
        disableApprovalType: false,
        formData: {
          id: state.profile.user_id,
          name: state.profile.full_name,
          nik: state.profile.nik,
          email: state.profile.email,
          address: state.profile.address ? state.profile.address : null,
          role_id: state.profile.role_id,
          job_title_id: state.profile.job_title_id,
          manager: null,
          join_date: state.profile.join_date,
          dob: state.profile.date_of_birth,
          phone: state.profile.phone,
          imageURL: state.profile.avatar,
          limit_remote: state.profile.limit_remote,
          additional_limit_remote: state.profile.additional_limit_remote,
          division_id: state.profile.division_id,
          approval_type: state.profile.approval_type,
        },
      });
    }
  }

  fetchUser = () => {
    const {isSuperAdmin} = this.props;
    const {page, limit, query, employment_status, sortBy, order, type, approval_type} = this.state;

    if (isSuperAdmin) {
      this.props.getNewUsers({
        ...(page && {page}),
        ...(limit && {limit}),
        ...(query && {query}),
        ...(employment_status && employment_status !== 'all' && {employment_status}),
        ...(sortBy && {sort_by: sortBy}),
        ...(order && {order}),
        ...(approval_type && approval_type !== 'all' && {approval_type}),
      });
    } else {
      // this.props.getAllUser({page, limit, query, sort_by: sortBy, order});
      this.props.getNewUsers({
        ...(page && {page}),
        ...(limit && {limit}),
        ...(query && {query}),
        ...(employment_status && employment_status !== 'all' && {employment_status}),
        ...(sortBy && {sort_by: sortBy}),
        ...(order && {order}),
        ...(approval_type && approval_type !== 'all' && {approval_type}),
      });
    }

    if (!isSuperAdmin) this.props.checkPremium();
  };

  fetchManager = () => {
    const {page, limit, all, searchFilterManager} = this.state;
    const queryParams = {
      query: searchFilterManager,
      // all: true
    };
    this.props.getAllManager(queryParams);
  }
  handleReload = (params) => {
    this.setState(params, () => this.fetchUser());
  };

  handleReset = () => {
    this.setState({
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        manager: null,
        join_date: null,
        role_id: '',
        organization_id: '',
        job_title_id: null,
        limit_remote: null,
        additional_limit_remote: 0,
        division_id: null,
        approval_type: 'all',
      },
      searchFilter: null,
      modalFormShow: false,
      disableApprovalType: false,
      isEdit: false,
      errorMessage: null,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,
      modalImportShow: false,
      modalCreateDivision: false,
      modalCreateJobTitle: false,
    });
    // this.toggleResetPage()
    // this.handleReload()
  };

  filterSetting = (data) => {
    if (isArray(data)) {
      const filter = (data, key) => {
        const result = data.filter((value) => value.key === key);
        return isArray(result) ? result[0] : null;
      };

      const advantech = filter(data, 'advantech');

      return [advantech];
    }
  }

  handleResetFilter = () => {
    this.setState({
      page: 1,
      limit: 10,
      query: '',
      employment_status: 'active',
      sortBy: null,
      order: 'desc',
      approval_type: 'all',
      userDatatable: [],
    }, () => this.fetchUser());
    // this.toggleResetPage();
    // this.handleReload()
  };

  handleExport = () => {
    const {page, limit, query, order, sortBy, approval_type, employment_status} = this.state;
    this.props.exportExcelUser({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(order && {order}),
      ...(sortBy && {sort_by: sortBy}),
      ...(approval_type && {approval_type}),
      ...(employment_status && {employment_status}),
    });
  }

  handleInputChangeDivision = (event) => {
    const {formData} = this.state;
    const {name, value} = event.target;
    if (value === 0) {
      this.setState({modalCreateDivision: true});
    } else {
      this.setState({
        formData: {
          ...formData,
          [name]: value,
        },
      });
    }
  }
  handleInputChangeJobTitle = (event) => {
    const {formData} = this.state;
    const {name, value} = event.target;
    if (value === 0) {
      this.setState({modalCreateJobTitle: true});
    } else {
      this.setState({
        formData: {
          ...formData,
          [name]: value,
        },
      });
    }
  }
  handleFilterEmployment = (event) => {
    const {name, value} = event.target;
    this.setState(
        {employment_status: value},
        () => this.fetchUser(),
    );
  }

  handleFilterApproval = (event) => {
    const {name, value} = event.target;
    this.setState(
        {approval_type: value},
        () => this.fetchUser(),
    );
  }

  handleFilterEmployment = (event) => {
    const {name, value} = event.target;
    this.setState(
        {employment_status: value},
        () => this.fetchUser(),
    );
  }

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    if (name === 'name' && value.length > 0) {
      const regexp = /\d/g;
      if (value.length < 3) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [name]: 'name must more than 3 character',
          },
        });
      } else if (value.match(regexp)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [name]: 'name must not be used number',
          },
        });
      } else {
        this.setState({
          formData: {
            ...formData,
            [name]: value,
          },
          errorMessage: null,
        });
      }
    } else if (name === 'nik' && value.length > 0) {
      const regexp = /\d/g;
      if (!value.match(regexp)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [name]: 'nik must be used number',
          },
        });
      } else {
        this.setState({
          formData: {
            ...formData,
            [name]: value,
          },
          errorMessage: null,
        });
      }
    } else if (name === 'user_img') {
      const regExp = /\.(jpg|jpeg)$/i;
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        if (!value.match(regExp)) {
          this.setState({
            user_img: null,
            errorImageSize: null,
            errorMessage: {
              [name]: 'Must be .jpg/.jpeg',
            },
          });
        } else {
          this.setState({
            isCropping: true,
            errorImageSize: null,
            errorMessage: null,
            formData: {
              ...formData,
              imageURL: URL.createObjectURL(image),
              user_img: image,
            },
          });
        }
      } else {
        let message;
        if (imageSizeIsAllowed === false) {
          message =
            'Maximum file size is ' +
            process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        }
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          imageURL: null,
          user_img: null,
        });
      }
    } else if (name === 'email' && value.length > 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value.match(emailRegex)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            email: 'Email must be a valid email',
          },
        });
      } else {
        this.setState({
          formData: {...formData, [name]: value},
          errorMessage: null,
        });
      }
    } else if (name === 'phone' && value.length > 0) {
      if ((value.length < 9) || (value.length > 14)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            phone: 'phone number must be at least 9-14 character',
          },
        });
      } else {
        this.setState({
          formData: {...formData, [name]: value},
          errorMessage: null,
        });
      }
    } else {
      this.setState({
        formData: {...formData, [name]: value},
        errorMessage: null,
        errorMessageRemote: null,
        errorMessageAdditionalRemote: null,
      });
    }
  };

  handleDivisionForm = (event) => {
    const {name, value} = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleDateChange = (params) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = params;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: null,
    });
  };

  handleFormToggle = (type) => {
    const {
      creatable,
      creatableMessage,
      isSuperAdmin,
      orgStatusData,
    } = this.props;
    if (creatable === false && type === 'add') {
      if (orgStatusData.premium && orgStatusData.message_2) {
        showPopup('info', 'Info', orgStatusData.message_2);
      } else {
        showPopup(
            'confirm',
            creatableMessage,
            'Yes, Upgrade',
            () => this.toggleModalUpgrade(),
        );
      }
    } else {
      const tempAdminJobTitle = isSuperAdmin ? 1 : null; // superadmin handler
      this.handleReset();
      this.setState({
        modalFormShow: !this.state.modalFormShow,
        disableApprovalType: false,
        remoteWorking: false,
        formData: {...this.resetForm, job_title_id: tempAdminJobTitle},
      });
    }
  };

  handleModalImport = () => {
    const {orgStatusData} = this.props;
    this.setState({
      modalImportShow: !this.state.modalImportShow,
      import_users: orgStatusData && orgStatusData.import_users,
    });
  };

  handleGetRoleInfo = (roleName) => {
    return this.props.roleData.filter((item) => item.name === roleName);
  };

  handleGetJobInfo = (jobType) => {
    const job = this.props.jobData.filter((item) => item.name === jobType);
    return job.length ? job : null;
  };

  handleGetManagerInfo = async (id) => {

  }

  handleDetailUser = (data) => {
    this.props.history.push(`/employee-detail?id=${data[1]}`);
  }


  handleEditDialogToggle = async (data) => {
    const {isSuperAdmin} = this.props;
    let job = this.handleGetJobInfo(data[11]);
    const role = this.handleGetRoleInfo(data[9]);
    const manager = await this.props.getOneManager(data[1]);
    if (isSuperAdmin) job = 1; // superadmin handler
    if (!isSuperAdmin) job = job ? job[0].id : null;
    this.handleReset();
    this.setState({
      isEdit: true,
      remoteWorking: data[17] === null ? false : true,
      modalFormShow: !this.state.modalFormShow,
      disableApprovalType: data[9] === 'HR Admin' ? true : false,
      formData: {
        id: data[1],
        name: data[2],
        nik: data[3],
        email: data[5],
        address: data[6],
        role_id: role.length ? role[0].id : null,
        job_title_id: job,
        manager: manager,
        join_date: data[13],
        dob: data[7] ? convDate(data[7]) : null,
        phone: data[8],
        imageURL: data[14],
        limit_remote: data[17],
        additional_limit_remote: data[18],
        approval_type: data[9] === 'HR Admin' ? 'master' : data[12],
        division_id: data[20],
      },
    });
  };

  handleConfirmDelete = (data) => {
    if (data[9] === 'HR Admin') {
      showPopup(
          'info',
          'Account with role HR can not be deleted. Please contact Pagii Support to delete this account.',
      );
    } else {
      showPopup(
          'confirm',
          'Are you sure you want to delete this data ?',
          'Yes',
          () => this.props.dropUser(data[1]),
      );
    }
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearchQuery(value);
  }

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {createUser, createNewUser, updateUser, updateNewUser, isSuperAdmin, isMultipleHR} = this.props;
    const {isEdit, remoteWorking} = this.state;
    const {
      id,
      name,
      address,
      nik,
      job_title_id,
      email,
      dob,
      phone,
      join_date,
      role_id,
      manager,
      password,
      cropped_user_img,
      limit_remote,
      additional_limit_remote,
      division_id,
      approval_type,
      organization_id,
      errorMessage,
    } = this.state.formData;

    const payload = {
      name: name,
      email: email,
      phone: phone,
      role_id: role_id,
      organization_id: organization_id,
    };
    const dataForm = new FormData();
    if (name) {
      dataForm.append('name', name);
    }
    if (nik) dataForm.append('nik', nik);
    if (!isSuperAdmin && manager) {
      if (manager.manager_id !=='null' && manager.manager_id !==null) {
        dataForm.append('manager', manager.manager_id);
      } else {
        dataForm.append('manager', null);
      }
    } else {
      dataForm.append('manager', null);
    }
    if (!isSuperAdmin && job_title_id) dataForm.append('job_title_id', job_title_id);
    if (email) dataForm.append('email', email);
    if (address) dataForm.append('address', address);
    if (dob) dataForm.append('dob', convDate(dob));
    // if (join_date) dataForm.append('join_date', convDate(join_date));
    if (phone) {
      if ((phone.length < 9) || (phone.length > 14)) {
        this.setState({
          errorMessage: {
            phone: 'phone number must be at least 9-14 character',
          },
        });
      } else {
        dataForm.append('phone', phone);
        this.setState({
          errorMessage: null,
        });
      }
    }
    // if (phone) dataForm.append('phone', phone);
    if (isMultipleHR) {
      if (role_id) dataForm.append('role_id', role_id ? role_id : 3);
    } else {
      dataForm.append('role_id', role_id || 3);
    }
    // if (password) dataForm.append('password', password);
    if (cropped_user_img) {
      dataForm.append('user_img', blobToFile(cropped_user_img));
    }

    const requiredFields = [
      {
        name: 'name',
        message: 'Full name',
      },
      {
        name: 'email',
        message: 'Email',
      },
      {
        name: 'phone',
        message: 'Phone Number',
      },
      {
        name: 'role_id',
        message: 'Role',
      },
    ];
    const missingFields = requiredFields.filter((field) => !this.state.formData[field.name]);

    missingFields.forEach((field) => {
      this.setState((prevState) => ({
        errorMessage: {
          ...prevState.errorMessage,
          [field.name]: `${field.message} is required`,
        },
      }));
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email?.match(emailRegex)) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          email: 'Email must be a valid email',
        },
      });
    } else {
      this.setState({
        errorMessage: null,
      });
    }

    // if (remoteWorking && limit_remote === 0 ) {
    //   dataForm.append('limit_remote', 0);
    // } else if (!remoteWorking) {
    //   if (limit_remote) dataForm.append('limit_remote', limit_remote);
    // } else {
    //   if (limit_remote) dataForm.append('limit_remote', limit_remote);
    // }

    // if (remoteWorking && additional_limit_remote === 0 ) {
    //   dataForm.append('additional_limit_remote', 0);
    // } else if (!remoteWorking) {
    //   dataForm.append('additional_limit_remote', 0);
    // } else {
    //   if (additional_limit_remote) dataForm.append('additional_limit_remote', additional_limit_remote);
    // }

    // if (division_id) dataForm.append('division_id', division_id.map((x)=>x.id));
    if (division_id) dataForm.append('division_id', division_id);
    if (approval_type) dataForm.append('approval_type', approval_type);

    return !isEdit ? ( !isSuperAdmin ? createUser(dataForm) : createNewUser(payload) ) : (!isSuperAdmin ? updateUser(id, dataForm) : updateNewUser(id, payload));
  };

  handleSubmitDivision = (e) => {
    e.preventDefault();
    const {createDivision} = this.props;
    const {division_name, description} = this.state;
    const params = {
      ...(division_name && {division_name}),
      ...(description && {description}),
    };
    return createDivision(params);
  };

  handleSubmitJobTitle = (e) => {
    e.preventDefault();
    const {createUserJobTitle} = this.props;
    const {job_name, job_description} = this.state;
    const params = {
      ...(job_name && {name: job_name}),
      ...(job_description && {description: job_description}),
    };
    return createUserJobTitle(params);
  };

  handleSubmitAdvantech = () => {
    const {createUser, updateUser, isSuperAdmin} = this.props;
    const {isEdit, remoteWorking} = this.state;
    const {
      id,
      name,
      nik,
      address,
      job_title_id,
      email,
      dob,
      phone,
      join_date,
      role_id,
      manager,
      password,
      cropped_user_img,
      division_id,
      limit_remote,
      additional_limit_remote,
    } = this.state.formData;

    const dataForm = new FormData();
    if (name) dataForm.append('name', name);
    if (nik) dataForm.append('nik', nik);
    if (manager) {
      if (manager.manager_id !=='null' && manager.manager_id !==null) {
        dataForm.append('manager', manager.manager_id);
      } else {
        dataForm.append('manager', null);
      }
    } else {
      dataForm.append('manager', null);
    }
    if (job_title_id) dataForm.append('job_title_id', job_title_id);
    if (email) dataForm.append('email', email);
    if (address) dataForm.append('address', address);
    if (dob) dataForm.append('dob', convDate(dob));
    // if (join_date) dataForm.append('join_date', convDate(join_date));
    if (phone) dataForm.append('phone', phone);
    if (isEdit) {
      if (role_id) dataForm.append('role_id', role_id);
    } else {
      dataForm.append('role_id', isSuperAdmin ? role_id : 3);
    }
    // if (password) dataForm.append('password', password);
    if (cropped_user_img) {
      dataForm.append('user_img', blobToFile(cropped_user_img));
    }
    if (division_id) dataForm.append('division_id', division_id);


    // if (remoteWorking && limit_remote === 0) {
    //   dataForm.append('limit_remote', 0);
    // } else if (!remoteWorking) {
    //   if (limit_remote) dataForm.append('limit_remote', limit_remote);
    // } else {
    //   if (limit_remote) dataForm.append('limit_remote', limit_remote);
    // }
    // if (remoteWorking && additional_limit_remote === 0 ) {
    //   dataForm.append('additional_limit_remote', 0);
    // } else if (!remoteWorking) {
    //   dataForm.append('additional_limit_remote', 0);
    // } else {
    //   if (additional_limit_remote) dataForm.append('additional_limit_remote', additional_limit_remote);
    // }
    return !isEdit ? createUser(dataForm) : updateUser(id, dataForm);
  };

  handleSubmitImport = (e) => {
    e.preventDefault();
    const {importExcelUser} = this.props;
    const {file} = this.state;

    const excel = file.length > 0 && file[0] ? file[0] : null;
    const dataForm = new FormData();

    if (excel) dataForm.append('files', excel);

    importExcelUser(dataForm);
  };

  toggleModalUpgrade = (status, method) => {
    const {isPremium, expiredDate, isPaid, dueDate} = this.props;
    if (!status) status = !this.state.showUpgradeModal;
    if (!method) method = null;
    if ((isPremium && (getTimeDiff(expiredDate)+1) >= INVOICE_PERIOD && method !== 'meeting') || (isPaid && method !== 'meeting')) status = false;
    if ((!method && !isPremium && (getTimeDiff(dueDate)+1) < 0) || (!method && !dueDate)) method = 'plan';
    if (!method && !isPremium && (getTimeDiff(dueDate)+1) >= 0) method = 'xendit';
    if (!method && isPremium && (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD) method = 'xendit';
    this.setState({showUpgradeModal: status, showMethod: method});
  }

  renderModalUpgrade = () => {
    const {showUpgradeModal, showMethod, invoiceUrl} = this.state;
    return (
      <UpgradeAccountModal
        isOpen={showUpgradeModal}
        showMethod={showMethod}
        handleParentToggle={this.toggleModalUpgrade}
        invoiceUrl={invoiceUrl}
      />
    );
  };

  toggleCropperModal = (status) => {
    this.setState({isCropping: status});
  };

  handleCrop = (result) => {
    this.setState({
      formData: {
        ...this.state.formData,
        cropped_user_img: result.blob,
        imageURL: result.imageURL,
      },
    });
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.setState({searchFilterManager: query},
        () => {
          this.fetchManager();
        });
  }, 500);

  handleDebounceSearchQuery = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchUser(),
    );
  }, 500);

  selectHandler = (event, value) => {
    if (value) {
      value.manager_id = value.nik;
      value.manager_name = value.name;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        manager: value ? value : null,
      },
    });
  }

  resetManager = () => {
    this.setState({searchFilterManager: null});
  }

  handleImportFile = (files) => {
    this.setState({file: files}, () => console.log(this.state.file));
  }


  searchCombo = (event = null, value) => {
    this.handleDebounceSearch(event.target.value);
  }

  handleSwitchChange = (event) => {
    const value = event.target.checked;
    const {isEdit} = this.state;
    if (value === true) {
      this.setState({
        remoteWorking: value,
        formData: {
          ...this.state.formData,
          limit_remote: 0,
          additional_limit_remote: 0,
        },
      });
    } else if (value === false) {
      this.setState({
        remoteWorking: value,
        formData: {
          ...this.state.formData,
          limit_remote: null,
          additional_limit_remote: 0,
        },
      });
    }
  }

  renderModalForm = () => {
    const {
      classes,
      isLoading,
      isLoadingManager,
      jobData,
      roleData,
      isSuperAdmin,
      roleLoading,
      jobLoading,
      userData,
      managerData,
      divisionData,
      divisionLoading,
      isMultipleHR,
      statusData,
      organizationData,
    } = this.props;
    const {
      name,
      nik,
      address,
      job_title_id,
      email,
      dob,
      join_date,
      phone,
      role_id,
      password,
      imageURL,
      manager,
      user_img,
      division_id,
      limit_remote,
      additional_limit_remote,
      approval_type,
      organization_id,
    } = this.state.formData;
    const {
      isEdit,
      modalFormShow,
      showPassword,
      errorMessage,
      errorImageSize,
      showCropResult,
      isCropping,
      remoteWorking,
      errorMessageRemote,
      errorMessageAdditionalRemote,
      disableApprovalType,
      errorMessageEmail,
          } = this.state;
    const titleDialog = isEdit ? 'Update User' : 'Create User';
    const ManagerData = [];
    userData.map((value) => {
      return ManagerData.push(value.id);
    });

    const RoleOption = [];

    roleData.map((value) => {
      if (value.name !== 'Partner' && value.name !== 'Super Admin') {
        if (value.name === 'HR Admin') {
          RoleOption.push({id: value.id, name: `${value.name}`, isDisabled: value.name === 'HR Admin' && statusData.hr_limit < 1 ? true : false});
        } else {
          RoleOption.push({id: value.id, name: value.name, isDisabled: false});
        }
      }
    });

    const OrganizationOption = [];

    organizationData.map((value) => {
      OrganizationOption.push({id: value.id, name: value.name, isDisabled: false});
    });

    const renderDivisionOption = () => {
      const divisionList = [];
      divisionData.map((value) => {
        divisionList.push({id: value.id, name: value.division_name});
      });
      divisionList.push({id: 0, name: '+ Add New Division...'});
      return divisionList;
    };
    const renderApprovalTypeOption = () => {
      const options = [
        {id: 'none', name: 'None'},
        {id: 'master', name: 'Master'},
        {id: 'approval', name: 'Approval'},
      ];
      return options;
    };

    const divisionOption = [];
    divisionData.map((data) => {
      divisionOption.push({
        id: data.id,
        name: data.division_name,
      });
    });
    divisionOption.push({id: 0, name: '+ Add New Division...'});
    const jobTitleOption = [];
    jobData.map((data) => {
      jobTitleOption.push({
        id: data.id,
        name: data.name,
      });
    });
    // jobTitleOption.push({id: 0, name: '+ Add New Job Title...'});

    const manager_id = userData.filter((value) => {
      return value.nik === manager;
    });
    const optionEdit = managerData.filter((value) => {
      return value.name !== name;
    });

    const divisionNameSelected = divisionOption.find((x)=>x.id === division_id) ?? {};
    const jobTitleSelected = jobTitleOption.find((x)=>x.id === job_title_id) ?? {};

    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            className={classes.modalTitle}
            title={titleDialog}
            onClose={() => this.handleFormToggle('close')}
          />
        </MuiThemeProvider>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <Typography className={classes.personalInformation}>
              Personal Data
            </Typography>
            <MInput
              classNameFC={classes.formControl}
              name="name"
              label="Full Name *"
              placeholder="Full Name"
              defaultValue={name}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.name ?
                  errorMessage.name :
                  undefined
              }
              autoFocus
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {!isSuperAdmin ? (
                <MInput
                  classNameFC={classes.formControl}
                  name="nik"
                  label="NIK / Employee ID *"
                  placeholder="NIK"
                  defaultValue={nik}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.nik ? errorMessage.nik : undefined
                  }
                />
                ) : null}
              </Grid>
              <Grid item xs={6}>
                {!isSuperAdmin ? (
                <MDatepicker
                  className={classes.formControl}
                  name="dob"
                  label="Date Of Birth  *"
                  value={dob}
                  onDateChange={this.handleDateChange}
                  error={
                    errorMessage && errorMessage.dob ? errorMessage.dob : undefined
                  }
                  placeholder="dd-mm-yyyy"
                  maxDate={new Date()}
                  inputVariant="outlined"
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'date']}
                  fullWidth
                />
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="email"
                  label="Email Address *"
                  placeholder="Email"
                  type="email"
                  defaultValue={email}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.email ? errorMessage.email : undefined
                  }
                  autoComplete="new-email"
                />
              </Grid>
              <Grid item xs={12}>
                <MInputMask
                  classNameFC={classes.formControl}
                  name="phone"
                  label="Phone Number *"
                  placeholder="Phone"
                  type="number"
                  defaultValue={phone}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.phone ?
                      errorMessage.phone.split(':')[0] :
                      undefined
                  }
                />
              </Grid>
              {isSuperAdmin && (
                <>
                  <Grid item xs={12}>
                    <MSelectRole
                      classNameFC={classes.formControl}
                      name="role_id"
                      label="Role"
                      value={role_id}
                      onChange={this.handleInputChange}
                      error={
                        errorMessage && errorMessage.role_id ?
                          errorMessage.role_id :
                          statusData.hr_limit ? `HR Admin Remaining: ${statusData.hr_limit < 1 ? 0 : statusData.hr_limit}` : undefined
                      }
                      isError={errorMessage && errorMessage.role_id ? true : false}
                      keyPair={['id', 'name', 'isDisabled']}
                      options={RoleOption ? RoleOption : []}
                      loading={roleLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      className={classes.formControl}
                      ListboxProps={{style: {maxHeight: '12rem'}}}
                      value={organization_id}
                      onChange={(event, newValue) => {
                        if (event.target.value.length > 1) {
                          this.setState({errorMessage: {
                            organization_id: null,
                          }});
                        } else {
                          this.setState({
                            formData: {
                              ...this.state.formData,
                              organization_id: newValue.id,
                            },
                          });
                        }
                      }}
                      id="controllable-states-demo"
                      options={OrganizationOption}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          label="Transfer to Organization"
                          variant='outlined'
                          error={
                            errorMessage && errorMessage.organization_id ?
                              true : false
                          }
                          helperText={
                            errorMessage && errorMessage.organization_id ?
                              errorMessage.organization_id :
                              undefined
                          }
                          />
                      }
                      disableClearable
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {!isSuperAdmin ? (
                <MInput
                  classNameFC={classes.formControl}
                  name="address"
                  label="Address"
                  placeholder="Address"
                  type="text"
                  defaultValue={address}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.address ?
                      errorMessage.address.split(':')[0] :
                      undefined
                  }
                  autoComplete="new-address"
                  rows={3}
                  multiline
                />
            ) : null}
            {!isSuperAdmin ? (
              <>
                <MInput
                  classNameFC={classes.formControl}
                  name="user_img"
                  label="Photo"
                  placeholder="Photo"
                  type="file"
                  onChange={this.handleInputChange}
                  inputProps={{accept: 'image/*'}}
                  error={
                  errorImageSize ?
                    errorImageSize :
                    errorMessage && errorMessage.user_img ?
                    errorMessage.user_img :
                    undefined
                  }
                />
                <span><i>Must be .jpg/.jpeg with recommended resolution 200 x 200 px</i></span>
                {!isCropping && imageURL && (
                  <div style={{marginTop: '16px'}}>
                    <CardMedia
                      className={classes.photo}
                      title="Photo"
                      image={imageURL}
                      // style={{marginTop: '32px'}}
                    />
                    {user_img && (
                      <MButton
                        className={`${classes.defaultBtn} ${classes.btnCrop}`}
                        label="Retry"
                        icon={<Crop />}
                        onClick={() => this.toggleCropperModal(true)}
                      />
                    )}
                  </div>
                )}
                <ImageCropper
                  showModal={isCropping}
                  src={user_img}
                  showResult={showCropResult}
                  onClose={() => this.toggleCropperModal(false)}
                  onComplete={this.handleCrop}
                />
              </>
            ) : null}
            {!isSuperAdmin ? <Typography className={classes.employeeData}>Employee Data</Typography> : <></>}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {!isSuperAdmin ? (
                <Autocomplete
                  className={classes.formControl}
                  ListboxProps={{style: {maxHeight: '12rem'}}}
                  value={divisionNameSelected}
                  options={divisionOption}
                  getOptionLabel={(option)=>option.name || ''}
                  getOptionSelected={(option, value)=> option.id === value.id}
                  onChange={(event, newValue) => {
                    if (newValue.id === 0) {
                      this.setState({modalCreateDivision: true});
                    } else {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          division_id: newValue.id,
                        },
                        errorMessage: null,
                      });
                    }
                  }}
                  id="controllable-states-demo"
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Division *"
                      variant='outlined'
                      error={
                        errorMessage && (errorMessage.division_id || errorMessage.division) ?
                          true : false
                      }
                      helperText={
                        errorMessage && (errorMessage.division_id || errorMessage.division) ?
                        errorMessage.division ? errorMessage.division : errorMessage.division_id :
                          undefined
                      }
                      />
                  }
                  disableClearable
                />
              ) : (
                ''
              )}
              </Grid>
              <Grid item xs={6}>
                {!isSuperAdmin ? (
                <Autocomplete
                  className={classes.formControl}
                  ListboxProps={{style: {maxHeight: '12rem'}}}
                  value={jobTitleSelected}
                  // defaultValue={job_title_id}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    if (newValue.id === 0) {
                      this.setState({modalCreateJobTitle: true});
                    } else {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          job_title_id: newValue.id,

                        },
                      });
                    }
                  }}
                  id="controllable-states-demo"
                  options={jobTitleOption}
                  getOptionLabel={(option)=>option.name || ''}
                  getOptionSelected={(option, value)=>option.id === value.id}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label="Job Title *"
                      variant='outlined'
                      error={
                        errorMessage && errorMessage.job_title_id ?
                          true : false
                      }
                      helperText={
                        errorMessage && errorMessage.job_title_id ?
                          errorMessage.job_title_id :
                          undefined
                      }
                      />
                  }
                  disableClearable
                />
              ) : (
                ''
              )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
            {isMultipleHR ? (
              <Grid item xs={6}>
                  <div>
                    {!isSuperAdmin ? (
                      <div>
                        <MSelectRole
                          classNameFC={classes.formControl}
                          name="role_id"
                          label="Role *"
                          value={role_id}
                          onChange={this.handleInputChange}
                          error={
                            errorMessage && errorMessage.role_id ?
                              errorMessage.role_id :
                              statusData.hr_limit ? `HR Admin Remaining: ${statusData.hr_limit < 1 ? 0 : statusData.hr_limit}` : undefined
                          }
                          isError={errorMessage && errorMessage.role_id ? true : false}
                          keyPair={['id', 'name', 'isDisabled']}
                          options={RoleOption ? RoleOption : []}
                          loading={roleLoading}
                        />
                      </div>
                  ) : (
                    ''
                  )}
                  </div>
                </Grid>
            ) : (null)}
              <Grid item xs={6}>
                {!isSuperAdmin ? (
                  <Autocomplete
                    className={classes.formControl}
                    id="free-solo-demo"
                    freeSolo
                    options={isEdit ? optionEdit : managerData}
                    value={manager ? {nik: manager.manager_id, name: manager.manager_name} : null}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => this.selectHandler(event, value)}
                    style={{position: 'relative'}}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Supervisor"
                          variant="outlined"
                          fullWidth
                          onChange={(event, value) => this.searchCombo(event, value)}
                          // onClick={() => this.searchCombo()}
                        />
                        {isLoadingManager ? <CircularProgress color="inherit" size={24} style={{position: 'absolute', right: '35px', top: '15px'}}/> : null}
                      </>
                    )}
                  />
                ) : ''}
              </Grid>
              <Grid item xs={6}>
                <div>
                  {!isSuperAdmin ? (
                    <div>
                      <MSelect
                        classNameFC={classes.formControl}
                        name="approval_type"
                        value={approval_type}
                        label="Approval Type"
                        onChange={this.handleInputChange}
                        error={
                          errorMessage && errorMessage.approval_type ?
                            errorMessage.approval_type :
                            undefined
                        }
                        keyPair={['id', 'name']}
                        options={renderApprovalTypeOption()}
                        loading={divisionLoading}
                        disabled={disableApprovalType}
                      />
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                        <Typography style={{fontSize: 12, fontWeight: 400}}>Information</Typography>
                        <MuiThemeProvider theme={this.tooltipTheme()}>
                          <IconButton onClick={() => this.setState({modalApprovalInformation: !this.state.modalApprovalInformation})} style={{marginLeft: 4, bottom: 1}}>
                            <HelpOutline />
                          </IconButton>
                        </MuiThemeProvider>
                      </div>
                    </div>
                ) : (
                  ''
                )}
                </div>
              </Grid>
            </Grid>

            {/* <Grid container spacing={2}>
              <Grid item xs={6}>
                {!isSuperAdmin ? (
                <MInput
                  classNameFC={classes.formControl}
                  name="password"
                  label="Password *"
                  placeholder="***********"
                  autoComplete="new-password"
                  type={showPassword ? 'none' : 'password'}
                  defaultValue={!isEdit ? password : undefined}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.password ?
                      errorMessage.password :
                      undefined
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        position="end"
                        onClick={this.handleTogglePassword}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              ) : null}
              </Grid>
              <Grid item xs={6}>
                {!isSuperAdmin ? (
                  <MDatepicker
                    className={classes.formControl}
                    name="join_date"
                    label="Register Date"
                    value={join_date}
                    onDateChange={this.handleDateChange}
                    error={
                      errorMessage && errorMessage.join_date ? errorMessage.join_date : undefined
                    }
                    placeholder="dd-mm-yyyy"
                    maxDate={new Date()}
                    inputVariant="outlined"
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'date']}
                    fullWidth
                  />
                ) : null}
              </Grid>
            </Grid> */}
            {/* {!isSuperAdmin ? (
              <>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 12}}>
                  <Typography className={classes.remoteData} >Limit Remote Working</Typography>
                  <Switch color='primary' checked={remoteWorking} onChange={(event) => this.handleSwitchChange(event)}/>
                  <MuiThemeProvider theme={this.tooltipTheme()}>
                    <Tooltip title="Jika nonaktif maka karyawan tidak memiliki limit kuota remote working, jika aktif maka Anda dapat mengatur jumlah kuota dalam satu bulan">
                      <IconButton>
                        <HelpOutline />
                      </IconButton>
                    </Tooltip>
                  </MuiThemeProvider>
                </div>
                {remoteWorking ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div style={{display: 'flex'}}>
                        <MInput
                          classNameFC={classes.formControl}
                          name="limit_remote"
                          label="Remote Working Quota"
                          placeholder="Enter remote working quota"
                          type="number"
                          defaultValue={limit_remote}
                          onKeyDown={(event) => {
                            if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                              event.preventDefault();
                            }
                          }}
                          onBlur={this.handleInputChange}
                          error={
                            errorMessageRemote ? errorMessageRemote : undefined
                          }
                        />
                        <div style={{padding: '32px 0px 26px 8px'}}>
                          <MuiThemeProvider theme={this.tooltipTheme()}>
                            <Tooltip title="Kuota remote working yang dimiliki setiap karyawan dalam satu bulan. Jika diisi 0 maka tidak dapat melakukan remote working, jika diisi 5 maka user memiliki kuota 5 dalam satu bulan">
                              <IconButton>
                                <HelpOutline />
                              </IconButton>
                            </Tooltip>
                          </MuiThemeProvider>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{display: 'flex'}}>
                        <MInput
                          classNameFC={classes.formControl}
                          name="additional_limit_remote"
                          label="Additional Remote Limit"
                          placeholder="Enter additional remote limit"
                          type="number"
                          defaultValue={additional_limit_remote}
                          onKeyDown={(event) => {
                            if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                              event.preventDefault();
                            }
                          }}
                          onBlur={this.handleInputChange}
                          error={
                          errorMessageAdditionalRemote ? errorMessageAdditionalRemote : undefined
                          }
                        />
                        <div style={{padding: '32px 0px 26px 8px'}}>
                          <MuiThemeProvider theme={this.tooltipTheme()}>
                            <Tooltip title="Kuota tambahan yang hanya berlaku dalam satu bulan dan akan kembali nol di bulan berikutnya">
                              <IconButton>
                                <HelpOutline />
                              </IconButton>
                            </Tooltip>
                          </MuiThemeProvider>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ) : (<></>)} */}
            {/* {isSuperAdmin ? (
            <MSelect
              classNameFC={classes.formControl}
              name="role_id"
              label="Role"
              value={role_id}
              onChange={this.handleInputChange}
              error={
                errorMessage && errorMessage.role_id ?
                  errorMessage.role_id :
                  undefined
              }
              keyPair={['id', 'name']}
              options={roleData ? roleData : []}
              loading={roleLoading}
            />) : null } */}
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
              disabled={errorMessage ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };


  renderModalCreateDivision = () => {
    const {
      classes,
      isLoadingDivision,
    } = this.props;
    const {
      modalCreateDivision,
      errorMessageDivision,
      division_name,
      description,
    } = this.state;
    const titleDialog = 'Create Division';

    return (
      <Dialog
        open={modalCreateDivision}
        onClose={() => this.setState({
          modalCreateDivision: false,
          errorMessageDivision: {},
          division_name: null,
          description: null,
        })}
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            className={classes.modalTitle}
            title={titleDialog}
            onClose={() => this.setState({
              modalCreateDivision: false,
              errorMessageDivision: {},
              division_name: null,
              description: null,
            })}
          />
        </MuiThemeProvider>
        <form onSubmit={this.handleSubmitDivision} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <MInput
              classNameFC={classes.formControl}
              name="division_name"
              label="Division Name"
              placeholder="Enter division name"
              defaultValue={division_name}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageDivision && errorMessageDivision.division_name ?
                  errorMessageDivision.division_name :
                  undefined
              }
              autoFocus
            />
            <MInput
              classNameFC={classes.formControl}
              name="description"
              label="Description"
              placeholder="Enter description"
              defaultValue={description}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageDivision && errorMessageDivision.description ?
                  errorMessageDivision.description :
                  undefined
              }
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.setState({
                modalCreateDivision: false,
                errorMessageDivision: {},
                division_name: null,
                description: null,
              })}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoadingDivision ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderModalCreateJobTitle = () => {
    const {
      classes,
      isLoadingDivision,
    } = this.props;
    const {
      modalCreateJobTitle,
      errorMessageDivision,
      job_name,
      job_description,
    } = this.state;
    const titleDialog = 'Create Job Title';

    return (
      <Dialog
        open={modalCreateJobTitle}
        onClose={() => this.setState({
          modalCreateJobTitle: false,
          errorMessageDivision: {},
          job_name: null,
          job_description: null,
        })}
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            className={classes.modalTitle}
            title={titleDialog}
            onClose={() => this.setState({
              modalCreateJobTitle: false,
              errorMessageDivision: {},
              job_name: null,
              job_description: null,
            })}
          />
        </MuiThemeProvider>
        <form onSubmit={this.handleSubmitJobTitle} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <MInput
              classNameFC={classes.formControl}
              name="job_name"
              label="Job Title Name"
              placeholder="Enter Job Title name"
              defaultValue={job_description}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageDivision && errorMessageDivision.division_name ?
                  errorMessageDivision.division_name :
                  undefined
              }
              autoFocus
            />
            <MInput
              classNameFC={classes.formControl}
              name="job_description"
              label="Description"
              placeholder="Enter description"
              defaultValue={job_description}
              onBlur={this.handleDivisionForm}
              error={
                errorMessageDivision && errorMessageDivision.description ?
                  errorMessageDivision.description :
                  undefined
              }
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.setState({
                modalCreateJobTitle: false,
                errorMessageDivision: {},
                division_name: null,
                description: null,
              })}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoadingDivision ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderModalGuide = () => {
    const {classes} = this.props;
    const {modalGuideShow, file} = this.state;

    const guideList= [
      {
        title: 'Name',
        description: 'Field ini wajib diisi. Berisi nama lengkap yang akan tampil di aplikasi mobile karyawan Anda.',
      },
      {
        title: 'Email',
        description: 'Field ini wajib diisi. Pastikan alamat email yang diisi adalah alamat email aktif. Kode verifikasi untuk mengaktifkan akun akan dikirimkan ke alamat email ini. Alamat email ini juga akan digunakan untuk login oleh karyawan Anda.',
      },
      {
        title: 'Phone',
        description: 'Field ini wajib diisi. Format pengisian hanya menggunakan angka saja. Contoh 085003337788. Diharapkan tidak menggunakan simbol seperti (+, -)',
      },
      {
        title: 'Password',
        description: `Field ini wajib diisi. Password dapat Anda buat berbeda pada setiap nama, atau dibuat seragam. Karyawan Anda dapat mengatur ulang password ini pada aplikasi mobile di menu ‘Profil’.`,
      },
      {
        title: 'NIK',
        description: 'Field ini wajib diisi. Anda dapat memasukkan Nomor Induk Kependudukan sesuai dengan KTP atau Nomor Induk Karyawan perusahaan Anda.',
      },
      {
        title: 'Division_id',
        description: 'Field ini wajib diisi menggunakan format angka (ID) dan bukan berupa nama division. Anda harus membuat daftar divisi terlebih dahulu di menu Division Management. Lalu kembali ke menu User Management -> Import. Pada template document excel, Anda akan melihat daftar division beserta nomor (ID). Input ID tersebut pada tiap nama karyawan Anda.',
      },
      {
        title: 'Job_title_id',
        description: 'Field ini wajib diisi menggunakan format angka (ID) dan bukan berupa nama job title. Anda dapat mengetahui format angka (ID) ini sama seperti Division_id. Anda perlu membuat daftar job title terlebih dahulu di menu General Setting -> Company Setting -> Job Setting. Selanjutnya Anda dapat melihat job_title_id ini pada tab ketiga pada template document excel.',
      },
      {
        title: 'Dob',
        description: 'Field ini wajib diisi. Field ini diisi oleh tanggal lahir dengan format yyyy-mm-dd. Contoh jika lahir tanggal 17 Agustus 1945 maka format yang dimasukkan adalah 1945-08-17.',
      },
      {
        title: 'Supervisor_id',
        description: 'Field ini tidak wajib diisi. Jika diisi harus berupa NIK yang Anda daftarkan di menu User Management.',
      },
      {
        title: 'Join_date',
        description: 'Field ini wajib diisi. Berupa tanggal registrasi user. Misalnya diisi dengan tanggal hari ini, dengan format yyyy-mm-dd.',
      },
    ];

    return (
      <Dialog
        open={modalGuideShow}
        onClose={() => this.setState({modalGuideShow: false})}
        maxWidth='md'
      >
        <ModalTitle
          title={'Excel Template Guideline'}
          onClose={() => this.setState({modalGuideShow: false})}
          style={{color: '#656464'}}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContentGuide}>
            {guideList.map((value, index) => {
              return (
                <div key={index} style={{marginBottom: 24}}>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.titleGuide} >{index+1}.&nbsp;</Typography>
                    <Typography className={classes.titleGuide} >{value.title}</Typography>
                  </div>
                  <div style={{display: 'flex', marginTop: 8}}>
                    <Typography className={classes.subtitleGuide} style={{opacity: 0}}>{index+1}.&nbsp;</Typography>
                    <Typography className={classes.subtitleGuide}>{value.description}</Typography>
                  </div>
                </div>
              );
            })}
            <Typography className={classes.subtitleGuide} style={{marginTop: 32, marginBottom: 32}}>
              Anda dapat mengatur lebih lanjut selengkapnya di fitur <strong>User Management</strong> kemudian pilih nama yang ingin Anda perbarui, lalu klik ikon ‘update’.
            </Typography>
          </DialogContent>
        </form>
      </Dialog>
    );
  }

  renderApproalTypeGuide = () => {
    const {classes} = this.props;
    const {modalApprovalInformation, file} = this.state;

    const approvalList= [
      {
        title: 'Master',
        description: 'adalah pengguna yang memiliki hak untuk melakukan approval dari berbagai divisi. Type ini dapat terdiri dari beberapa pengguna. Master berlaku sebagai level approval terakhir. HR akan otomatis berlaku sebagai Master, dan Master dapat terdiri dari beberapa pengguna.',
      },
      {
        title: 'Approval',
        description: 'Adalah pengguna yang hanya memiliki hak untuk mendapatkan notifikasi dan melakukan approval dari satu divisi saja. Type ini dapat terdiri dari beberapa pengguna. Anda dapat mengatur urutan approve pada menu ‘Approval Order Setting’',
      },
    ];

    return (
      <Dialog
        open={modalApprovalInformation}
        onClose={() => this.setState({modalApprovalInformation: false})}
        maxWidth='sm'
      >
        <ModalTitle
          title={'Approval Type Information'}
          onClose={() => this.setState({modalApprovalInformation: false})}
          style={{color: '#656464'}}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContentGuide}>
            {approvalList.map((value, index) => {
              return (
                <div key={index} style={{marginBottom: 24}}>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.titleGuide} >{index+1}.&nbsp;</Typography>
                    <Typography className={classes.titleGuide} >{value.title}</Typography>
                  </div>
                  <div style={{display: 'flex', marginTop: 8}}>
                    <Typography className={classes.subtitleGuide} style={{opacity: 0}}>{index+1}.&nbsp;</Typography>
                    <Typography className={classes.subtitleGuide}>{value.description}</Typography>
                  </div>
                </div>
              );
            })}
          </DialogContent>
        </form>
      </Dialog>
    );
  }

  renderModalImport = () => {
    const {classes, importLoading} = this.props;
    const {modalImportShow, file} = this.state;

    const template = window.location.origin+'/download/users-import-template.xlsx';

    return (
      <Dialog
        open={modalImportShow}
        onClose={() => this.handleModalImport('close')}
        maxWidth='md'
      >
        <ModalTitle
          title={'Import User'}
          onClose={() => this.handleModalImport('close')}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <DropzoneArea
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{container: {spacing: 1, direction: 'row'}}}
              previewChipProps={{classes: {root: classes.previewChip}}}
              previewText="Selected files"
              dropzoneText='Drag and drop an Excel (.csv/.xls/.xlsx) file here or click'
              filesLimit={1}
              classes={{root: `${file && file.length > 0 ? classes.hidden : classes.dropZoneArea}`, text: classes.dropZoneText, icon: classes.dropZoneIcon}}
              acceptedFiles={['.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
              onChange={(files) => this.handleImportFile(files)}
            />
            <div style={{marginTop: 24}}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Typography className={classes.fontGuide}>See the description of how to fill in the template&nbsp;</Typography>
                <Link onClick={() => this.setState({modalGuideShow: !this.state.modalGuideShow})}>
                  <Typography className={classes.guideLinkPopup} color='primary' style={{fontWeight: 'bold'}}>here</Typography>
                </Link>
              </div>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Typography className={classes.fontGuide}>or&nbsp;</Typography>
                <Link onClick={() => this.props.downloadExcelTemplate()} download target="_blank">
                  <Typography className={classes.guideLinkPopup} color='primary' style={{fontWeight: 'bold'}}>download Excel Template</Typography>
                </Link>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Grid container justify='space-between'>
              <Grid item spacing={2}>
                <MButton
                  label="Cancel"
                  style={{marginRight: 15}}
                  onClick={() => this.handleModalImport('close')}
                />
                <MButton
                  className={classes.defaultBtn}
                  label={'Import'}
                  type="submit"
                  icon={<Send />}
                  loading={importLoading ? true : false}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  handleSyncAdvantech = () => {
    const {orgStatusData} = this.props;
    if (orgStatusData && orgStatusData.adv_sync) {
      showPopup(
          'confirm',
          'Are you sure you want to stop the sync process?',
          'Yes',
          () => this.props.cancelAdvSync(),
      );
    } else if (orgStatusData && !orgStatusData.adv_sync) {
      showPopup(
          'confirm',
          'Are you sure you want to sync users data to advantech?, it will take a while',
          'Yes',
          () => this.props.syncAdvantech(),
      );
    }
  }

  handleSyncAdvantechSingle = async (data) => {
    const {isSuperAdmin} = this.props;
    let job = this.handleGetJobInfo(data[11]);
    const role = this.handleGetRoleInfo(data[8]);
    const manager = await this.props.getOneManager(data[1]);
    if (isSuperAdmin) job = 1; // superadmin handler
    if (!isSuperAdmin) job = job ? job[0].id : null;
    this.handleReset();
    this.setState({
      isEdit: true,
      remoteWorking: data[17] === null ? false : true,
      disableApprovalType: data[9] === 'HR Admin' ? true : false,
      formData: {
        id: data[1],
        name: data[2],
        nik: data[3],
        role_id: role[0].id,
        job_title_id: job,
        manager: manager,
        join_date: data[13],
        email: data[5],
        dob: data[7] ? convDate(data[6]) : null,
        phone: data[8],
        imageURL: data[14],
        limit_remote: data[17],
        additional_limit_remote: data[18],
        approval_type: data[9] === 'HR Admin' ? 'master' : data[12],
        division_id: data[20],
      },
    }, () => this.handleSubmitAdvantech());
  }

  handleCloseSnackbar = () => {
    this.setState({snackbar: false});
  };

  renderSnackbar = () => {
    const {snackbar, snackbarVertical, snackbarHorizontal} = this.state;
    const action = (
      <Button color="primary" size="small" onClick={() => this.props.history.push('/export-activity')}>
        View Activity
      </Button>
    );
    return (
      <ThemeProvider theme={this.snackbarTheme()}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar}
          onClose={this.handleCloseSnackbar}
          message="Your file is in the queue, click here to see the progress"
          key={snackbarVertical + snackbarHorizontal}
          action={action}
        />
      </ThemeProvider>
    );
  }

  renderFilter = () => {
    const {classes, attendanceTypes, exportLoading, isSuperAdmin, orgStatusData, userData} = this.props;
    const {query, employment_status, approval_type} = this.state;

    const renderEmploymentOption = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'active', name: 'Active'},
        {id: 'inactive', name: 'Inactive'},
      ];
      return options;
    };

    const renderEmploymentStatus = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'active', name: 'Active'},
        {id: 'inactive', name: 'Inactive'},
        {id: 'expired soon', name: 'Expired Soon'},
      ];
      return options;
    };

    const renderApprovalOption = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'none', name: 'None'},
        {id: 'master', name: 'Master'},
        {id: 'approval', name: 'Approval'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        <MInput
          autoFocus
          className={`${classes.filter} ${classes.searchInput}`}
          fullWidth={false}
          variant="standard"
          name="query"
          label="Search"
          placeholder="Search keyword"
          value={query}
          onChange={this.handleSearch}
          InputProps={{endAdornment: <IconButton className={classes.searchIcon} position="end"> <Search/></IconButton>}}
        />
        <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={classes.employmentInput}
          style={{minWidth: 200, marginRight: 24}}
          variant="standard"
          name="approval_type"
          label="Approval type"
          keyPair={['id', 'name']}
          options={renderApprovalOption()}
          value={approval_type}
          onChange={this.handleFilterApproval}
        />
        <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={classes.employmentInput}
          style={{minWidth: 200, marginRight: 24}}
          variant="standard"
          name="employment_status"
          label="Employment Status"
          keyPair={['id', 'name']}
          options={renderEmploymentStatus()}
          value={employment_status}
          onChange={this.handleFilterEmployment}
        />
        {/* <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={classes.employmentInput}
          style={{minWidth: 200, marginRight: 24}}
          variant="standard"
          name="method"
          label="Employment Status"
          keyPair={['id', 'name']}
          options={renderEmploymentOption()}
          value={employment_status}
          onChange={this.handleFilterEmployment}
        /> */}
        <div className={classes.btnFilterContainer}>
          <MButton
            className={`${classes.btnExport} ${classes.filterBtnEnd}`}
            label="Reset"
            icon={<Refresh/>}
            onClick={this.handleResetFilter}
          />
        </div>
        {
          !isSuperAdmin && (
            <>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label="Export"
                  icon={<GetApp/>}
                  onClick={this.handleExport}
                  loading={exportLoading}
                  disabled={userData?.length < 1 ? true : false}
                />
              </div>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label={orgStatusData && orgStatusData.import_users ? 'Cancel Import' : 'Import'}
                  icon={orgStatusData && orgStatusData.import_users ? <CancelOutlined/> : <Publish/>}
                  onClick={() => {
orgStatusData && orgStatusData.import_users ? this.props.cancelImportUser() : this.handleModalImport();
                  }}
                  loading={exportLoading}
                />
              </div>
            </>
          )
        }
      </Paper>
    );
  }

  renderActions = (params) => {
    const {orgStatusData, isSuperAdmin} = this.props;
    const rowDataDisable = 12;
    const disableCondition = orgStatusData && orgStatusData.adv_sync;
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Tooltip title="Edit">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => this.handleEditDialogToggle(params)}
                startIcon={<Edit />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
                disabled={
                  (!rowDataDisable ? false : !disableCondition === null ? !params[rowDataDisable] : disableCondition && !params[rowDataDisable]) ||
                  (orgStatusData && orgStatusData.import_users)
                }
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        {!isSuperAdmin && (
          <Grid item>
            <Tooltip title="Detail">
              <div style={{display: 'inline', marginRight: '5px'}}>
                <Button
                  variant="contained"
                  color="primary"
                  size='small'
                  onClick={() => this.handleDetailUser(params)}
                  startIcon={<Search />}
                  style={{
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                >
                </Button>
              </div>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <Tooltip title={params[9] === 'HR Admin' ? 'Info' : 'Delete'}>
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color={params[9] === 'HR Admin' ? 'primary' : 'secondary'}
                size='small'
                onClick={() => this.handleConfirmDelete(params)}
                startIcon={params[9] === 'HR Admin' ? <Info /> : <Delete />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
                disabled={
                  (!rowDataDisable ? false : !disableCondition === null ? !params[rowDataDisable] : disableCondition && !params[rowDataDisable]) ||
                  (orgStatusData && orgStatusData.import_users)
                }
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {classes, isLoading, userData, userMeta, orgStatusData, isLoadingOrg, isSuperAdmin, isMultipleHR, menusData} = this.props;
    const displayJobTitle = this.props.isSuperAdmin ? false : true;
    const {resetDatatable, resetPage, advantech, userDatatable} = this.state;


    const columns = [
      {name: 'no', label: 'No', display: true}, // Data[0]
      {name: 'id', label: 'id', display: false}, // Data[1]
      {name: 'name', label: 'Name', display: true}, // Data[2]
      {name: 'nik', label: 'NIK', display: isSuperAdmin ? false : false}, // Data[3]
      {
        name: 'manager_name', // Data[4]
        label: 'Supervisor',
        display: isSuperAdmin ? false : false,
        customBodyRender: (value) => {
          return value ? <Typography style={{fontSize: 13, textTransform: 'capitalize'}}>{value}</Typography> : '-';
        },
      },
      {name: 'email', label: 'Email', display: true}, // Data[5]
      {name: 'address', label: 'Address', display: false}, // Data[6]
      {
        name: 'dob', // Data[7]
        label: 'Date of Birth',
        display: isSuperAdmin ? false : false,
        customBodyRender: (value) => {
          return (
            <div style={{whiteSpace: 'nowrap'}}>
              {value ? convDate(value, 'DD-MM-YYYY') : '-'}
            </div>
          );
        },
      },
      {name: 'phone', label: 'Phone', display: true}, // Data[8]
      {name: 'role', label: 'Role', display: isSuperAdmin ? true : true}, // Data[9]
      {
        name: 'division_name', // Data[10]
        label: 'Division',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => {
          return value ? <Typography style={{fontSize: 13, textTransform: 'capitalize'}}>{value}</Typography> : '-';
        },
      },
      {name: 'job_title', label: 'Job Title', display: isSuperAdmin ? false : true}, // Data[11]
      {
        name: 'approval_type', // Data[12]
        label: 'Approval Type',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => {
          return value ? <Typography style={{fontSize: 13, textTransform: 'capitalize'}}>{value}</Typography> : '-';
        },
      },
      //
      {
        name: isSuperAdmin ? 'register_date' : 'join_date', // Data[13]
        label: 'Register Date',
        display: false,
        customBodyRender: (value) => {
          return (
            <div style={{whiteSpace: 'nowrap'}}>
              {value ? convDate(value, 'DD-MM-YYYY') : '-'}
            </div>
          );
        },
      },
      {name: 'image', label: 'Photo', display: false}, // Data[14]
      {
        name: 'advantech', // Data[15]
        label: 'Advantech Sync',
        // display: advantech && menusData.advantech ? true : false,
        display: false,
        customBodyRender: (value, userData) => {
          return (
            <Grid container justify="center" wrap="nowrap">
              <Tooltip
                title={
                  value ?
                    'synchronized' :
                    orgStatusData && orgStatusData.adv_sync ?
                    'Sync in progress' :
                    'click to sync user'
                }
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    orgStatusData && !orgStatusData.adv_sync && !value ?
                      this.handleSyncAdvantechSingle(userData.rowData) :
                      undefined
                  }
                  style={{
                    maxWidth: '35px',
                    maxHeight: '35px',
                    minWidth: '35px',
                    minHeight: '35px',
                  }}
                  disabled={orgStatusData && orgStatusData.import_users}
                >
                  {value ? (
                    <CheckCircleOutline className={classes.syncTrue} />
                  ) : orgStatusData && orgStatusData.adv_sync ? (
                    <HourglassEmpty className={classes.syncFalse} />
                  ) : (
                    <Sync className={classes.syncFalse} />
                  )}
                </Button>
              </Tooltip>
              {!userData.rowData[13] ? undefined : (
                <Tooltip
                  title={userData && userData.rowData && userData.rowData[13]}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      maxWidth: '35px',
                      maxHeight: '35px',
                      minWidth: '35px',
                      minHeight: '35px',
                      marginLeft: '5px',
                    }}
                  >
                    {userData && userData.rowData && userData.rowData[13] && (
                      <WarningOutlined className={classes.syncWarn} />
                    )}
                  </Button>
                </Tooltip>
              )}
            </Grid>
          );
        },
      },
      {name: 'logs.log', label: 'Log', display: false}, // Data[16]
      {name: 'limit_remote', label: 'Remote Working Quota', display: false}, // Data[17]
      {name: 'additional_limit_remote', label: 'Additional Limit Remote', display: false}, // Data[18]
      {name: 'image', label: 'Photo', display: false}, // Data[19]
      {name: 'division_id', label: 'Division ID', display: false}, // Data[20]
      {name: 'status', label: 'Employment Status', display: true,
        customBodyRender: (value, params) => {
          if (value === 'active') {
            return <strong style={{color: '#008000'}}>{'Active'}</strong>;
          } else if (value === 'inactive') {
            return <strong style={{color: '#F00B38'}}>{'Inactive'}</strong>;
          } else if (value === 'expired soon') {
            return (
              <Tooltip title={'End Date: ' + convDate(params.rowData[23], 'DD MMMM YYYY')}>
                <strong style={{color: '#E5AF5A'}}>{'Expired Soon'}</strong>
              </Tooltip>
            );
          } else {
            return '-';
          }
        },
      }, // Data[21]
      {name: 'status', label: 'Contract Status', display: false}, // Data[22]
      {name: 'end_date', label: 'End Date', display: false}, // Data[22]
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        {this.renderModalGuide()}
        {this.renderApproalTypeGuide()}
        {this.renderModalCreateDivision()}
        {this.renderModalCreateJobTitle()}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Datatable
              title={'Employee List'}
              loading={isLoading}
              dataSource={userDatatable}
              total={userMeta ? userMeta.total : 0}
              page={userMeta ? userMeta.page : 1}
              columns={columns}
              handleReload={(params) => this.handleReload(params)}
              handleCreate={() => this.handleFormToggle('add')}
              toggleResetPage={resetPage}
              manualNumbering={true}
              customActions={(data) => this.renderActions(data)}
              customTools={
                isSuperAdmin || (menusData && menusData.advantech === false) || advantech === false ? undefined :
                (
                <div style={{float: 'right'}}>
                  <Grid container justify='center'>
                    <Tooltip title="Advantech Sync">
                      <IconButton
                        onClick={() => this.handleSyncAdvantech()}
                        disabled={isLoadingOrg || (orgStatusData && orgStatusData.import_users) ? true : false}
                      >
                        {isLoadingOrg ? <CircularProgress color="inherit" size={20}/> : orgStatusData && orgStatusData.adv_sync ? <CancelOutlined /> : <Sync />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <p style={{fontSize: 12, marginBottom: 0, marginTop: -8}}>
                    {isLoadingOrg ? null : orgStatusData && orgStatusData.adv_sync ? 'Stop Sync' : 'Advantech Sync'}
                  </p>
                </div>
                )
              }
            />
          </Grid>
        </Grid>
        {this.renderModalForm()}
        {this.renderModalImport()}
        {this.renderModalUpgrade()}
        {this.renderSnackbar()}
      </div>
    );
  }
}

const stateToProps = ({userManagement, jobTitle, upgradeAccount, auth, organization, xendit, generalSetting, divisionManagement}) => ({
  token: auth.data.authorization,
  isMultipleHR: auth.data.menus?.multiple_hr,
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: userManagement.isLoading,
  isLoadingManager: userManagement.isLoadingManager,
  isError: userManagement.isError,
  isSuccess: userManagement.isSuccess,
  message: userManagement.message,
  trigger: userManagement.trigger,
  premium: upgradeAccount.premium,
  creatable: upgradeAccount.creatable,
  creatableMessage: upgradeAccount.creatableMessage,
  userData: userManagement.userData,
  managerData: userManagement.managerData,
  userMeta: userManagement.userMeta,
  roleLoading: userManagement.roleLoading,
  roleData: userManagement.roleData,
  jobLoading: jobTitle.jobLoading,
  jobData: jobTitle.jobDataByOrg,
  orgStatusData: organization.orgStatusData,
  isPremium: upgradeAccount.premium,
  isPaid: upgradeAccount.payment,
  statusData: upgradeAccount.data,
  expiredDate: upgradeAccount.expiredDate,
  dueDate: upgradeAccount.dueDate,
  xenditStatus: xendit.xenditStatus,
  isSuccessXendit: xendit.isSuccess,
  triggerXendit: xendit.trigger,
  messageXendit: xendit.message,
  isErrorXendit: xendit.isError,
  isLoadingXendit: xendit.isLoading,
  data: generalSetting.data,
  isLoadingOrg: upgradeAccount.isLoading,
  menusData: auth.data.menus,
  exportLoading: userManagement.exportLoading,
  exportData: userManagement.exportData,
  downloadLoading: userManagement.downloadLoading,
  downloadData: userManagement.downloadData.file,
  importLoading: userManagement.importLoading,
  isSuccessOrg: organization.isSuccess,
  triggerOrg: organization.trigger,
  organizationData: organization.organizationData,

  isLoadingDivision: divisionManagement.isLoading,
  isErrorDivision: divisionManagement.isError,
  isSuccessDivision: divisionManagement.isSuccess,
  divisionMessage: divisionManagement.message,
  triggerDivision: divisionManagement.trigger,
  divisionData: divisionManagement.divisionData,
  divisionMeta: divisionManagement.divisionMeta,

  isSuccessJob: userManagement.isSuccessJob,
  isErrorJob: userManagement.isErrorJob,
  isLoadingJob: userManagement.isLoadingJob,
  triggerJob: userManagement.triggerJob,

});

const dispatchToProps = (dispatch) => ({
  getAllUser: (params) => dispatch(getAllUser(params)),
  getNewUsers: (params) => dispatch(getNewUsers(params)),
  getAllManager: (params) => dispatch(getAllManager(params)),
  getAllRole: () => dispatch(getAllRole()),
  createUser: (data) => dispatch(postUser(data)),
  createNewUser: (data) => dispatch(postNewUser(data)),
  getOneManager: (id) => dispatch(getOneManager(id)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
  updateNewUser: (id, data) => dispatch(updateNewUser(id, data)),
  dropUser: (id) => dispatch(dropUser(id)),
  checkPremium: () => dispatch(checkPremium()),
  clearState: () => dispatch(clearState()),
  getJobTitleByOrg: (params) => dispatch(getJobTitleByOrg(params)),
  checkStatus: () => dispatch(checkStatus()),
  syncAdvantech: () => dispatch(syncAdvantech()),
  cancelAdvSync: () => dispatch(cancelAdvSync()),
  cancelImportUser: () => dispatch(cancelImportUser()),
  exportExcelUser: (params) => dispatch(exportExcelUser(params)),
  importExcelUser: (data) => dispatch(importExcelUser(data)),
  downloadExcelTemplate: (params) => dispatch(downloadExcelTemplate(params)),
  checkStatusUser: () => dispatch(checkStatusUser()),
  getOrganizationList: (params) => dispatch(getOrganizationList(params)),

  getDivision: (params) => dispatch(getDivision(params)),
  createDivision: (params) => dispatch(createDivision(params)),
  clearStateDivision: () => dispatch(clearState()),

  createUserJobTitle: (data) => dispatch(createUserJobTitle(data)),
  updateExportNotification: (params) => dispatch(updateExportNotification(params)),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(UserManagement));
