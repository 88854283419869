import {EVENT_MANAGEMENT} from '../../services';
import {EVENT_MANAGEMENT_STATE as STATE} from './eventManagement.types';
import {transformObject, isArray, toast} from '../../helpers';

export const createEvent = (params, history) => {
  return (dispatch) => {
    dispatch(request(STATE.REQUEST));
    EVENT_MANAGEMENT.createEvent(params)
        .then(
            (response) => {
              const data = response?.data?.data;
              dispatch(requestSuccess(STATE.CREATE_EVENT_SUCCESS, data?.message));
              dispatch(requestSuccess(STATE.FETCH_EVENT_DETAIL_SUCCESS, data));
              toast('success', 'Event created successfully');
              history.push('/event-management/detail', {id: data?.id});
            },
            (error) => {
              const errors = error?.response?.data?.data?.errors;
              const message = isArray(errors) ? transformObject(errors) : error?.response?.data?.data?.message;
              dispatch(requestFailed(STATE.CREATE_EVENT_FAILED, message));

              if (!isArray(errors)) {
                toast('error', message);
              }
            },
        );
  };
};

export const updateEvent = (id, params, history) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_EVENT));
    EVENT_MANAGEMENT.updateEvent(id, params)
      .then(
        (response) => {
          const data = response?.data?.data;
          dispatch(requestSuccess(STATE.UPDATE_EVENT_SUCCESS, data?.message));
          toast('success', 'Event updated successfully');
          setTimeout(() => history.push('/event-management/detail', {id}), 1000);
        },
        (error) => {
          const errors = error?.response?.data?.data?.errors;
          const message = isArray(errors) ? transformObject(errors) : error?.response?.data?.data?.message;
          dispatch(requestFailed(STATE.UPDATE_EVENT_FAILED, message));

          if (!isArray(errors)) {
            toast('error', message);
          }
        },
      );
  };
};

export const requestCancelEvent = (params, body, filterState = null) => {
  return (dispatch) => {
    dispatch(request(STATE.REQUEST_CANCEL_EVENT));
    EVENT_MANAGEMENT.requestCancelEvent(params, body)
      .then(
        (response) => {
          dispatch(togglePopupRequestCancelEvent(false));
          dispatch(requestSuccess(STATE.REQUEST_CANCEL_EVENT_SUCCESS, response?.data?.data?.message));
          dispatch(getEvents(filterState));

          toast(
            'success',
            'Your request is On Review, please wait or contact our team support',
          );
        },
        (error) => {
          const errors = error?.response?.data?.data?.errors;
          const message = isArray(errors) ? transformObject(errors) : error?.response?.data?.data?.message;
          dispatch(requestFailed(STATE.REQUEST_CANCEL_EVENT_FAILED, message));
        },
      );
  };
};

export const cancelApprovalEvent = (id, params, filterState = null) => {
  return (dispatch) => {
    dispatch(request(STATE.CANCEL_APPROVAL_EVENT));
    EVENT_MANAGEMENT.cancelApprovalEvent(id, params)
      .then(
        (response) => {
          dispatch(togglePopupCancelApproval(false));
          dispatch(requestSuccess(STATE.CANCEL_APPROVAL_EVENT_SUCCESS, response?.data?.data?.message));
          dispatch(getEvents(filterState));
          toast('success', 'Your approval has been submitted');
        },
        (error) => {
          const errors = error?.response?.data?.data?.errors;
          const message = isArray(errors) ? transformObject(errors) : error?.response?.data?.data?.message;
          dispatch(requestFailed(STATE.CANCEL_APPROVAL_EVENT_FAILED, message));
          toast('error', 'Failed to submit your approval, please try again later');
        },
      );
  };
};

export const blockEvent = (id, params, filterState = null) => {
  return (dispatch) => {
    dispatch(request(STATE.BLOCK_EVENT));
    EVENT_MANAGEMENT.blockEvent(id, params)
      .then(
        (response) => {
          dispatch(togglePopupBlockEvent(false));
          dispatch(requestSuccess(STATE.BLOCK_EVENT_SUCCESS, response?.data?.data?.message));
          dispatch(getEvents(filterState));
          toast('success', 'The event successfully blocked');
        },
        (error) => {
          const errors = error?.response?.data?.data?.errors;
          const message = isArray(errors) ? transformObject(errors) : error?.response?.data?.data?.message;
          dispatch(requestFailed(STATE.BLOCK_EVENT_FAILED, message));
          toast('error', 'Failed to block the event, please try again later');
        },
      );
  };
};

export const getEvents = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_EVENTS));
    EVENT_MANAGEMENT.getEvents(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.FETCH_EVENTS_SUCCESS, response?.data?.data));
        },
        (error) => {
          const response = error?.response?.data;
          dispatch(requestFailed(STATE.FETCH_EVENTS_FAILED, response));
        },
      );
  };
};

export const getEventDetail = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_EVENT_DETAIL));
    EVENT_MANAGEMENT.getEventDetail(params)
      .then(
        (response) => {
          const {data} = response?.data;
          dispatch(requestSuccess(STATE.FETCH_EVENT_DETAIL_SUCCESS, data));
        },
        (error) => {
          const response = error?.response?.data;
          dispatch(requestFailed(STATE.FETCH_EVENT_DETAIL_FAILED, response));
        },
      );
  };
};

export const getParticipants = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_PARTICIPANTS));
    EVENT_MANAGEMENT.getParticipants(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.FETCH_PARTICIPANTS_SUCCESS, response?.data?.data));
        },
        (error) => {
          const response = error?.response?.data;
          dispatch(requestFailed(STATE.FETCH_PARTICIPANTS_FAILED, response));
        },
      );
  };
};

export const getEventQR = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_EVENT_QR));
    EVENT_MANAGEMENT.getEventQR(params)
      .then(
        (response) => {
          const {data} = response?.data || {};
          dispatch(requestSuccess(STATE.FETCH_EVENT_QR_SUCCESS, data));
        },
        (error) => {
          const response = error?.response?.data;
          dispatch(requestFailed(STATE.FETCH_EVENT_QR_FAILED, response));
        },
      );
  };
};

export const generateEventQR = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GENERATE_EVENT_QR));
    EVENT_MANAGEMENT.generateEventQR(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.GENERATE_EVENT_QR_SUCCESS, response?.data?.data));
          getEventQR(params);
        },
        (error) => {
          const response = error?.response?.data;
          dispatch(requestFailed(STATE.GENERATE_EVENT_QR_FAILED, response));
        },
      );
  };
};

export const togglePopupAnnouncement = (params = null) => {
  return (dispatch) => {
    dispatch(requestSuccess(STATE.TOGGLE_POPUP_EVENT_ANNOUNCEMENT, params));
  };
};

export const togglePopupRequestCancelEvent = (params = null) => {
  return (dispatch) => {
    dispatch(requestSuccess(STATE.TOGGLE_POPUP_REQUEST_CANCEL_EVENT, params));
  };
};

export const togglePopupCancelApproval = (params = null) => {
  return (dispatch) => {
    dispatch(requestSuccess(STATE.TOGGLE_POPUP_CANCEL_APPROVAL_EVENT, params));
  };
};

export const togglePopupBlockEvent = (params = null) => {
  return (dispatch) => {
    dispatch(requestSuccess(STATE.TOGGLE_POPUP_BLOCK_EVENT, params));
  };
};

export const sendAnnouncement = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.SEND_EVENT_ANNOUNCEMENT));
    EVENT_MANAGEMENT.sendAnnouncement(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.SEND_EVENT_ANNOUNCEMENT_SUCCESS, response?.data?.data));
          toast('success', 'Announcement created successfully');
        },
        (error) => {
          const response = error?.response?.data;
          dispatch(requestFailed(STATE.SEND_EVENT_ANNOUNCEMENT_FAILED, response));
          toast('error', 'Failed to create announcement, please try again later');
        },
      );
  };
};

export const updateVoucher = (id, params) => {
  return (dispatch, getState) => {
    dispatch(request(STATE.UPDATE_EVENT_VOUCHER));
    EVENT_MANAGEMENT.updateEventVoucher(id, params)
      .then(
        (response) => {
          const data = response?.data?.data;
          dispatch(requestSuccess(STATE.UPDATE_EVENT_VOUCHER_SUCCESS, data?.message));

          const updatedVouchers = [...params?.voucher];

          const updatedState = {
            ...getState()?.eventManagement?.eventDetail,
            voucher: [...updatedVouchers],
          };

          dispatch(requestSuccess(STATE.FETCH_EVENT_DETAIL_SUCCESS, {...updatedState}));
          toast('success', 'Voucher updated successfully');
        },
        (error) => {
          const errors = error?.response?.data?.data?.errors;
          const message = isArray(errors) ? transformObject(errors) : error?.response?.data?.data?.message;
          dispatch(requestFailed(STATE.UPDATE_EVENT_VOUCHER_FAILED, message));

          if (!isArray(errors)) {
            toast('error', message);
          }
        },
      );
  };
};

export const exportParticipant = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT_PARTICIPANTS));
    EVENT_MANAGEMENT.exportParticipants(params).then(
      (response) => {
        dispatch(requestSuccess(STATE.EXPORT_PARTICIPANTS_SUCCESS, response.data.data));
      },
    ).catch((error) => {
      dispatch(requestFailed(STATE.EXPORT_PARTICIPANTS_FAILED));
    });
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType = null) => ({
  type: requestType || STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: requestType || STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});
