/* eslint-disable no-duplicate-case */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import {initialState, USER_STATE} from './userManagement.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingContract: true,
        isError: null,
        isSuccess: null,
        message: null,
      };
    case USER_STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoadingContract: false,
        isError: action.requestType,
        message: action.payload,
      };
      case USER_STATE.REQUEST_JOB:
      return {
        ...state,
        isLoadingJob: true,
        isErrorJob: null,
        isSuccessjob: null,
        message: null,
      };
    case USER_STATE.REQUEST_JOB_FAILED:
      return {
        ...state,
        isLoadingJob: false,
        isErrorJob: action.requestType,
        message: action.payload,
      };
    case USER_STATE.REQUEST_CONTRACT:
      return {
        ...state,
        isLoadingContract: true,
        isError: null,
        isSuccess: null,
        message: null,
      };
    case USER_STATE.CREATE_JOB_SUCCESS:
      return {
        ...state,
        isLoadingJob: false,
        isSuccessJob: true,
        triggerJob: action.type,
        message: action.payload,
      };
    case USER_STATE.CREATE_FAILED:
      const messages = {};
      for (let i = 0; i < action.message.length; i++) messages[action.message[i].field] = action.message[i].value;
      return {
        ...state,
        isLoadingJob: false,
        isErrorJob: true,
        isSuccessJob: false,
        message: messages,
        triggerJob: USER_STATE.CREATE_JOB_FAILED,
      };
    case USER_STATE.REQUEST_CONTRACT_FAILED:
      return {
        ...state,
        isLoadingContract: false,
        isError: action.requestType,
        message: action.payload,
      };
      case USER_STATE.REQUEST_LEAVE:
      return {
        ...state,
        isLoadingLeave: true,
        isLoading: true,
        isError: null,
        isSuccess: null,
        message: null,
      };
      case USER_STATE.REQUEST_LEAVE_FAILED:
      return {
        ...state,
        isLoadingLeave: false,
        isLoading: false,
        isError: action.requestType,
        message: action.payload,
      };
      case USER_STATE.REQUEST_UPDATE_HISTORY:
      return {
        ...state,
        isLoadingUpdateHistory: true,
        isErrorUpdateHistory: null,
        isSuccessUpdateHistory: null,
        message: null,
      };
    case USER_STATE.REQUEST_UPDATE_HISTORY_FAILED:
      return {
        ...state,
        isLoadingUpdateHistory: false,
        isErrorUpdateHistory: action.requestType,
        message: action.payload,
      };
    case USER_STATE.REQUEST_LEAVE_HISTORY:
      return {
        ...state,
        isLoadingLeaveHistory: true,
        isErrorUpdateHistory: null,
        isSuccessUpdateHistory: null,
        message: null,
      };
    case USER_STATE.REQUEST_LEAVE_HISTORY_FAILED:
      return {
        ...state,
        isLoadingLeaveHistory: false,
        isErrorLeaveHistory: action.requestType,
        message: action.payload,
      };
    case USER_STATE.REQUEST_PERSONAL_LEAVE:
      return {
        ...state,
        isLoadingUserPersonalLeave: true,
        isErrorUserPersonalLeave: null,
        isSuccessUserPersonalLeave: null,
        message: null,
      };
    case USER_STATE.REQUEST_PERSONAL_LEAVE_FAILED:
      return {
        ...state,
        isLoadingUserPersonalLeave: false,
        isErrorUserPersonalLeave: action.requestType,
        message: action.payload,
      };
    case USER_STATE.FETCH_USER_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.requestType,
        userData: action.payload.data,
        userMeta: action.payload.meta,
        trigger: action.type,
      };
    case USER_STATE.FETCH_SINGLE_MANAGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        oneManagerData: action.payload.data,
        trigger: action.type,
      };
    case USER_STATE.FETCH_PROFILE_SUCCCESS:
      return {
        ...state,
        isLoadingProfile: false,
        isSuccessProfile: action.requestType,
        profileData: action.payload.data,
        triggerProfile: USER_STATE.FETCH_PROFILE_SUCCCESS,
      };
    case USER_STATE.FETCH_USER_BY_SHIFT:
      return {
        ...state,
        isLoadingShift: true,
        isError: null,
        isSuccess: null,
        message: null,
      };
    case USER_STATE.FETCH_USER_BY_SHIFT_SUCCESS:
      return {
        ...state,
        isLoadingShift: false,
        isSuccess: action.requestType,
        userDataByShift: action.payload.data,
        userMeta: action.payload.meta,
      };
    case USER_STATE.FETCH_USER_BY_SHIFT_FAILED:
      return {
        ...state,
        isLoadingShift: false,
        isError: action.requestType,
        message: action.payload,
      };
    case USER_STATE.FETCH_MANAGER:
      return {
        ...state,
        isLoadingManager: true,
        managerData: [],
      };
    case USER_STATE.FETCH_MANAGER_SUCCCESS:
      return {
        ...state,
        isLoadingManager: false,
        isSuccess: action.requestType,
        managerData: action.payload,
      };
    case USER_STATE.FETCH_ROLE:
      return {
        ...state,
        roleLoading: true,
      };
    case USER_STATE.FETCH_ROLE_SUCCCESS:
      return {
        ...state,
        roleLoading: false,
        roleData: action.payload.data,
        message: action.payload,
      };
    case USER_STATE.FETCH_JOB:
      return {
        ...state,
        jobLoading: true,
      };
    case USER_STATE.FETCH_JOB_SUCCCESS:
      return {
        ...state,
        jobLoading: false,
        jobData: action.payload.data,
      };
    case USER_STATE.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.CREATE_SUCCESS,
      };
    case USER_STATE.CREATE_FAILED:
      const validation = {};
      for (let i = 0; i < action.message.length; i++) validation[action.message[i].field] = action.message[i].value;
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: validation,
        trigger: USER_STATE.CREATE_FAILED,
      };
    case USER_STATE.DROP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.DROP_SUCCESS,
      };
    case USER_STATE.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_SUCCESS,
      };
    case USER_STATE.UPDATE_FAILED:
      return {
        ...state,
        isLoadingLeave: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_FAILED,
      };
    case USER_STATE.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_EMPLOYEE_SUCCESS,
      };
    case USER_STATE.UPDATE_EMPLOYEE_FAILED:
      return {
        ...state,
        isLoadingLeave: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_EMPLOYEE_FAILED,
      };
    case USER_STATE.CHECK_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        attendanceTypes: action.payload,
        trigger: USER_STATE.CHECK_STATUS,
        menus: action.payload,
      };
    case USER_STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    case USER_STATE.CLEAR_LOADER_STATE:
      return {
        ...state,
        isLoading: false,
        isLoadingShift: false,
        isError: null,
        isSuccess: null,
        message: null,
        trigger: '',
      };
    case USER_STATE.SYNC_ADVANTECH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.SYNC_ADVANTECH_SUCCESS,
      };
    case USER_STATE.SYNC_ADVANTECH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.SYNC_ADVANTECH_FAILED,
      };
    case USER_STATE.CANCEL_SYNC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.CANCEL_SYNC_SUCCESS,
      };
    case USER_STATE.CANCEL_SYNC_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.CANCEL_SYNC_FAILED,
      };
    case USER_STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case USER_STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case USER_STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case USER_STATE.DOWNLOAD_TEMPLATE:
      return {
        ...state,
        downloadLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case USER_STATE.DOWNLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
        isError: false,
        isSuccess: true,
        downloadData: action.payload,
        trigger: action.type,
      };
    case USER_STATE.DOWNLOAD_TEMPLATE_FAILED:
      return {
        ...state,
        downloadLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case USER_STATE.IMPORT_SUCCESS:
      return {
        ...state,
        importLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.IMPORT_SUCCESS,
      };
    case USER_STATE.IMPORT_FAILED:
      return {
        ...state,
        importLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.IMPORT_FAILED,
      };
      case USER_STATE.CANCEL_IMPORT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: true,
          message: action.payload,
          trigger: USER_STATE.CANCEL_IMPORT_SUCCESS,
        };
      case USER_STATE.CANCEL_IMPORT_FAILED:
        return {
          ...state,
          isLoading: false,
          isError: true,
          isSuccess: false,
          message: action.payload,
          trigger: USER_STATE.CANCEL_IMPORT_FAILED,
        };
    case USER_STATE.READ_NOTIF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload.message,
        trigger: USER_STATE.READ_NOTIF_SUCCESS,
      };
    case USER_STATE.READ_NOTIF_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.READ_NOTIF_FAILED,
      };

    case USER_STATE.GET_BANK_SUCCESS:
      return {
        ...state,
        isLoadingBank: false,
        isSuccessBank: true,
        bankData: action.payload.data,
        triggerBank: USER_STATE.GET_BANK_SUCCESS,
      };

    case USER_STATE.GET_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isErrorGet: false,
        isSuccessGet: true,
        triggerGet: USER_STATE.GET_DETAIL_SUCCESS,
        employeeDetailData: action.payload.data,
      };
      case USER_STATE.GET_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isErrorGet: true,
        isSuccessGet: false,
        messageGet: action.payload,
        triggerGet: USER_STATE.GET_DETAIL_FAILED,
      };
    case USER_STATE.UPDATE_PERSONAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_PERSONAL_SUCCESS,
      };
    case USER_STATE.UPDATE_PERSONAL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_PERSONAL_FAILED,
      };
    case USER_STATE.UPDATE_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_EMPLOYMENT_SUCCESS,
      };
    case USER_STATE.UPDATE_EMPLOYMENT_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_EMPLOYMENT_FAILED,
      };
    case USER_STATE.UPDATE_REMOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_REMOTE_SUCCESS,
      };
    case USER_STATE.UPDATE_REMOTE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_REMOTE_FAILED,
      };
    case USER_STATE.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_ACCOUNT_SUCCESS,
      };
    case USER_STATE.UPDATE_ACCOUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_ACCOUNT_FAILED,
      };
    case USER_STATE.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.requestType,
        contractData: action.payload.data,
        contractMeta: action.payload.meta,
      };
    case USER_STATE.ADD_CONTRACT_FAILED:
      const contractValidation = {};
      for (let i = 0; i < action.message.length; i++) validation[action.message[i].field] = action.message[i].value;
      return {
        ...USER_STATE,
        isLoadingContract: false,
        isError: true,
        message: contractValidation,
        trigger: USER_STATE.ADD_CONTRACT_FAILED,
      };
    case USER_STATE.ADD_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoadingContract: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.ADD_CONTRACT_SUCCESS,
      };
    case USER_STATE.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoadingContract: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_CONTRACT_SUCCESS,
      };
    case USER_STATE.UPDATE_CONTRACT_FAILED:
      return {
        ...state,
        isLoadingContract: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_CONTRACT_FAILED,
      };
    case USER_STATE.UPDATE_CONTRACT_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingContract: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_CONTRACT_STATUS_SUCCESS,
      };
    case USER_STATE.UPDATE_CONTRACT_STATUS_FAILED:
      return {
        ...state,
        isLoadingContract: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_CONTRACT_STATUS_FAILED,
      };
    case USER_STATE.UPDATE_EMPLOYEE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_EMPLOYEE_STATUS_SUCCESS,
      };
    case USER_STATE.UPDATE_EMPLOYEE_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_EMPLOYEE_STATUS_FAILED,
      };
    case USER_STATE.DELETE_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case USER_STATE.DELETE_CONTRACT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case USER_STATE.UPDATE_DEDUCTED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: USER_STATE.UPDATE_DEDUCTED_SUCCESS,
      };
    case USER_STATE.UPDATE_DEDUCTED_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: USER_STATE.UPDATE_DEDUCTED_FAILED,
      };
    case USER_STATE.UPDATE_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingUpdateHistory: false,
        updateHistoryData: action.payload.data,
        updateHistoryMeta: action.payload.meta,
      };
    case USER_STATE.LEAVE_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingLeaveHistory: false,
        leaveHistoryData: action.payload.data,
        leaveHistoryMeta: action.payload.meta,
      };
      case USER_STATE.USER_PERSONAL_LEAVE_SUCCESS:
      return {
        ...state,
        isLoadingUserPersonalLeave: false,
        isSuccessUserPersonalLeave: true,
        userPersonalLeaveData: action.payload.data,
        userPersonalLeaveMeta: action.payload.meta,
        triggerUserPersonalLeave: action.type,
      };
      case USER_STATE.REQUEST_ADJUSTMENT_LEAVE:
      return {
        ...state,
        isLoadingAddAdjustment: true,
        isErrorAddAdjustment: null,
        isSuccessAddAdjustment: null,
        triggerAddAdjustment: action.requestType,
      };
    case USER_STATE.REQUEST_ADJUSTMENT_LEAVE_FAILED:
      return {
        ...state,
        isLoadingAddAdjustment: false,
        isErrorAddAdjustment: true,
        messageAddAdjustment: action.payload,
        triggerAddAdjustment: action.requestType,
      };
      case USER_STATE.CREATE_ADJUSTMENT_LEAVE_FAILED:
        const validationAdjustmentLeave = {};
        for (let i = 0; i < action.message.length; i++) validation[action.message[i].field] = action.message[i].value;
        return {
          ...state,
          isLoadingAddAdjustment: false,
          isErrorAddAdjustment: true,
          messageAddAdjustment: validationAdjustmentLeave,
          triggerAddAdjustment: USER_STATE.CREATE_ADJUSTMENT_LEAVE_FAILED,
        };
      case USER_STATE.CREATE_ADJUSTMENT_LEAVE_SUCCESS:
        return {
          ...state,
          isLoadingAddAdjustment: false,
          isSuccessAddAdjustment: true,
          messageAddAdjustment: action.payload,
          triggerAddAdjustment: USER_STATE.CREATE_ADJUSTMENT_LEAVE_SUCCESS,
        };
      case USER_STATE.REQUEST_EMPLOYEE_TYPE:
        return {
          ...state,
          isLoadingEmployeeType: true,
          isSuccessEmployeeType: false,
          isErrorEmployeeType: false,
          triggerEmployeeType: USER_STATE.REQUEST_EMPLOYEE_TYPE,
        };
      case USER_STATE.REQUEST_EMPLOYEE_TYPE_SUCCESS:
        return {
          ...state,
          isLoadingEmployeeType: false,
          isSuccessEmployeeType: true,
          employeeTypeList: action.payload,
          triggerEmployeeType: USER_STATE.REQUEST_EMPLOYEE_TYPE_SUCCESS,
        };
      case USER_STATE.REQUEST_EMPLOYEE_TYPE_FAILED:
        return {
          ...state,
          isLoadingEmployeeType: false,
          isSuccessEmployeeType: false,
          isErrorEmployeeType: true,
          triggerEmployeeType: USER_STATE.REQUEST_EMPLOYEE_TYPE_FAILED,
        };
    default:
      return state;
  }
};
