/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2';
import moment from 'moment';
import crypto from 'crypto';
import Resizer from 'react-image-file-resizer';
import './style.css';
import {connectFirestoreEmulator} from 'firebase/firestore';
import questionIcon from './question.svg';

// RESPONSE API PARSING
export const formatCurrency = (value) => {
  const reverse = value.toString().split('').reverse().join('');
  let thousand = reverse.match(/.{1,3}/g);
  thousand= thousand.join('.').split('').reverse().join('');

  return thousand;
};


export const isObject = (data) => {
  if (data && Object.keys(data).length > 0 && data.constructor === Object) {
    return true;
  }
  return false;
};

export const thousandSeparator = (data) => {
  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');
  return addCommas(removeNonNumeric(data));
};

export const isArray = (data) => {
  if (data && Array.isArray(data) && data.length) return true;
  return false;
};

export const checkSuperadmin = (authState) => {
  if (!authState) return false;
  const isSuperAdmin =
    authState.data.profile && authState.data.profile.role === 'Super Admin' ?
      true :
      false;
  return isSuperAdmin;
};

export const transformObject = (array) => {
  let newObject = {};
  array.map((data) => {
    const name = data.field;
    newObject = {...newObject, [name]: data.value};
    return true;
  });
  return newObject;
};

export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// REGEX VALIDATION
export const validateRegex = (value, type = 'text', removeTrailing = true) => {
  let result;
  if (type === 'text') {
    result = value.replace(/[^\w.\-\_\s']/g, ''); // allow alphanumeric, hyphen, underscore, singlequote
  } else if (type === 'email') {
    result = value.replace(/[^\w.\-\_'\@]/g, ''); // allow alphanumeric, hyphen, underscore, singlequote and @
  } else if (type === 'number') {
    result = value.replace(/\D+/g, ''); // allow only number
  } else {
    result = value;
  }

  if (removeTrailing) {
    result = result.replace(/^\s+|\s+$/g, ''); // remove leading & trailing spaces
  }

  return result;
};

// DATE HELPER

export const convDate = (date, customFormat = 'YYYY-MM-DD') => {
  const convert = () => {
    return moment(date).format(customFormat);
  };

  if (date && date instanceof Date && !isNaN(date.valueOf())) {
    return convert();
  } else {
    const check = moment(date).isValid();
    return check ? convert() : null;
  }
};

export const getDay = (date, locales='id') => {
  const day = moment(date).format('d');
  switch (parseInt(day)) {
    case 1:
      return locales==='id' ? 'Senin' : 'Monday';
    case 2:
      return locales==='id' ? 'Selasa' : 'Tuesday';
    case 3:
      return locales==='id' ? 'Rabu' : 'Wednesday';
    case 4:
      return locales==='id' ? 'Kamis' : 'Thursday';
    case 5:
      return locales==='id' ? 'Jumat' : 'Friday';
    case 6:
      return locales==='id' ? 'Sabtu' : 'Saturday';
    default:
      return locales==='id' ? 'Minggu' : 'Sunday';
  }
};

export const getTimeDiff = (targetDate, currentDate = 'today') => {
  try {
    return moment(targetDate).diff(moment(), 'days');
  } catch (e) {
    return null;
  }
};

export const dateDiff = (startDate, endDate) => {
  function treatAsUTC(date) {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  }
  try {
    const millisecondsPerDay = (24 * 60 * 60 * 1000);
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
  } catch (e) {
    return null;
  }
};

export const remainingDays = (targetDate) => {
  const currentDate = new Date();
  const target = new Date(targetDate); // Replace with your desired exam date
  const timeDifference = Math.abs(target - currentDate);
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24) - 1 );
  return daysRemaining ? daysRemaining : null;
};

// IMAGE SIZE
export const checkFileUploadSize = (file) => {
  const maxSize = process.env.REACT_APP_MAX_UPLOAD_SIZE_MB * 1000000;
  if (file) {
    if (file.size > maxSize) return false; // false if size too large
    return true;
  }
  return null; // null if file not selected
};

export const checkMobile = () => {
  const userAgent = navigator.userAgent;
  return /Mobi/.test(userAgent);
};

// IMAGE RESIZE PACKAGE
export const resize = (file, maxWidth, maxHeight, callback) => {
  Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'PNG',
      100,
      0,
      callback,
      'blob',
  );
};

export const blobToFile = (blob, extension = 'png') => {
  return new File([blob], `upload.${extension}`, {
    type: blob.type ? blob.type : 'image/png',
  });
};

// IMAGE RESIZE VANILLA JS

export const resizeVanilla = (file, maxWidth, maxHeight, fn) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function(event) {
    const dataUrl = event.target.result;
    const image = new Image();
    image.src = dataUrl;
    image.onload = function() {
      const resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
      fn(resizedDataUrl);
    };
  };
};

export const resizeImage = (image, maxWidth, maxHeight, quality) => {
  const canvas = document.createElement('canvas');

  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (maxWidth !== null && maxWidth !== undefined && width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (maxHeight !== null && maxHeight !== undefined && height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg', quality);
};

// TOAST HELPER

export const toast = (type, message) => {
  switch (type) {
    case 'success':
      cogoToast.success(message, {
        position: 'bottom-center',
        hideAfter: 5,
      });
      break;
    case 'error':
      if (message !== 'Failed to authenticate token' && (message !== 'No token provided')) {
        cogoToast.error(message, {
          position: 'bottom-center',
          hideAfter: 5,
        });
      }
      break;
    case 'info':
      cogoToast.info(message, {
        position: 'bottom-center',
        hideAfter: 5,
      });
      break;
    default:
      break;
  }
};

export const decrypt = (text) => {
  const textParts = (text + '').split(':');
  // console.log(textParts.split(':'));
  console.log(text.split('&')[0]);
  // console.log(textParts.split(':'));
  const iv = new Buffer.from(textParts.shift(), 'hex');
  // console.log(iv);
  const encryptedText = new Buffer.from(text.split('&')[0], 'hex');
  // console.log(encryptedText);
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(process.env.REACT_APP_ENCRYPTION_KEY), iv);
  console.log(decipher);
  let decrypted = decipher.update(encryptedText);

  console.log(decrypted);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  console.log(decrypted);

  return decrypted.toString();
};

// POPUP HELPER

export const showPopup = (type, ...rest) => {
  switch (type) {
    case 'confirm':
      const [message, btnOKText, handleOK] = rest;
      Swal.fire({
        customClass: {
          container: 'my-swal',
        },
        title: 'Confirmation',
        text: message,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#00AFF0',
        cancelButtonColor: '#E5AF5A',
        confirmButtonText: btnOKText ? btnOKText : 'Yes',
        cancelButtonText: 'No',
        inputAttributes: {
          id: 'swal-btn',
        },
        onOpen: () => {
          $('.swal2-confirm').attr('id', 'btnConfirm');
          $('.swal2-cancel').attr('id', 'btnCancel');
        },
      }).then((result) => {
        if (result.value) {
          handleOK();
        }
      });
      break;
    case 'warning':
      const [warnMessage, warnBtnOKText, warnHandleOK] = rest;
      Swal.fire({
        customClass: {
          container: 'my-swal',
        },
        title: 'WARNING',
        text: warnMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00AFF0',
        cancelButtonColor: '#E5AF5A',
        confirmButtonText: warnBtnOKText ? warnBtnOKText : 'Yes',
        cancelButtonText: 'No',
        inputAttributes: {
          id: 'swal-btn',
        },
        onOpen: () => {
          $('.swal2-confirm').attr('id', 'btnConfirm');
          $('.swal2-cancel').attr('id', 'btnCancel');
        },
      }).then((result) => {
        if (result.value) {
          warnHandleOK();
        }
      });
      break;
    case 'infohandle':
      const [handleMessage, handleBtnOKText, handleHandleOK] = rest;
      Swal.fire({
        customClass: {
          container: 'my-swal',
        },
        title: 'INFO',
        text: handleMessage,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#00AFF0',
        cancelButtonColor: '#E5AF5A',
        confirmButtonText: handleBtnOKText ? handleBtnOKText : 'Yes',
        cancelButtonText: 'No',
        inputAttributes: {
          id: 'swal-btn',
        },
        onOpen: () => {
          $('.swal2-confirm').attr('id', 'btnConfirm');
          $('.swal2-cancel').attr('id', 'btnCancel');
        },

      }).then((result) => {
        if (result.value) {
          handleHandleOK();
        }
      });
      break;
    case 'info':
      const [infoTitle, infoMessage] = rest;
      Swal.fire(infoTitle, infoMessage, 'info');
      break;
    case 'error':
      const [errorMessage] = rest;
      Swal.fire(
          'Oops...',
        errorMessage ?
          errorMessage :
          `Something is wrong, please try again later...`,
        'error',
      );
      break;
    default:
      return;
  }
};

export const convText = (text, type='camelcase') => {
  let result;
  if (text) {
    if (type==='uppercase') {
      result = text.toUpperCase();
    } else if (type==='lowercase') {
      result = text.toLowerCase();
    } else {
      const countText = text.split(' ');
      if (countText.length > 1) {
        countText.map((val, index) => {
          const first = val.substring(0, 1).toUpperCase();
          const second = val.substring(1);
          const word = first+second;
          const textResult = result ? result : '';
          result = `${textResult + word} `;
        });
      } else {
        result = text.substring(0, 1).toUpperCase() + text.substring(1);
      }
    }
  }

  return result;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const impersonateHrMode = (userId) => {
  if (userId?.toString() === process.env.REACT_APP_USER_ID_ASSIGN) {
    return true;
  } else {
    return false;
  }
};

export const isValidURL = (str = '') => {
  try {
    return Boolean(new URL(str));
  } catch {
    return false;
  }
};

export const getImageUrl = (url) => {
  if (!url) return '';
  return url?.search('http') === 0 || url?.search('https') === 0 ? url : `https://${url}`;
};

export const formatCurrency2 = (number) => {
  return new Intl.NumberFormat('id-ID',
    {
      style: 'currency',
      currency: 'IDR',
      maximumFractionDigits: 0,
    },
  ).format(number);
};
