/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {
  Paper,
  CircularProgress,
  Link,
} from '@material-ui/core';
import {Refresh} from '@material-ui/icons';
import {MButton} from '../../components/form';
import {REGISTER_STATE as STATE} from '../../redux/register/register.types';
import {toast, isObject, checkSuperadmin, checkMobile, resetLoader} from '../../helpers';
import {submitApprovalOrg} from '../../redux/register/register.actions';
import WebTemplate from '../../components/template';

class ActivationApprovalOrganization extends React.Component {
  constructor() {
    super();
    this.state = {
      status: null,
      message: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isError, message, registerTrigger, registerMessage} = nextProps;

    if (registerTrigger === STATE.APPROVAL_SUCCESS && this.props.registerMessage !== registerMessage) {
      this.setState({status: 'success', message: registerMessage});
    }

    if (registerTrigger === STATE.APPROVAL_FAILED && this.props.registerMessage !== registerMessage) {
      if (!isObject(registerMessage)) {
        this.setState({status: 'error', message: registerMessage});
      }
      if (message) {
        toast('error', message);
      }
      // this.props.history.replace('/419');
      // setTimeout(() => {
      //   this.props.history.replace('/');
      // }, 2000);
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentDidMount() {
    this.checkApproval();
  }

  checkApproval = () => {
    const location = this.props.location.pathname;
    const segment = location.substring(location.lastIndexOf('/') + 1);
    const id_organization_invite = segment !== 'activation-approval-organization' ? segment : null;
    if (id_organization_invite) {
      this.props.submitApprovalOrg({
        ...(id_organization_invite && {id_organization_invite: parseInt(id_organization_invite)}),
      });
    } else {
      this.props.history.push('/dashboard');
    }
  }

  handleReset = () => {
    this.setState({
      status: null,
      message: null,
    });
  }

  renderLoading = () => {
    const {classes} = this.props;
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} color="inherit" size={24} />
        <div className={classes.loaderText}>{'Processing...'}</div>
      </div>
    );
  }

  renderResponse = () => {
    const {message} = this.state;
    const {classes} = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.message}>{message}</div>
        <MButton
          className={classes.btnBack}
          label="Go Back"
          icon={<Refresh/>}
          onClick={() => this.props.history.push('/company-management')}
        />
      </div>
    );
  }

  handleBackToNative = (e) => {
    const payload = {type: 'backToNative'};
    const json = (JSON.stringify(payload));
    window.postMessage(json, '*');
  }

  handleBackToWeb = (e) => {
    e.preventDefault();
    const baseURL = window.location.origin;
    window.location.href = baseURL + '/login';
  }

  renderThankYou = () => {
    const {classes} = this.props;
    const isMobile = checkMobile();
    return (
      <div className={classes.center}>
        <h5 className={`${classes.formTitle} ${classes.center}`}>Successfully approved request!</h5>
        <h7 className={classes.center}>
          Now your account can be viewed by Superadmin HR.
        </h7>
        <div className={classes.btnThankYouContainer}>
          <Link
            href={'/finish-register'}
            className={classes.registerBtn}
            onClick={isMobile ? this.handleBackToNative : this.handleBackToWeb}
          >
            Back to Login
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const {classes, isSuccess} = this.props;
    return (
      <WebTemplate
        header
        footer
      >
        <div className={`${classes.root} ${classes.adjustHeight}`}>
          <div className={classes.paper}>
            {
              this.renderThankYou()
            }
          </div>
        </div>
      </WebTemplate>
    );
  }
}

const stateToProps = ({auth, register}) => ({
  isSuperAdmin: checkSuperadmin(auth),
  registerTrigger: register.trigger,
  registerMessage: register.message,
});

const dispatchToProps = (dispatch) => ({
  submitApprovalOrg: (params) => dispatch(submitApprovalOrg(params)),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(ActivationApprovalOrganization));
