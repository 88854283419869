/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {USER} from './../../services';
import {USER_STATE as STATE} from './userManagement.types';
import {transformObject, isArray} from '../../helpers';

export const getAllUser = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_USER));
    USER.getUsers(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_USER_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_USER_FAILED, response.data.message));
            },
        );
  };
};
export const getProfile = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_PROFILE));
    USER.getProfile(params)
      .then(
          (response) => {
            dispatch(requestSuccess(STATE.FETCH_PROFILE_SUCCCESS, response.data));
          },
          (error) => {
            const response = error.response.data;
            dispatch(requestFailed(STATE.FETCH_PROFILE_FAILED, response.data.message));
          },
      );
  };
};

export const getAllUserAdmin = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_USER));
    USER.getUsersAdmin(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_USER_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_USER_FAILED, response.data.message));
            },
        );
  };
};

export const getNewUsers = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_USER));
    USER.getNewUsers(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_USER_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_USER_FAILED, response.data.message));
            },
        );
  };
};

export const getUserByShift = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_USER_BY_SHIFT, true));
    USER.getUsers(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_USER_BY_SHIFT_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_USER_BY_SHIFT_FAILED, response.data.message));
            },
        );
  };
};

export const getAllManager = (params) => {
  return (dispatch) => {
    dispatch(requestManager());
    USER.getManagers(params)
        .then(
            (response) => {
              // dispatch(requestSuccess(STATE.FETCH_MANAGER_SUCCCESS, response.data));
              dispatch(requestManagerSuccess(response.data.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_MANAGER_FAILED, response.data.message));
            },
        );
  };
};

export const getSingleManager = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_SINGLE_MANAGER, true));
    USER.getManagersId(id)
        .then(
            (response) => {
              console.log(response);
              dispatch(requestSuccess(STATE.FETCH_SINGLE_MANAGER_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_SINGLE_MANAGER_FAILED, response.data.message));
            },
        );
  };
};

export const getOneManager = (id) => {
  return async (dispatch) => {
   const manager = await USER.getManagersId(id);
        return manager.data.data;
  };
};

export const getAllRole = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ROLE, true));
    USER.getRoles(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ROLE_SUCCCESS, response.data));
            },
            (error) => {
              dispatch(requestFailed(STATE.FETCH_ROLE_FAILED, null));
            },
        );
  };
};

export const getAllJobList = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_JOB, true));
    USER.getJobLists(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_JOB_SUCCCESS, response.data));
            },
            () => {
              dispatch(requestFailed(STATE.FETCH_JOB_FAILED, null));
            },
        );
  };
};

export const postUser = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    USER.addUser(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.CREATE_FAILED, errors));
            },
        );
  };
};

export const postUserAdmin = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    USER.addUserAdmin(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.CREATE_FAILED, errors));
            },
        );
  };
};

export const postNewUser = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    USER.addNewUser(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.CREATE_FAILED, errors));
            },
        );
  };
};

export const dropUser = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    USER.dropUser(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const message = error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const updateUser = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    USER.updateUser(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.UPDATE_FAILED, errors));
            },
        );
  };
};

export const updateNewUser = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    USER.updateNewUser(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_EMPLOYEE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.UPDATE_EMPLOYEE_FAILED, response.data.message));
            },
        );
  };
};

export const updateEmployee = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_EMPLOYEE));
    USER.updateNewUser(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_EMPLOYEE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.UPDATE_EMPLOYEE_FAILED, response.data.message));
            },
        );
  };
};

export const updateUserAdmin = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    USER.updateUserAdmin(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.UPDATE_FAILED, response.data.message));
            },
        );
  };
};

export const checkStatus = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHECK_STATUS, true));
    USER.checkStatus(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHECK_STATUS_SUCCESS, response.data.data));
            },
            (error) => {
              dispatch(requestFailed(STATE.CHECK_STATUS_FAILED, null));
            },
        );
  };
};

export const syncAdvantech = () => {
  return (dispatch) => {
    dispatch(request(STATE.SYNC_ADVANTECH));
    USER.syncAdvantech()
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.SYNC_ADVANTECH_SUCCESS, response.data.data.message));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.SYNC_ADVANTECH_FAILED, response.data.message));
            },
        );
  };
};

export const cancelAdvSync = () => {
  return (dispatch) => {
    dispatch(request(STATE.CANCEL_SYNC));
    USER.cancelAdvSync()
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CANCEL_SYNC_SUCCESS, response.data.data.message));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.CANCEL_SYNC_FAILED, response.data.message));
            },
        );
  };
};

export const exportExcelUser = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    USER.exportExcelUser(params)
        .then(
            (response) => {
              // const file = response.data.data.file;
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
              // window.location.href = file; // alternative : window.open(file);
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

export const downloadExcelTemplate = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.DOWNLOAD_TEMPLATE, true));
    USER.downloadExcelTemplate(params)
        .then(
            (response) => {
              const file = response.data.data.file;
              dispatch(requestSuccess(STATE.DOWNLOAD_TEMPLATE_SUCCESS, response.data.data));
              window.location.href = file; // alternative : window.open(file);
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(downloadFailed(STATE.DOWNLOAD_TEMPLATE_FAILED, message));
            },
        );
  };
};


export const importExcelUser = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.IMPORT));
    USER.importExcelUser(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.IMPORT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.IMPORT_FAILED, response.data.message));
            },
        );
  };
};

export const readNotifUser = () => {
  return (dispatch) => {
    dispatch(request(STATE.READ_NOTIF));
    USER.readNotifUser()
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.READ_NOTIF_SUCCESS, response.data.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.READ_NOTIF_SUCCESS, response.data.message));
            },
        );
  };
};

export const cancelImportUser = () => {
  return (dispatch) => {
    dispatch(request(STATE.CANCEL_IMPORT));
    USER.cancelImportUser()
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CANCEL_IMPORT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.CANCEL_IMPORT_FAILED, response.data.message));
            },
        );
  };
};

export const getBankList = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_BANK));
    USER.getBankList(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_BANK_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.GET_BANK_FAILED, response.data.message));
            },
        );
  };
};

export const getEmployeeDetail = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_DETAIL));
    USER.getEmployeeDetail(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_DETAIL_SUCCESS, response.data));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailedGet(STATE.GET_DETAIL_FAILED, errors));
            },
        );
  };
};

export const updatePersonalData = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_PERSONAL));
    USER.updatePersonalData(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_PERSONAL_SUCCESS, response.data.data.message));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.UPDATE_PERSONAL_FAILED, errors));
            },
        );
  };
};

export const updateEmploymentData = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_EMPLOYMENT));
    USER.updateEmploymentData(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_EMPLOYMENT_SUCCESS, response.data.data.message));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.UPDATE_EMPLOYMENT_FAILED, errors));
            },
        );
  };
};

export const updateRemoteData = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_REMOTE));
    USER.updateRemoteData(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_REMOTE_SUCCESS, response.data.data.message));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.UPDATE_REMOTE_FAILED, errors));
            },
        );
  };
};

export const updateAccountData = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_ACCOUNT));
    USER.updateAccountData(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_ACCOUNT_SUCCESS, response.data.data.message));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestFailed(STATE.UPDATE_ACCOUNT_FAILED, errors));
            },
        );
  };
};

export const getContractUser = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_CONTRACT));
    USER.getContractUser(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_CONTRACT_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.GET_CONTRACT_FAILED, response.data.message));
            },
        );
  };
};

export const addContractUser = (params) => {
  return (dispatch) => {
    dispatch(requestContract(STATE.ADD_CONTRACT));
    USER.addContractUser(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ADD_CONTRACT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.ADD_CONTRACT_FAILED, message));
            },
        );
  };
};

export const updateContractUser = (id, params) => {
  return (dispatch) => {
    dispatch(requestContract(STATE.UPDATE_CONTRACT));
    USER.updateContractUser(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_CONTRACT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_CONTRACT_FAILED, message));
            },
        );
  };
};

export const updateContractStatus = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_CONTRACT_STATUS));
    USER.updateContractStatus(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_CONTRACT_STATUS_SUCCESS, response.data.data.message));
            },
            (error) => {
              const message = error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_CONTRACT_STATUS_FAILED, message));
            },
        );
  };
};

export const updateEmployeeStatus = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_EMPLOYEE_STATUS));
    USER.updateEmployeeStatus(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_EMPLOYEE_STATUS_SUCCESS, response.data.data.message));
            },
            (error) => {
              const message = error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_EMPLOYEE_STATUS_FAILED, message));
            },
        );
  };
};

export const updateDeductedLeave = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_DEDUCTED));
    USER.updateDeductedLeave(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_DEDUCTED_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_DEDUCTED_FAILED, message));
            },
        );
  };
};

export const deleteContractUser = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DELETE_CONTRACT));
    USER.deleteContractUser(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DELETE_CONTRACT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const message = error.response.data.data.message;
              dispatch(requestFailed(STATE.DELETE_CONTRACT_FAILED, message));
            },
        );
  };
};

export const getUpdateHistory = (params, id) => {
  return (dispatch) => {
    dispatch(requestUpdateHistory(STATE.UPDATE_HISTORY));
    USER.getUpdateHistory(params, id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_HISTORY_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.UPDATE_HISTORY_FAILED, response.data.message));
            },
        );
  };
};

export const getLeaveHistory = (params, id) => {
  return (dispatch) => {
    dispatch(requestLeaveHistory(STATE.LEAVE_HISTORY));
    USER.getLeaveHistory(params, id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.LEAVE_HISTORY_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.LEAVE_HISTORY_FAILED, response.data.message));
            },
        );
  };
};

export const getUserPersonalLeave = (params, id) => {
  return (dispatch) => {
    dispatch(requestPersonalLeave(STATE.USER_PERSONAL_LEAVE));
    USER.getUserPersonalLeave(params, id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.USER_PERSONAL_LEAVE_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.USER_PERSONAL_LEAVE_FAILED, response.data.message));
            },
        );
  };
};


export const createUserJobTitle = (params) => {
  return (dispatch) => {
    dispatch(requestJob(STATE.CREATE_JOB));
    USER.createUserJobTitle(params)
        .then(
            (response) => {
              dispatch(requestJobSuccess(STATE.CREATE_JOB_SUCCESS, response.data.data));
            },
            (error) => {
              let errors = error.response.data.data.errors;
              if (Array.isArray(errors) && errors.length) errors = transformObject(errors);
              dispatch(requestJobFailed(STATE.CREATE_JOB_FAILED, errors));
            },
        );
  };
};

export const createAdjustmentLeave = (params) => {
  return (dispatch) => {
    dispatch(requestAdjustmentLeave(STATE.CREATE_ADJUSTMENT_LEAVE));
    USER.createAdjustmentLeave(params)
        .then(
            (response) => {
              dispatch(requestAdjustmentLeaveSuccess(STATE.CREATE_ADJUSTMENT_LEAVE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestAdjustmentLeaveFailed(STATE.CREATE_ADJUSTMENT_LEAVE_FAILED, message));
            },
        );
  };
};

export const getListEmployeeType = (params) => {
  return (dispatch) => {
    dispatch(requestEmployeeType(STATE.REQUEST_EMPLOYEE_TYPE));
    USER.getListEmployeeType(params)
        .then(
            (response) => {
              dispatch(requestEmployeeTypeSuccess(STATE.REQUEST_EMPLOYEE_TYPE_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestEmployeeTypeFailed(STATE.REQUEST_EMPLOYEE_TYPE_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

export const clearLoaderState = () => {
  return (dispatch) => dispatch(resetLoaderState());
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestUpdateHistory = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_UPDATE_HISTORY,
  requestType,
});

const requestLeaveHistory = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_LEAVE_HISTORY,
  requestType,
});

const requestPersonalLeave = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_PERSONAL_LEAVE,
  requestType,
});


const requestJob = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_JOB,
  requestType,
});

const requestJobSuccess = (requestType, response) => {
  return ({
  type: requestType,
  payload: response,
});
};

const requestJobFailed = (requestType, response) => ({
  type: STATE.REQUEST_JOB_FAILED,
  requestType,
  payload: response,
});

const requestContract = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_CONTRACT,
  requestType,
});

const requestLeave = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_LEAVE,
  requestType,
});

const requestBankSuccess = (requestType, response) => ({
  payload: response,
});

const requestBankFailed = (requestType, response) => ({
  payload: response,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestLeaveFailed = (requestType, response) => ({
  type: STATE.REQUEST_LEAVE_FAILED,
  requestType,
  payload: response,
});

const requestLeaveSuccess = (requestType, response) => {
  return ({
  type: requestType,
  payload: response,
});
};

const requestSuccess = (requestType, response) => {
  return ({
  type: requestType,
  payload: response,
});
};

const requestFailedGet = (requestType, response) => {
  return ({
  type: requestType,
  payload: response,
});
};

const requestAdjustmentLeave = (requestType) => ({
  type: STATE.REQUEST_ADJUSTMENT_LEAVE,
  requestType,
});

const requestAdjustmentLeaveFailed = (requestType, response) => ({
  type: STATE.REQUEST_ADJUSTMENT_LEAVE_FAILED,
  requestType,
  payload: response,
});

const requestAdjustmentLeaveSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const requestEmployeeType = (requestType) => ({
  type: STATE.REQUEST_EMPLOYEE_TYPE,
  requestType,
});

const requestEmployeeTypeFailed = (requestType, response) => ({
  type: STATE.REQUEST_EMPLOYEE_TYPE_FAILED,
  requestType,
  payload: response,
});

const requestEmployeeTypeSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

const resetLoaderState = () => ({
  type: STATE.CLEAR_LOADER_STATE,
});

const requestManager = () => ({
  type: STATE.FETCH_MANAGER,
});

const requestBank = () => ({
  type: STATE.GET_BANK,
});

const requestManagerSuccess = (data) => ({
  type: STATE.FETCH_MANAGER_SUCCCESS,
  payload: data,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const downloadFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

