/* eslint-disable no-mixed-operators */
/* eslint-disable no-dupe-keys */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, ThemeProvider} from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Link,
  CircularProgress,
  Dialog,
  Divider,
  Box,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {
  SentimentDissatisfied,
  AccessTime,
  NoMeetingRoom,
  ExpandMore,
  ExpandLess,
  RepeatRounded,
  KeyboardReturnRounded,
} from '@material-ui/icons';
import {checkPremium} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {USER_STATE} from '../../redux/userManagement/userManagement.types';
import {ORGANIZATION_STATE} from '../../redux/organization/organization.types';
import {getJobTitleByOrg} from '../../redux/jobTitle/jobTitle.actions';
import {
  getNewUsers,
  getAllUser,
  getAllManager,
  getAllRole,
  postUser,
  postNewUser,
  dropUser,
  updateUser,
  updateNewUser,
  clearState,
  getOneManager,
  syncAdvantech,
  cancelAdvSync,
  exportExcelUser,
  importExcelUser,
  cancelImportUser,
} from '../../redux/userManagement/userManagement.actions';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../redux/organization/organization.actions';
import svgEmployee from '../../assets/img/dashboard/employee.svg';
import svgTodayAtt from '../../assets/img/dashboard/todayAtt.svg';
import svgYesterdayAtt from '../../assets/img/dashboard/yesterdayAtt.svg';
import svgLastweekAtt from '../../assets/img/dashboard/lastWeekAtt.svg';
import svgLastmonthAtt from '../../assets/img/dashboard/lastMonthAtt.svg';
import svgTodayBus from '../../assets/img/dashboard/todayBus.svg';
import svgYesterdayBus from '../../assets/img/dashboard/yesterdayBus.svg';
import svgLastweekBus from '../../assets/img/dashboard/lastWeekBus.svg';
import svgLastmonthBus from '../../assets/img/dashboard/lastMonthBus.svg';
import svgInvitation from '../../assets/icon/dashboard/confetti.svg';
import confirmationIcon from '../../assets/icon/partner/confirm.svg';
import {checkSuperadmin, convDate, isObject, showPopup} from '../../helpers';
import styles from './styles';
import {toast} from '../../helpers';
import {getAll, getNoRecord} from '../../redux/dashboard/dashboard.actions';
import {Datatable} from '../../components';
import moment from 'moment';
import {getPaymentAnalytics} from '../../redux/confirmPayment/confirmPayment.actions';
import {Chart as ChartJS} from 'chart.js/auto';
import {Line, Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {getRegisterAnalytics} from '../../redux/register/register.actions';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {MButton, MInput} from '../../components/form';
import {getInvoicePrice} from '../../redux/invoice/invoice.actions';
import {rejectPartner, approvePartner, clearState as clearStatePartner} from '../../redux/partner/partner.actions';
import {PARTNER_STATE} from '../../redux/partner/partner.types';

const numberSeparator = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const yearPickerTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: 14,
      },
    },
    MuiFormControl: {
      root: {
        maxWidth: 68,
        mmarginLeft: 4,
      },
    },
  },
});

const paperTheme = createTheme({
  overrides: {
    MuiGrid: {
      container: {
        justifyContent: 'space-between',
      },
    },
  },
});

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      startDate: null,
      endDate: null,
      showNoRecord: false,
      dateNoRecord: null,
      filter: null,
      payYear: moment().format('YYYY'),
      regYear: moment().format('YYYY'),
      isExpanded: false,
      ignoreModalShow: false,
      notes: null,
      errorNotes: false,
      iframeUrl: null,
      companyPrice: [
        {
          plan: 1,
          actual_price: 399000,
          selling_price: 271200,
          valid_until: moment().format('MMMM YYYY'),
        },
        {
          plan: 3,
          actual_price: 1197000,
          selling_price: 957000,
          valid_until: moment().format('MMMM YYYY'),
        },
        {
          plan: 6,
          actual_price: 2394000,
          selling_price: 2154200,
          valid_until: moment().format('MMMM YYYY'),
        },
        {
          plan: 12,
          actual_price: 4788000,
          selling_price: 4309200,
          valid_until: moment().format('MMMM YYYY'),
        },
      ],
      companyData: [],
      terms: [
        {no: 1, term: 'Only valid for a certain period'},
        {no: 2, term: 'Valid for all types of payments'},
        {no: 3, term: 'If you accept the invitation, the discount listed will be claimed immediately.'},
        {no: 4, term: 'If you decline the invitation, this opportunity will be forfeited.'},
      ],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.checkStatus();
    this.props.checkStatusUser();
    // this.fetchUser();
    this.props.getAllRole();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {invoiceTrigger, invoiceIsSuccess, orgStatusData, triggerOrg, isSuccessOrg, price} = nextProps;
    const {triggerPartner, isSuccessPartner, isLoadingPartner, messagePartner, data, isError, isSuccessGeneralSetting} = nextProps;

    if (isSuccessPartner) {
      if (triggerPartner === PARTNER_STATE.REJECT_SUCCESS) {
        toast('success', 'Invitation Has Been Ignored');
        this.setState({
          ignoreModalShow: false,
        });
        this.props.checkStatus();
        this.props.checkStatusUser();
        this.props.history.push('/payment-history');
        this.props.clearState();
        this.props.clearStatePartner();
      }
      if (triggerPartner === PARTNER_STATE.APPROVE_SUCCESS) {
        toast('success', 'Invitation Has Been Approved');
        this.setState({
          ignoreModalShow: false,
        });
        this.props.checkStatus();
        this.props.checkStatusUser();
        this.props.history.push('/payment-history');
        this.props.clearState();
        this.props.clearStatePartner();
      }
    }

    if (isError && messagePartner) {
      if (!isObject(messagePartner)) toast('error', messagePartner);
    }


    if (isSuccessOrg) {
      if (triggerOrg === ORGANIZATION_STATE.CHECK_STATUS_SUCCESS) {
        this.setState({
          companyData: orgStatusData,
        });
      }
    }

    if (invoiceIsSuccess) {
      if (invoiceTrigger === 'INVOICE_FETCH_PRICE_SUCCESS') {
        this.setState({
          companyPrice: price.prices,
        });
      }
    }

    if (isSuccessGeneralSetting) {
      const iframeValue = data.find((item) => item.key === 'url_iframe');
      const urlIframe = iframeValue ? iframeValue.value : null;

      this.setState({
        iframeUrl: urlIframe,
      });
    }
  }


  componentWillUnmount() {
    this.props.clearState();
    this.props.clearStatePartner();
  }

  fetchUser = () => {
    const {isSuperAdmin} = this.props;
    const {page, limit, query, sortBy, order, type} = this.state;

    if (isSuperAdmin) {
      this.props.getNewUsers({
        page,
        limit,
        query,
        sort_by: sortBy,
        order,
        type: 'guest',
      });
    } else {
      // this.props.getAllUser({page, limit, query, sort_by: sortBy, order});
      this.props.getNewUsers({page, limit, query, sort_by: sortBy, order});
    }

    if (!isSuperAdmin) this.props.checkPremium();
  };

  componentDidMount() {
    const {isSuperAdmin} = this.props;

    this.props.getAll();
    if (!isSuperAdmin) this.props.getInvoicePrice();
    if (isSuperAdmin) this.fetchPaymentAnalytics();
    if (isSuperAdmin) this.fetchRegisterAnalytics();
    if (!isSuperAdmin) this.props.getJobTitleByOrg();
  }

  kFormatter = (num) => {
    return Math.abs(num) > 999 ?
      Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k' :
      Math.sign(num) * Math.abs(num);
  };

  handleComingSoon = () => {
    toast('info', 'Coming soon in next release');
  };

  handleOpenModalNoRecord = (type, filter) => {
    if (type === 'open') {
      const dateNow = moment().format('DD-MM-Y');
      const yesterday = moment().subtract(1, 'days').format('DD-MM-Y');
      this.props.getNoRecord({filter, page: 1, limit: 10, order: 'asc'});
      this.setState({
        showNoRecord: true,
        dateNoRecord: filter === 'today' ? dateNow : yesterday,
        filter: filter,
      });
    } else if (type === 'close') {
      this.setState({showNoRecord: false});
    }
  };

  fetchPaymentAnalytics = () => {
    const {payYear} = this.state;

    this.props.getPaymentAnalytics({
      ...(payYear && {year: payYear}),
    });
  };

  handlePayYearChange = (params) => {
    this.setState(
        {
          payYear: moment(params).format('YYYY'),
        },
        () => this.fetchPaymentAnalytics(),
    );
  };

  fetchRegisterAnalytics = () => {
    const {regYear} = this.state;

    this.props.getRegisterAnalytics({
      ...(regYear && {year: regYear}),
    });
  };

  handleRegYearChange = (params) => {
    this.setState(
        {
          regYear: moment(params).format('YYYY'),
        },
        () => this.fetchRegisterAnalytics(),
    );
  };

  renderHR = () => {
    const {classes} = this.props;
    const elem = [
      {
        title: 'Lateness',
        count: '1.024',
        iconBackground: '#FF5722',
        contentBackground: '#F44336',
        icon: <AccessTime className={classes.icon} />,
      },
      {
        title: 'Illness',
        count: '256',
        iconBackground: '#2196F3',
        contentBackground: '#F44336',
        icon: <SentimentDissatisfied className={classes.icon} />,
      },
      {
        title: 'Absence',
        count: '128',
        iconBackground: '#F44336',
        contentBackground: '#F44336',
        icon: <NoMeetingRoom className={classes.icon} />,
      },
    ];

    const items = elem.map((value, index, array) => {
      const count = 12 / array.length;
      return (
        <Grid key={index.toString()} item xs={count}>
          <Paper variant="outlined" className={classes.itemPaper}>
            <div
              className={classes.iconContainer}
              style={{backgroundColor: value.iconBackground}}
            >
              {value.icon}
            </div>
            <div className={classes.content}>
              <div className={classes.contentTitle}>{value.title}</div>
              <div className={classes.contentNumber}>{value.count}</div>
            </div>
          </Paper>
        </Grid>
      );
    });

    return (
      <Grid container spacing={3}>
        {items}
      </Grid>
    );
  };

  renderLoader = (size = 15) => {
    const {classes} = this.props;
    return <CircularProgress className={classes.loaderColor} size={size} />;
  };

  renderSmallCards = () => {
    const {classes, isLoading, totalEmployees} = this.props;
    const contents = [
      {
        logo: svgEmployee,
        title: 'Total Active Employee',
        total: totalEmployees,
      },
    ];

    const items = contents.map((value, index) => {
      return (
        <Grid item key={index.toString()} lg={3} xs={6}>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container wrap="nowrap" className={classes.iconPaddingSingle}>
              <Grid item xs={3}>
                <img src={value.logo} alt="img" style={{width: '100%'}} />
              </Grid>
              <Grid item xs={12} className={classes.marginLeftEmp}>
                {isLoading ? (
                  this.renderLoader(20)
                ) : (
                  <Typography className={classes.resultFontEmp}>
                    {value.total}
                  </Typography>
                )}
                <Typography className={classes.titleEmp}>
                  {value.title}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    });

    return (
      <Grid container wrap="nowrap" spacing={2}>
        {items}
      </Grid>
    );
  };

  handleReload = (params, filter) => {
    this.props.getNoRecord({
      filter: filter,
      page: params.page,
      limit: params.limit,
      order: 'asc',
    });
  };

  renderAttendanceCards = () => {
    const {classes, isLoading, today, yesterday, lastWeek, lastMonth, dashboardTime} =
      this.props;
    const cards = [
      {
        key: 'today',
        logo: svgTodayAtt,
        title: 'Today',
        remoteCompleted: today.remoteCheckIn,
        officeCompleted: today.officeCheckIn,
        noRecord: today.noRecord,
        permit: today.permit,
        paidleave: today.paidleave,
      },
      {
        key: 'yesterday',
        logo: svgYesterdayAtt,
        title: 'Yesterday',
        remoteCompleted: yesterday.remoteCompleted,
        remoteNoCheckOut: yesterday.remoteNoCheckOut,
        officeCompleted: yesterday.officeCompleted,
        officeNoCheckOut: yesterday.officeNoCheckOut,
        noRecord: yesterday.noRecord,
        permit: yesterday.permit,
        paidleave: yesterday.paidleave,
      },
      {
        key: 'lastWeek',
        logo: svgLastweekAtt,
        title: 'Last Week',
        remoteCompleted: lastWeek.remoteCompleted,
        remoteNoCheckOut: lastWeek.remoteNoCheckOut,
        officeCompleted: lastWeek.officeCompleted,
        officeNoCheckOut: lastWeek.officeNoCheckOut,
        noRecord: lastWeek.noRecord ? this.kFormatter(lastWeek.noRecord) : 0,
        permit: lastWeek.permit,
        paidleave: lastWeek.paidleave,
      },
      {
        key: 'lastMonth',
        logo: svgLastmonthAtt,
        title: 'Last Month',
        remoteCompleted: lastMonth.remoteCompleted,
        remoteNoCheckOut: lastMonth.remoteNoCheckOut,
        officeCompleted: lastMonth.officeCompleted,
        officeNoCheckOut: lastMonth.officeNoCheckOut,
        noRecord: lastMonth.noRecord ? this.kFormatter(lastMonth.noRecord) : 0,
        permit: lastMonth.permit,
        paidleave: lastMonth.paidleave,
      },
    ];

    const items = cards.map((value, index) => {
      const today = value.key === 'today' ? true : false;
      return (
        <Grid key={index.toString()} item xl={6} lg={6} md={6} xs={12}>
          <MuiThemeProvider theme={paperTheme}>
            <Paper variant="outlined" className={classes.paper}>
              <Grid
                container
                wrap="nowrap"
                className={classes.iconPaddingMulti}
              >
                <Grid item>
                  <img className={classes.icon} alt="img" src={value.logo} />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.parentFontAttend}>
                    {value.title}
                  </Typography>
                  <Typography
                    className={classes.childTitleFont}
                  >{`Attendance record for ${value.title}`}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} className={classes.marginLeft}>
                  <Typography
                    className={[classes.marginTop, classes.parentFont].join(
                        ' ',
                    )}
                  >
                    Remote Working
                  </Typography>
                  {value.title === 'Today' ? (
                    <Typography className={classes.childFont}>
                      Check-In
                    </Typography>
                  ) : (
                    <Typography className={classes.childFont}>
                      Completed
                    </Typography>
                  )}
                  <Grid container spacing={1}>
                    {isLoading ? (
                      this.renderLoader()
                    ) : (
                      <Link
                        className={classes.resultFontAtt}
                        href={`/attendance-history?range=${
                          value.key
                        }&method=${'remote'}${!today ? '&completed=true' : ''}`}
                      >
                        {value.remoteCompleted}
                      </Link>
                    )}
                  </Grid>
                </Grid>
                {value.remoteNoCheckOut !== undefined && (
                  <Grid item xs={1} className={classes.border}></Grid>
                )}
                {value.remoteNoCheckOut !== undefined ? (
                  <Grid item xs={4} className={classes.marginTopNoCheckout}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                    ></Typography>
                    <Typography className={classes.childFont}>
                      No Check-Out
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Link
                          className={classes.resultFontAtt}
                          href={`/attendance-history?range=${
                            value.key
                          }&method=${'remote'}&completed=${'false'}`}
                        >
                          {value.remoteNoCheckOut}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                ) : value.title === 'Today' ? (
                  <Grid item xs={4}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                    >Permit</Typography>
                    <Typography className={classes.childFont}>
                      Leave
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Link
                          className={classes.resultFontAtt}
                          href={`/leave-management?status=all&date=today`}
                        >
                          {value.paidleave ? value.paidleave : 0}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={5}
                    className={classes.marginTopNoCheckout}
                  ></Grid>
                )}
                <Grid item xs={4} className={classes.marginLeft}>
                  <Typography
                    className={[classes.marginTop, classes.parentFont].join(
                        ' ',
                    )}
                  >
                    Office Working
                  </Typography>
                  {value.title === 'Today' ? (
                    <Typography className={classes.childFont}>
                      Check-In
                    </Typography>
                  ) : (
                    <Typography className={classes.childFont}>
                      Completed
                    </Typography>
                  )}
                  <Grid container spacing={1}>
                    {isLoading ? (
                      this.renderLoader()
                    ) : (
                      <Link
                        className={classes.resultFontAtt}
                        href={`/attendance-history?range=${
                          value.key
                        }&method=${'office'}${!today ? '&completed=true' : ''}`}
                      >
                        {value.officeCompleted}
                      </Link>
                    )}
                  </Grid>
                </Grid>
                {value.officeNoCheckOut !== undefined && (
                  <Grid item xs={1} className={classes.border}></Grid>
                )}
                {value.officeNoCheckOut !== undefined ? (
                  <Grid item xs={4} className={classes.marginTopNoCheckout}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                    ></Typography>
                    <Typography className={classes.childFont}>
                      No Check-Out
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Link
                          className={classes.resultFontAtt}
                          href={`/attendance-history?range=${
                            value.key
                          }&method=${'office'}&completed=${'false'}`}
                        >
                          {value.officeNoCheckOut}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                ): value.title === 'Today' ? (
                  <Grid item xs={4}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                    ></Typography>
                    <Typography className={classes.childFont}>
                      Permit
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Link
                          className={classes.resultFontAtt}
                          href={`/permit?type=submission_date&status=approved&date=today`}
                        >
                          {value.permit}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={5}
                    className={classes.marginTopNoCheckout}
                  ></Grid>
                )}
              </Grid>
              {/* {value.key === 'lastWeek' || value.key === 'lastMonth' ? null : ( */}
              {value.title === 'Today' ? (
                <>
                  <Grid item xs={3} className={classes.marginLeft}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                      style={{marginBottom: 8}}
                    >
                      No Record
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Link
                          className={classes.resultFontAtt}
                          // href={`/attendance-history?range=${value.key}&method=${'noRecord'}`}
                          href={'#'}
                          onClick={() =>
                            this.handleOpenModalNoRecord('open', value.key)
                          }
                        >
                          {value.noRecord}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                  <div style={{padding: '12px 42px 12px 54px', opacity: 0}}>
                    <Divider style={{backgroundColor: '#DCDCDC'}}/>
                  </div>
                </>
              ) : (
                <>
                <div style={{padding: '12px 42px 12px 54px'}}>
                  <Divider style={{backgroundColor: '#DCDCDC'}}/>
                </div>
                <Grid container>
                  <Grid item xs={2} className={classes.marginLeft}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                    >
                      No Record
                    </Typography>
                    <Typography className={classes.childFont}>
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Typography
                          className={classes.resultFont}
                        >
                          {value.noRecord}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={2} className={classes.marginLeft}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                    >
                      Permit
                    </Typography>
                    <Typography className={classes.childFont}>
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Typography
                          className={classes.resultFont}
                        >
                          {value.permit}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={2} className={classes.marginLeft}>
                    <Typography
                      className={[classes.marginTop, classes.parentFont].join(
                          ' ',
                      )}
                    >
                      Leave
                    </Typography>
                    <Typography className={classes.childFont}>
                    </Typography>
                    <Grid container spacing={1}>
                      {isLoading ? (
                        this.renderLoader()
                      ) : (
                        <Typography
                          className={classes.resultFont}
                        >
                          {value.paidleave}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                </>
              )}
              {/* )} */}
            </Paper>
          </MuiThemeProvider>
        </Grid>
      );
    });


    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
          <Typography className={classes.fontTitle}>Attendances</Typography>
          <Typography className={classes.fontTitleUpdate}>Updated every {dashboardTime ? dashboardTime * 1 : 0} minutes</Typography>
        </div>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {items}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderBusinessTrip = () => {
    const {classes, isLoading, today, yesterday, lastWeek, lastMonth} =
      this.props;
    const cards = [
      {
        key: 'today',
        logo: svgTodayBus,
        title: 'Today',
        businessCheckIn: today.businessCheckIn ?
          this.kFormatter(today.businessCheckIn) :
          0,
      },
      {
        key: 'yesterday',
        logo: svgYesterdayBus,
        title: 'Yesterday',
        businessCheckIn: yesterday.businessCheckIn ?
          this.kFormatter(yesterday.businessCheckIn) :
          0,
      },
      {
        key: 'lastWeek',
        logo: svgLastweekBus,
        title: 'Last Week',
        businessCheckIn: lastWeek.businessCheckIn ?
          this.kFormatter(lastWeek.businessCheckIn) :
          0,
      },
      {
        key: 'lastMonth',
        logo: svgLastmonthBus,
        title: 'Last Month',
        businessCheckIn: lastMonth.businessCheckIn ?
          this.kFormatter(lastMonth.businessCheckIn) :
          0,
      },
    ];

    const items = cards.map((value, index) => {
      return (
        <Grid item key={`business-` + index} xl={3} lg={6} md={6} xs={6}>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container wrap="nowrap" className={classes.iconPaddingSingle}>
              <Grid item>
                <img
                  src={value.logo}
                  alt={value.title}
                  className={classes.icon}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.parentFont}>
                  {value.title}
                </Typography>
                <Typography className={classes.childTitleFont}>
                  Business trip record for {value.title}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.childFont}>
                      Completed
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.resultFontContainer}>
                    {isLoading ? (
                      this.renderLoader()
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Link
                          className={classes.resultFont}
                          href={`/attendance-history?range=${
                            value.key
                          }&method=${'business'}`}
                        >
                          {value.businessCheckIn}
                          <span className={classes.personFont}>Person</span>
                        </Link>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    });

    return (
      <div>
        <Typography className={classes.fontTitle}>Business Trip</Typography>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {items}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderModalNoRecord = () => {
    const {showNoRecord, classes, dateNoRecord, filter} = this.state;
    const {isLoadingNoRecord, dataNoRecord, metaNoRecord} = this.props;
    const columns = [
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'nik',
        label: 'NIK',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'email',
        label: 'Email',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];
    return (
      <Dialog
        open={showNoRecord}
        onClose={() => this.handleOpenModalNoRecord('close')}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
      >
        <Datatable
          creatable={false}
          title={`No Record Attendance ${dateNoRecord}`}
          loading={isLoadingNoRecord}
          dataSource={dataNoRecord}
          total={metaNoRecord.total}
          page={metaNoRecord.page}
          columns={columns}
          hideActions={true}
          handleReload={(params) => this.handleReload(params, filter)}
          // manualNumbering={true}
        />
      </Dialog>
    );
  };

  renderPaymentSummary = () => {
    const {classes, isLoading, payAnalytics} = this.props;

    const {payYear, iframeUrl} = this.state;

    const labels = payAnalytics && payAnalytics.map((val) => val.month_name);
    const datas = payAnalytics && payAnalytics.map((val) => val.total_amount);

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 100,
          top: 20,
        },
      },
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Payment Summary',
        },
        datalabels: {
          display: true,
          align: '-45',
          formatter: function(value) {
            value = value.toString();
            value = value.split(/(?=(?:...)*$)/);
            value = value.join('.');

            return value + ' IDR';
          },
          listeners: {
            click: function(context) {
              const baseURL = window.location.origin;
              const ind = context.dataIndex + 1;
              window.location.href =
                baseURL +
                '/payment-analytics?month=' +
                ind +
                '&year=' +
                payYear;
            },
            enter: function(context) {
              context.hovered = true;
              return true;
            },
            leave: function(context) {
              context.hovered = false;
              return true;
            },
          },
          color: function(context) {
            // Change the label text color based on our new `hovered` context value.
            return context.hovered ? '#3f51b5' : '#00AFF0';
          },
        },
      },
      onClick: (e, element) => {
        if (element.length > 0) {
          const baseURL = window.location.origin;
          const ind = element[0].index + 1;
          window.location.href =
            baseURL + '/payment-analytics?month=' + ind + '&year=' + payYear;
        }
      },
      scales: {
        y: {
          min: 0,
          display: true,
          ticks: {
            display: true,
            callback: function(value, index, ticks) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join('.');

              return value + ' IDR';
            },
          },
        },
      },
    };

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: datas,
          borderColor: '#E8505B',
          backgroundColor: '#E8505B',
        },
      ],
    };

    return (
      <div>
        <Typography className={classes.fontTitle}>Pagii Overview</Typography>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.paper}>
              <Grid
                container
                justify="center"
                wrap="nowrap"
                className={classes.iconPaddingMulti}
              >
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={yearPickerTheme}>
                      <DatePicker
                        inputVariant="outlined"
                        openTo="year"
                        views={['year']}
                        label="Year"
                        value={payYear}
                        autoOk={true}
                        onChange={(params) => this.handlePayYearChange(params)}
                        minDate={'2018'}
                        maxDate={moment().format('YYYY')}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    style={{fontWeight: 700, fontSize: 18, marginTop: 4}}
                  >
                    Payment Summary
                  </Typography>
                </Grid>
                <Grid item xs></Grid>
              </Grid>
              <Grid
                container
                wrap="nowrap"
                style={{minHeight: '30vh', height: '320px', width: '104%'}}
              >
                <Line
                  options={chartOptions}
                  data={chartData}
                  plugins={[ChartDataLabels]}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderRegisterSummary = () => {
    const {classes, isLoading, regAnalytics} = this.props;

    const {regYear} = this.state;

    const labels = regAnalytics && regAnalytics.map((val) => val.month_name);
    const datas = regAnalytics && regAnalytics.map((val) => val.total_register);

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 100,
          top: 20,
        },
      },
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Payment Summary',
        },
        datalabels: {
          display: true,
          align: '-45',
          listeners: {
            click: function(context) {
              const baseURL = window.location.origin;
              const ind = context.dataIndex + 1;
              window.location.href =
                baseURL +
                '/registration-analytics?month=' +
                ind +
                '&year=' +
                regYear;
            },
            enter: function(context) {
              context.hovered = true;
              return true;
            },
            leave: function(context) {
              context.hovered = false;
              return true;
            },
          },
          color: function(context) {
            // Change the label text color based on our new `hovered` context value.
            return context.hovered ? '#3f51b5' : '#00AFF0';
          },
        },
      },
      onClick: (e, element) => {
        if (element.length > 0) {
          const baseURL = window.location.origin;
          const ind = element[0].index + 1;
          window.location.href =
            baseURL +
            '/registration-analytics?month=' +
            ind +
            '&year=' +
            regYear;
        }
      },
      scales: {
        y: {
          min: 0,
          display: true,
          ticks: {
            display: true,
          },
        },
      },
    };

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Dataset 2',
          data: datas,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    return (
      <div>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.paper}>
              <Grid
                container
                justify="center"
                wrap="nowrap"
                className={classes.iconPaddingMulti}
              >
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={yearPickerTheme}>
                      <DatePicker
                        inputVariant="outlined"
                        openTo="year"
                        views={['year']}
                        label="Year"
                        value={regYear}
                        autoOk={true}
                        onChange={(params) => this.handleRegYearChange(params)}
                        minDate={'2018'}
                        maxDate={moment().format('YYYY')}
                      />
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    align="center"
                    style={{fontWeight: 700, fontSize: 18, marginTop: 4}}
                  >
                    Registration Summary
                  </Typography>
                </Grid>
                <Grid item xs></Grid>
              </Grid>
              <Grid
                container
                wrap="nowrap"
                style={{minHeight: '30vh', height: '320px', width: '104%'}}
              >
                <Line
                  options={chartOptions}
                  data={chartData}
                  plugins={[ChartDataLabels]}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderIframe = () => {
    const {classes} = this.props;
    const {iframeUrl} = this.state;

    return iframeUrl && (
      <>
        <iframe style={{border: 'none'}} title="iframe dashboard" width="100%" height="600" src={iframeUrl}></iframe>
      </>
    );
  };

  handleIgnoreInvite = () => {
    this.setState({ignoreModalShow: !this.state.ignoreModalShow});
  }

  handleReasonChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      notes: value,
      errorNotes: null,
    });
  }

  handleSubmitAbort = () => {
    const {notes, errorNotes} = this.state;
    const {companyData} = this.state;

    if (notes === null) {
      this.setState({errorNotes: true});
      return;
    } else {
      const params = {
        notes,
      };
      // console.log(this.props.profile);
      // console.log(params);
      this.props.rejectPartner(params);
    }
  }

  renderModalIgnore = () => {
    const {classes} = this.props;
    const {ignoreModalShow, notes, errorNotes} = this.state;

    return (
      <Dialog
        open={ignoreModalShow}
        onClose={() => this.setState({
          ignoreModalShow: !this.state.ignoreModalShow,
        })}
      >
        <form autoComplete="off" style={{width: 460}}>
          <DialogContent style={{paddingTop: '0 !important'}}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginTop: 8, marginBottom: 24}}>
                  <img src={confirmationIcon} alt="img" style={{width: '56px'}} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8}}>
                  <Typography style={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    marginTop: -16,
                    marginBottom: -10,
                  }}>Confirmation</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8, paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                  <Typography style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    textalign: 'center',
                  }}>{`Are you sure you want to ignore this invitation? You will miss benefit from the Pagii subscription plan.`}</Typography>
                </div>
              </Grid>
              <Grid item>
                <MInput
                  style={{width: 420}}
                  name="notes"
                  label="Reject Reason"
                  placeholder="Enter your reason"
                  // value={notes}
                  onChange={(event) => this.handleReasonChange(event)}
                  // onClick={() => this.setState({errorNotes: false})}
                  multiline={true}
                  rows={3}
                  // notched={true}
                  shrink={true}
                  error={
                    errorNotes ? 'Reason is required' : false
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            padding: '0px 20px 20px 20px',
            placeContent: 'center',
          }}>
            <MButton
              className={classes.primaryBtn}
              label="Yes"
              onClick={() => this.handleSubmitAbort()}
            />
            <MButton
              style={{marginRight: 8}}
              className={classes.secondaryBtn}
              label="No"
              onClick={() => this.handleIgnoreInvite()}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  renderPartnerBenefit = () => {
    const {classes} = this.props;
    const {discount, companyPrice} = this.state;

    return (
      <Paper variant='outlined' style={{boxShadow: 'none', padding: 16, borderRadius: 18}}>
        <div>
          <Typography style={{fontSize: 14, fontWeight: 600, color: '#656464', marginBottom: 16}}>
            Partner Benefits:
          </Typography>
        </div>
        <div>
          <Grid container spacing={2}>
            {companyPrice.map((data, index) => {
              return (
                <Grid key={index.toString()} item xs={3}>
                  <Paper variant='outlined' style={{padding: '20px 14px', borderRadius: 8, overflow: 'hidden'}}>
                    <div style={{display: 'grid', placeItems: 'center'}}>
                      <Typography style={{
                        marginBottom: 16, color: data.plan === 1 ?
                          '#3CA1C1' :
                          data.plan === 3 ? '#F07300' :
                            data.plan === 6 ? '#4EC13C' : '#EEAC03', fontWeight: 700, fontSize: 14,
                      }}>{data.plan} MONTH</Typography>
                    </div>
                    <div style={{display: 'grid', placeItems: 'center'}}>
                      <Typography style={{fontSize: 24, fontWeight: 700, color: '#656464'}}>
                        <Typography style={{fontSize: 10, fontWeight: 500, color: '#A0A0A0'}}>IDR</Typography>
                        {data.amount}
                      </Typography>
                      <div style={{display: 'flex', marginTop: 12, marginBottom: 8}}>
                        {data.discount_amount ? (
                          <Typography style={{fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through'}}>IDR {data.price}</Typography>
                        ) : (
                          <Typography style={{opacity: 0, fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through'}}>IDR {data.price}</Typography>
                        )}
                      </div>
                      {data.discount_amount ? (
                      <Typography style={{fontSize: 9, fontWeight: 400, color: '#A0A0A0'}}>*Valid Until: {moment(data.valid_until, 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 9, fontWeight: 400, color: '#A0A0A0'}}>*Valid Until: {moment(data.valid_until, 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      )}
                    </div>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div>
          <Typography className={classes.textNote}>Note: 11% tax excluded (PPN)</Typography>
        </div>
      </Paper>
    );
  };

  renderTNC = () => {
    const {terms} = this.state;

    return (
      <Paper variant='outlined' style={{padding: 16, backgroundColor: '#F3F5F9', boxShadow: 'none', borderRadius: 18, height: '100%'}}>
        <div>
          <Typography style={{fontSize: 14, fontWeight: 600, color: '#656464'}}>
            Terms & Conditions:
          </Typography>
          {terms.map((data, index) => {
            return (
              <Typography key={index.toString()} style={{fontSize: 14, fontWeight: 400, color: '#656464', marginTop: 8}}>{`${data.no}. ${data.term}`}</Typography>
            );
          })}
        </div>
      </Paper>
    );
  };

  handleAcceptInvite = () => {
    const {companyData} = this.state;

    showPopup(
        'confirm',
        'Are you sure you want to accept this invitation? You will get benefit from the Pagii subscription plan.',
        'Yes',
        () => {
          this.props.approvePartner();
        },
    );
  }


  renderInvitationCard = () => {
    const {classes} = this.props;
    const {isExpanded} = this.state;

    return (
      <Accordion expanded={isExpanded} variant='outlined' style={{marginBottom: 36, borderRadius: 18}}>
        <AccordionSummary
          // expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          // onClick={() => this.setState({isExpanded: !isExpanded})}
        >
          <div style={{width: '100%'}}>
            <Grid container spacing={2} justify='space-between' alignItems='center'>
              <Grid item>
                <div style={{display: 'flex'}}>
                  <img src={svgInvitation} alt="img" style={{width: '56px'}} />
                  <div style={{marginLeft: 18}}>
                    <Typography className={classes.invitationTitle}>You are invited as Pagii Special Member!</Typography>
                    <Typography className={classes.invitationTitle}>Get a special discount for your subscription plan!</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div style={{display: 'flex'}}>
                  <MButton
                    style={{marginRight: 8}}
                    className={classes.secondaryBtn}
                    label="Ignore"
                    onClick={() => this.handleIgnoreInvite()}
                  />
                  <MButton
                    className={classes.primaryBtn}
                    label="Accept"
                    onClick={() => this.handleAcceptInvite()}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} justify='center'>
              <Grid item>
                <MButton
                  style={{marginRight: 8}}
                  className={classes.expandBtn}
                  label={!isExpanded ? 'View More' : 'View Less'}
                  icon={!isExpanded ? <ExpandMore /> : <ExpandLess />}
                  onClick={() => this.setState({isExpanded: !isExpanded})}
                />
              </Grid>
            </Grid>
            {isExpanded ? <Divider /> : null}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{width: '100%', marginTop: -24}}>
            <Grid container spacing={2} justify='space-between' alignItems='center'>
              <Grid item>
                <div style={{display: 'flex'}}>
                  <img src={svgInvitation} alt="img" style={{width: '56px', opacity: 0}} />
                  <div style={{marginLeft: 18}}>
                    <Typography className={classes.invitationSubTitle}>
                    Hello, you have the opportunity to become Pagii&apos;s Special Member. What are the advantages of being our partner? You will get a special discount for the subscription plan you choose. Don&apos;t miss this opportunity!
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                {this.renderPartnerBenefit()}
              </Grid>
              <Grid item xs={4}>
                {this.renderTNC()}
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  render() {
    const {role} = this.props;
    const {companyData, iframeUrl} = this.state;
    return (role === 'HR Admin' || role === 'Super Admin HR') ? (
      <div>
        {companyData?.discount_status === 'inactive' ? (
          this.renderInvitationCard()
        ) : (null)}
        {this.renderSmallCards()}
        <br />
        {this.renderAttendanceCards()}
        <br />
        {this.renderBusinessTrip()}
        {this.renderModalNoRecord()}
        {this.renderModalIgnore()}

      </div>
    ) : role === 'Super Admin' && iframeUrl ? (
        this.renderIframe()
    ) : role === 'Super Admin' && !iframeUrl ? (
      <>
        {this.renderPaymentSummary()}
        <br />
        {this.renderRegisterSummary()}
      </>
    ) : (
      <div>
      ss
      </div>
    );
  }
}

const stateToProps = ({
  userManagement,
  auth,
  dashboard,
  upgradeAccount,
  confirmPayment,
  register,
  organization,
  generalSetting,
  invoice,
  partner,
}) => ({
  token: auth.data.authorization,
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: dashboard.isLoading,
  isError: dashboard.isError,
  isSuccess: dashboard.isSuccess,
  message: dashboard.message,
  trigger: dashboard.trigger,
  data: dashboard.data,
  totalEmployees: dashboard.totalEmployees,
  today: dashboard.today,
  yesterday: dashboard.yesterday,
  lastWeek: dashboard.lastWeek,
  lastMonth: dashboard.lastMonth,
  dashboardTime: dashboard.dashboardTime,
  role: auth.data.profile.role,
  profile: auth.data.profile,
  isLoadingNoRecord: dashboard.isLoadingNoRecord,
  isSuccessNoRecord: dashboard.isSuccessNoRecord,
  isFailureNoRecord: dashboard.isFailureNoRecord,
  dataNoRecord: dashboard.dataNoRecord,
  metaNoRecord: dashboard.metaNoRecord,
  payAnalytics: confirmPayment.analyticsData,
  regAnalytics: register.analyticsRegister,
  premium: upgradeAccount.premium,
  // userData: userManagement.userData,
  orgStatusData: organization.orgStatusData,
  isPremium: upgradeAccount.premium,
  data: generalSetting.data,
  isSuccessGeneralSetting: generalSetting.isSuccess,
  menusData: auth.data.menus,
  isSuccessOrg: organization.isSuccess,
  triggerOrg: organization.trigger,
  price: invoice.invoicePriceData,
  invoiceTrigger: invoice.trigger,
  invoiceIsSuccess: invoice.isSuccess,

  isLoadingPartner: partner.isLoading,
  triggerPartner: partner.trigger,
  isSuccessPartner: partner.isSuccess,
  messagePartner: partner.message,
  isError: partner.isError,
});

const dispatchToProps = (dispatch) => ({
  getInvoicePrice: () => dispatch(getInvoicePrice()),
  getAll: (params) => dispatch(getAll(params)),
  getNoRecord: (params) => dispatch(getNoRecord(params)),
  getPaymentAnalytics: (params) => dispatch(getPaymentAnalytics(params)),
  getRegisterAnalytics: (params) => dispatch(getRegisterAnalytics(params)),
  getAllUser: (params) => dispatch(getAllUser(params)),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  getAllUser: (params) => dispatch(getAllUser(params)),
  getNewUsers: (params) => dispatch(getNewUsers(params)),
  getAllManager: (params) => dispatch(getAllManager(params)),
  getAllRole: () => dispatch(getAllRole()),
  createUser: (data) => dispatch(postUser(data)),
  createNewUser: (data) => dispatch(postNewUser(data)),
  getOneManager: (id) => dispatch(getOneManager(id)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
  updateNewUser: (id, data) => dispatch(updateNewUser(id, data)),
  dropUser: (id) => dispatch(dropUser(id)),
  checkPremium: () => dispatch(checkPremium()),
  clearState: () => dispatch(clearState()),
  clearStatePartner: () => dispatch(clearStatePartner()),
  getJobTitleByOrg: (params) => dispatch(getJobTitleByOrg(params)),
  checkStatus: () => dispatch(checkStatus()),
  syncAdvantech: () => dispatch(syncAdvantech()),
  cancelAdvSync: () => dispatch(cancelAdvSync()),
  cancelImportUser: () => dispatch(cancelImportUser()),
  exportExcelUser: (params) => dispatch(exportExcelUser(params)),
  importExcelUser: (data) => dispatch(importExcelUser(data)),
  checkStatusUser: () => dispatch(checkStatusUser()),
  rejectPartner: (params) => dispatch(rejectPartner(params)),
  approvePartner: (params) => dispatch(approvePartner(params)),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(Dashboard));
