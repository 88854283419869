/* eslint-disable max-len */
import {initialState, EVENT_MANAGEMENT_STATE as STATE} from './eventManagement.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CREATE_EVENT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_EVENT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_EVENT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.REQUEST_CANCEL_EVENT:
      return {
        ...state,
        isLoadingRequestCancel: true,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.REQUEST_CANCEL_EVENT_SUCCESS:
      return {
        ...state,
        isLoadingRequestCancel: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.REQUEST_CANCEL_EVENT_FAILED:
      return {
        ...state,
        isLoadingRequestCancel: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CANCEL_APPROVAL_EVENT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CANCEL_APPROVAL_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CANCEL_APPROVAL_EVENT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.BLOCK_EVENT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.BLOCK_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.BLOCK_EVENT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_EVENTS:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.type,
      };
    case STATE.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        trigger: action.type,
        events: action.payload,
      };
    case STATE.FETCH_EVENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        trigger: action.type,
        message: action.payload,
      };
    case STATE.FETCH_EVENT_DETAIL:
      return {
        ...state,
        eventDetail: null,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.type,
      };
    case STATE.FETCH_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        trigger: action.type,
        eventDetail: action.payload,
      };
    case STATE.FETCH_EVENT_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        trigger: action.type,
        message: action.payload,
      };
    case STATE.FETCH_PARTICIPANTS:
      return {
        ...state,
        participants: [],
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.type,
      };
    case STATE.FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        trigger: action.type,
        participants: action.payload,
      };
    case STATE.FETCH_PARTICIPANTS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        trigger: action.type,
        message: action.payload,
      };
    case STATE.FETCH_EVENT_QR:
      return {
        ...state,
        eventQR: null,
        isLoading: true,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_EVENT_QR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        eventQR: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_EVENT_QR_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.GENERATE_EVENT_QR:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.GENERATE_EVENT_QR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        eventQR: state?.eventQR ? {
          ...state.eventQR,
          file: action.payload,
        } : {file: action.payload},
        trigger: action.type,
      };
    case STATE.GENERATE_EVENT_QR_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.TOGGLE_POPUP_EVENT_ANNOUNCEMENT:
      return {
        ...state,
        showPopupAnnouncement: action?.payload !== undefined && action?.payload !== null ? action.payload : !state?.showPopupAnnouncement,
        trigger: action.type,
      };
    case STATE.TOGGLE_POPUP_REQUEST_CANCEL_EVENT:
      return {
        ...state,
        showPopupRequestCancelEvent: action?.payload !== undefined && action?.payload !== null ? action.payload : !state?.showPopupRequestCancelEvent,
        trigger: action.type,
      };
    case STATE.TOGGLE_POPUP_CANCEL_APPROVAL_EVENT:
      return {
        ...state,
        showPopupCancelApproval: action?.payload !== undefined && action?.payload !== null ? action.payload : !state?.showPopupCancelApproval,
        trigger: action.type,
      };
    case STATE.TOGGLE_POPUP_BLOCK_EVENT:
      return {
        ...state,
        showPopupBlockEvent: action?.payload !== undefined && action?.payload !== null ? action.payload : !state?.showPopupBlockEvent,
        trigger: action.type,
      };
    case STATE.SEND_EVENT_ANNOUNCEMENT:
      return {
        ...state,
        isLoadingAnnouncement: true,
        isSuccess: false,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.SEND_EVENT_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoadingAnnouncement: false,
        showPopupAnnouncement: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.SEND_EVENT_ANNOUNCEMENT_FAILED:
      return {
        ...state,
        isLoadingAnnouncement: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_EVENT_VOUCHER:
      return {
        ...state,
        isLoadingUpdateVoucher: true,
        isSuccess: false,
        isError: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_EVENT_VOUCHER_SUCCESS:
      return {
        ...state,
        isLoadingUpdateVoucher: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_EVENT_VOUCHER_FAILED:
      return {
        ...state,
        isLoadingUpdateVoucher: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.EXPORT_PARTICIPANTS:
      return {
        ...state,
        trigger: action.type,
        isLoadingExportParticipant: true,
        isSuccess: false,
        isError: false,
      };
    case STATE.EXPORT_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        trigger: action.type,
        isLoadingExportParticipant: false,
        exportData: action.payload,
        isSuccess: true,
      };
    case STATE.EXPORT_PARTICIPANTS_FAILED:
      return {
        ...state,
        trigger: action.type,
        isLoadingExportParticipant: false,
        isError: true,
      };
    default:
      return state;
  }
};
