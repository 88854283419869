/* eslint-disable camelcase */
/* eslint-disable quotes */
/* eslint-disable object-curly-spacing */
// eslint-disable-next-line max-len
/* eslint-disable no-trailing-spaces */
import React, { Component } from "react";
import {
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { getExportActivity } from "../../services/exportActivity";
import { onMessageListener } from "../../config/firebase";
import PdfIcon from '../../assets/img/pdf-icon.svg';
import { updateImportNotificationStatus } from "../../redux/importActivity/importActivity.actions";
import { IMPORT_ACTIVITY_STATE as STATE } from "../../redux/importActivity/importActivity.types";
import { withRouter } from 'react-router-dom';
import { Download } from '@mui/icons-material';
import { isSupported } from "firebase/messaging";

class ImportFloatingCard extends Component {
  state = {
    imports: [],
    accordionExpanded: true,
    isSupported: true,
  };

  toggleAccordion = () => {
    this.setState({ accordionExpanded: !this.state.accordionExpanded });
  };

  handleFcmNotSupport = () => {
    this.setState({
      isSupported: false,
    });
  }

  UNSAFE_componentWillMount() {
    const { handleFcmNotSupport } = this;
    async function checkSupported() {
      const supported = await isSupported();
      if (!supported) {
        handleFcmNotSupport();
      }
    }

    checkSupported();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, importNotification} = nextProps;

    if (trigger === STATE.UPDATE_IMPORT_NOTIF_SUCCESS || trigger === STATE.UPDATE_IMPORT_NOTIF_STATUS) {
      this.setState({imports: importNotification});
    }

    onMessageListener()
      .then((payload) => {
        this.props.updateImportNotificationStatus(payload);
      })
      .catch((err) => console.log('failed: ', err));
  }

  handleDownloadFile = (file) => {
    window.location.href = file;
  }

  render() {
    const { classes, handleClose } = this.props;
    const { imports, isSupported } = this.state;

    return this.state.imports?.length > 0 && isSupported && (
      <div className={classes.cardWrapper}>
        <CardContent style={{ paddingBottom: '8px' }}>
          <Accordion expanded={this.state.accordionExpanded}>
            <AccordionSummary
              expandIcon={<ExpandLessIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={this.toggleAccordion}
            >
              <div className={classes.spaceBetween}>
                <Typography>
                  Import {imports.length} {imports.length > 1 ? 'items' : 'item'}
                </Typography>
                <IconButton aria-label="Close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <div style={{ width: "100%" }}>
                {imports.map((item, index) => (
                  <div
                    key={index}
                    className={classes.spaceBetween}
                  >
                    <p className={classes.fileName}>
                      <img alt="PDF Icon" src={PdfIcon} className={classes.icon} />
                      {item.fileName}
                    </p>
                    <p
                      className={
                        item.status === "waiting" ?
                          classes.waiting :
                          item.status === "success" ? 
                          classes.success :
                          classes.error
                      }
                    >
                      {/* {item.status === "waiting" ? "Waiting" : item.status} */}
                      {
                        // eslint-disable-next-line max-len
                        item.status === "waiting" ? "Waiting" : (item.status === "failed" ? item.body.split(',')[1] : item.status)
                      }
                    </p>
                    <p className={classes.fileName}>
                      {item.status === "waiting" ?
                        <CircularProgress size={16} /> : (
                          item?.type === "import" && item?.status === "success" ? null : (
                            <Download onClick={() => this.handleDownloadFile(item.file_path)} />
                          )
                        )
                      }
                    </p>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </div>
    );
  }
}

const mapStateToProps = ({importActivity}) => {
  return {
    isLoading: importActivity.isLoading,
    isError: importActivity.isError,
    isSuccess: importActivity.isSuccess,
    message: importActivity.message,
    trigger: importActivity.trigger,
    importNotification: importActivity.importNotificationData,
  };
};

const dispatchToProps = (dispatch) => ({
  getExportActivity: (params) => dispatch(getExportActivity(params)),
  updateImportNotificationStatus: (params) => dispatch(updateImportNotificationStatus(params)),
});

export default withRouter(connect(
  mapStateToProps,
  dispatchToProps,
)(withStyles(styles)(ImportFloatingCard)));
