import {ENDPOINTS, apiService} from '../config';

export const getAttendanceHistory = (params) => {
  return apiService.get(
      ENDPOINTS.ATTENDANCE.HISTORY,
      {params},
  );
};

export const getAttendanceDetail = (id) => {
  return apiService.get(
      ENDPOINTS.ATTENDANCE.DETAILS + id,
  );
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.ATTENDANCE.EXPORT,
      params,
  );
};

export const importExcel = (params) => {
  return apiService.post(
      ENDPOINTS.ATTENDANCE.IMPORT,
      params,
  );
};

export const getAttendanceNoRecord = (params) => {
  return apiService.get(
      ENDPOINTS.ATTENDANCE.NO_RECORD,
      {params},
  );
};

export const downloadExcelTemplate = (params) => {
  return apiService.get(
      ENDPOINTS.ATTENDANCE.DOWNLOAD_IMPORT_TEMPLATE,
      {params},
  );
};
