/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './styles';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  CardMedia,
  Link,
  Typography,
  Paper,
  Chip,
  IconButton,
} from '@material-ui/core';
import {
  Send,
  FilterListSharp,
  Refresh,
  Cancel,
  CheckCircle,
  Update,
  Search,
  InfoOutlined,
  Warning,
  AccountCircleOutlined,
} from '@material-ui/icons';
import {AdminDatatable, Datatable} from '../../components';
import {apiService} from '../../config';
import {MInput, MSelect, MButton, ModalTitle, MKeyBoardDatepicker} from '../../components/form';
import moment from 'moment';
import {UPGRADE_STATE as STATE_UPGRADE} from '../../redux/upgradeAccount/upgradeAccount.types';
import {PAYMENT_STATE as STATE} from '../../redux/confirmPayment/confirmPayment.types';
import {toast, showPopup, isObject, checkSuperadmin, convDate, getTimeDiff, checkFileUploadSize} from '../../helpers';
import {
  createBankPayment,
} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {
  getPayment,
  createPayment,
  updatePayment,
  deletePayment,
  getPaymentByOrg,
  clearState,
} from '../../redux/confirmPayment/confirmPayment.actions';
import UpgradeAccountModal from '../upgradeAccount';
import invoiceReducer from '../../redux/invoice/invoice.reducer';
import {ReactComponent as WhatsappIcon} from '../../assets/icon/Payment-history/whatsapp.svg';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../redux/organization/organization.actions';
import {getInvoicePrice} from '../../redux/invoice/invoice.actions';
import {ORGANIZATION_STATE} from '../../redux/organization/organization.types';


const INVOICE_PERIOD = process.env.REACT_APP_API_INVOICE_PERIOD;

class CONFIRM_PAYMENT extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      searchFilter: null,
      filterStatus: null,
      showModal: false,
      errorMessage: null,
      title: null,
      bank: null,
      payment_date: null,
      description: null,
      payment_img: null,
      imageURL: null,
      reason: null,
      showMethod: null,
      tncChecked: false,
      errorImageSize: null,
      isOpen: false,
      formData: {
        id: null,
        title: null,
        bank: null,
        paymentDate: null,
        status: 'waiting',
        reason: null,
        company: null,
        image: null,
        plan: null,
        amount: null,
      },
      showUpgrade: false,
      amount: null,
      order: 'desc',
      sortBy: null,
      resetDatatable: null,
      resetPage: null,
      companyData: [],
      companyPrice: [],
    };
  }

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#F00B38',
      },
    },
    overrides: {
      MuiTypography: {
        root: {
          color: '#656464',
        },
        h6: {
          fontWeight: '600',
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.redirectDashboardIfChild();
    this.props.checkStatus();
    this.props.checkStatusUser();
    // this.props.getInvoicePrice();
    this.fetchPayment();
  }

  componentDidMount() {
    // this.props.getInvoicePrice();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message, isOpen, triggerUpgrade, isSuccessUpgrade, isErrorUpgrade, messageUpgrade} = nextProps;
    const {invoiceTrigger, invoiceIsSuccess, price, orgStatusData, triggerOrg, isSuccessOrg} = nextProps;

    if (isSuccessOrg) {
      if (triggerOrg === ORGANIZATION_STATE.CHECK_STATUS_SUCCESS) {
        this.setState({
          companyData: orgStatusData,
        });
      }
    }

    if (invoiceIsSuccess) {
      if (invoiceTrigger === 'INVOICE_FETCH_PRICE_SUCCESS') {
        this.setState({
          companyPrice: price.prices,
        });
      }
    }

    if (isOpen && isSuccessUpgrade && !isObject(messageUpgrade)) {
      if (
        triggerUpgrade === STATE_UPGRADE.BANK_PAYMENT_SUCCESS
      ) {
        this.toggleModal(false);
        this.props.checkPremium();
        toast('success', messageUpgrade);
      }
    }

    if (isOpen && isErrorUpgrade && messageUpgrade) {
      if (isObject(messageUpgrade)) this.setState({errorMessage: messageUpgrade});
      if (!isObject(messageUpgrade)) {
        if (triggerUpgrade === STATE_UPGRADE.BANK_PAYMENT_FAILED) toast('error', messageUpgrade);
      }
    }

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS || trigger === STATE.UPDATE_SUCCESS || trigger === STATE.DROP_SUCCESS) {
        this.handleResetForm(true);
        this.fetchPayment();
        toast('success', message);
      }

      if (trigger === STATE.UPDATE_SUCCESS) {
        this.setState({
          isEdit: false,
          modalEditShow: false,
        });
        toast('success', message);
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  fetchPayment = () => {
    const {isSuperAdmin} = this.props;
    const {page, limit, searchFilter, filterStatus, order, sortBy} = this.state;
    let params = {page, limit, query: searchFilter, order, sort_by: sortBy};
    if (filterStatus) params = {...params, status: filterStatus};
    (isSuperAdmin) ? this.props.getPayment(params) : this.props.getPaymentByOrg(params);
  }

  checkAmount = () => {
    const {price} = this.props;
    this.setState({amount: price});
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchPayment(),
    );
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        status: '',
        reason: null,
      },
    });
    if (closeModal) this.setState({showModal: false});
  }

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  toggleModal = (type, data = null) => {
    const {showModal} = this.state;
    if (type === 'edit') {
      const status = data[6] === 'waiting' ? null : data[6];
      this.setState({
        showModal: !showModal,
        editing: true,
        formData: {
          id: data[1],
          title: data[2],
          company: data[3],
          bank: data[4],
          paymentDate: data[5],
          reason: data[7],
          image: data[8],
          status,
          plan: data[9],
          amount: data[10],
        },
      });
    } else {
      this.handleResetForm();
      this.setState({
        editing: false,
        showModal: !showModal,
      });
    }
  }

  toggleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteJob(data[1]),
    );
  }

  handleSearch = (event) => {
    this.setState({searchFilter: event.target.value, page: 1}, () => this.fetchPayment());
  }

  handleFilterChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value}, () => this.fetchPayment());
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  }

  handleResetFilter = () => {
    const state = {
      page: 1,
      limit: 10,
      sortBy: null,
      order: 'desc',
      filterStatus: null,
    };
    this.setState(state, () => this.fetchPayment());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  }

  redirectDashboardIfChild = () => {
    const {isSuperAdmin, auth} = this.props;

    if (!isSuperAdmin) {
      if (auth.data.organization.is_child) {
        this.props.history.push('/dashboard');
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {id, status, reason} = this.state.formData;
    const params = {
      ...(status && {status}),
      ...(reason && {reason}),
    };
    this.props.updatePayment(id, params);
  }

  renderModalConfirm = () => {
    const {classes, isLoading} = this.props;
    const {showModal, errorMessage} = this.state;
    const {company, title, bank, paymentDate, status, reason, plan, amount} = this.state.formData;
    const options = [
      {id: 'rejected', name: 'Rejected'},
      {id: 'approved', name: 'Approved'},
    ];

    const paymentDetail = [
      {title: 'Company', value: company},
      {title: 'Subject', value: title},
      {title: 'Bank', value: bank},
      {title: 'Payment Date', value: paymentDate ? convDate(paymentDate) : '-'},
      {title: 'Plan', value: plan + ' Month(s)'},
      {title: 'Amount', value: 'IDR ' + amount},
    ];

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          open={showModal}
          onClose={() => this.toggleModal('close')}
        >
          <form onSubmit={this.handleSubmit}>
            <ModalTitle
              title="Confirm Payment"
              onClose={() => this.toggleModal('close')}
            />
            <DialogContent className={classes.dialogContent}>
              <Typography variant="h6" gutterBottom className={classes.modalSubTitle}>Payment details</Typography>
              <Grid container>
                {paymentDetail.map((detail) => (
                  <React.Fragment key={detail.title}>
                    <Grid item xs={4}><Typography className={classes.textDetail}>{detail.title}</Typography></Grid>
                    <Grid item xs={false} style={{marginRight: 18}}><Typography className={classes.textDetail}>:</Typography></Grid>
                    <Grid item xs={4}><Typography className={classes.textDetail}>{detail.value}</Typography></Grid>
                  </React.Fragment>
                ))}
              </Grid>
              <Typography variant="h6" gutterBottom className={classes.detailTitle}>Confirmation</Typography>
              {
                status !== 'cancelled' &&
                <MSelect
                  autoFocus
                  fullWidth
                  classNameFC={classes.formControl}
                  name="status"
                  label="Update status"
                  placeholder="Confirm status"
                  keyPair={['id', 'name']}
                  options={options}
                  defaultValue={status}
                  onChange={this.handleInputChange}
                  error={errorMessage && errorMessage.status ? errorMessage.status : undefined}
                />
              }
              <MInput
                classNameFC={classes.formControl}
                multiline
                rows={2}
                rowsMax={3}
                name="reason"
                label="Reason"
                placeholder="Fill in reason"
                defaultValue={reason}
                onBlur={this.handleInputChange}
                disabled={status === 'cancelled' ? true : false}
                error={errorMessage && errorMessage.reason ? errorMessage.reason : undefined}
              />
            </DialogContent>
            <DialogActions>
              <MButton
                className={classes.cancelBtn}
                label="Cancel"
                onClick={() => this.toggleModal('close')}
              />
              <MButton
                className={classes.submitBtn}
                label="Update"
                icon={<Send />}
                type={status === 'cancelled' ? 'button' : 'submit'}
                disabled={status === 'cancelled' ? true : false}
                loading={isLoading}
              />
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  renderFilter = () => {
    const {classes, isLoading, isSuperAdmin} = this.props;
    const {filterStatus, searchFilter} = this.state;
    const options = [
      {id: 'waiting', name: 'Waiting'},
      {id: 'rejected', name: 'Rejected'},
      {id: 'approved', name: 'Approved'},
    ];
    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container xs={12} spacing={2} alignItems="center">
          {/* {isSuperAdmin ?
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="outlined"
                name="query"
                label="Search Company"
                placeholder="Search Company"
                value={searchFilter}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton className={classes.searchIcon} position="end">
                      {' '}
                      <Search />
                    </IconButton>
                  ),
                }}
              />
            </Grid> : null
          } */}
          <Grid item>
            <MSelect
              classNameFC={classes.formFilter}
              name="filterStatus"
              label="Filter by Status"
              keyPair={['id', 'name']}
              options={options}
              value={filterStatus}
              onChange={this.handleFilterChange}
              fullWidth={false}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <div className={classes.btnFilterContainer}>
              <MButton
                className={`${classes.submit} ${classes.filterBtn}`}
                label="Reset"
                color="primary"
                icon={<Refresh />}
                onClick={!isLoading ? this.handleResetFilter : undefined}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  toggleModalUpgrade = (status) => {
    if (!status) status = !this.state.showModalCancel;
    this.setState({showModalCancel: status});
  }

  toggleModalPayment = (status) => {
    if (!status) status = !this.state.isOpen;
    if (this.props.handleParentToggle) this.props.handleParentToggle(status);
    this.setState(
        {isOpen: status},
        () => {
          if (!status) this.props.clearState();
          if (!status) this.handleReset();
        },
    );
  }

  handleDateChange = (params) => {
    const {name, value} = params;
    const {errorMessage} = this.state;
    this.setState({
      errorMessage: {...errorMessage, [name]: null},
      [name]: value,
    });
  }

  handleChange = (event) => {
    const {value, name, type} = event.target;
    const {errorMessage} = this.state;

    if (type === 'file') {
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        this.setState({
          errorImageSize: null,
          errorMessage: {...errorMessage, logo: null},
          payment_img: image,
          imageURL: URL.createObjectURL(image),
        });
      } else {
        let message;
        if (imageSizeIsAllowed === false) message = 'Maximum file size is ' + process.env.REACT_APP_MAX_UPLOAD_SIZE_MB + ' MB';
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          payment_img: null,
          imageURL: null,
        });
      }
    } else {
      this.setState({
        errorMessage: {...errorMessage, [name]: null},
        [name]: value,
      });
    }
  };

  handleSubmitPayment = (e) => {
    e.preventDefault();
    const {title, payment_img, bank, payment_date, description} = this.state;
    const dataForm = new FormData();

    if (title) dataForm.append('title', title);
    if (bank) dataForm.append('bank', bank);
    if (description) dataForm.append('description', description);
    if (payment_img) dataForm.append('payment_img', payment_img);
    if (payment_date) dataForm.append('payment_date', moment(payment_date).format('YYYY-MM-DD'));
    this.props.createBankPayment(dataForm);
  }

  handleReset = () => {
    this.setState({
      title: null,
      bank: null,
      payment_date: null,
      description: null,
      payment_img: null,
      imageURL: null,
      reason: null,
      showMethod: null,
      tncChecked: false,
      errorImageSize: null,
      errorMessage: null,
    });
  }

  toggleMethods = (params) => {
    if (this.props.handleParentToggle) this.props.handleParentToggle(params);
    this.setState(
        {showMethod: params},
        () => {
          if (!params) this.props.clearState();
          if (!params) this.handleReset();
        },
    );
  };

  renderBankTransferForm = () => {
    const {classes, isLoadingUpgrade, price, plan} = this.props;
    const {title, bank, payment_date, description, imageURL, errorMessage, errorImageSize, isOpen, amount} = this.state;
    if (price !== amount) this.checkAmount();
    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.toggleModalPayment(false)}
          fullWidth={ true } maxWidth={'md'}
        >
          <ModalTitle
            title={title}
            onClose={() => this.toggleModalPayment(false)}
          />
          <form onSubmit={this.handleSubmitPayment}>
            <DialogContent className={classes.paymentHeader}>
              <Typography variant="h6">Payment Account :</Typography>
              <Typography variant="h7" className={classes.paymentText}>Beneficiary Bank : <strong>BCA</strong></Typography>
              <Typography variant="h7" className={classes.paymentText}>Beneficiary Name : <strong>PT. Smooets Teknologi Outsourcing</strong></Typography>
              <Typography variant="h7" className={classes.paymentText}>Beneficiary Account : <strong>7771645252</strong></Typography>
              <Typography variant="h7" className={classes.paymentText}>Amount : <strong>{amount}</strong></Typography>
            </DialogContent>
            <DialogContent>
              <Typography variant="h6" component="h6">Submit Payment :</Typography>
              <MInput
                classNameFC={classes.formControl}
                autoFocus
                name="title"
                label="Title"
                placeholder="Payment title"
                defaultValue={title}
                onBlur={this.handleChange}
                error={errorMessage && errorMessage.title ? errorMessage.title : undefined}
              />
              <MInput
                classNameFC={classes.formControl}
                name="bank"
                label="Bank"
                placeholder="Bank Name"
                defaultValue={bank}
                onBlur={this.handleChange}
                error={errorMessage && errorMessage.bank ? errorMessage.bank : undefined}
              />
              <MInput
                classNameFC={classes.formControl}
                name="description"
                label="Description"
                placeholder="Description"
                defaultValue={description}
                onBlur={this.handleChange}
                error={errorMessage && errorMessage.description ? errorMessage.description : undefined}
                multiline
                rows={2}
                rowsMax={4}
              />
              <MKeyBoardDatepicker
                className={classes.formControl}
                inputVariant="outlined"
                name="payment_date"
                label="Payment Date"
                value={payment_date}
                onDateChange={this.handleDateChange}
                placeholder="dd-mm-yyyy"
                error={errorMessage && errorMessage.payment_date ? errorMessage.payment_date : undefined}
                maxDate={new Date()}
                autoOk
                fullWidth
              />
              <MInput
                classNameFC={classes.formControl}
                name="payment_img"
                label="Upload Payment"
                type="file"
                onChange={this.handleChange}
                error={
                  errorImageSize ?
                  errorImageSize :
                  (
                    errorMessage && errorMessage.payment_img ?
                    errorMessage.payment_img :
                    undefined
                  )
                }
              />
              {
                imageURL &&
                <CardMedia
                  className={classes.photo}
                  title="Payment"
                  image={imageURL}
                />
              }
            </DialogContent>
            <DialogActions>
              <MButton
                className={classes.defaultBtn}
                label="Submit Payment"
                color="primary"
                type='submit'
                size='small'
                icon={<Send />}
                loading={isLoadingUpgrade}
                onClick={this.handleSubmitPayment}
              />
              <MButton
                label='Back'
                size='small'
                onClick={() => this.toggleModalPayment(false)}
              />
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }

  renderSubscription = () => {
    const {classes, paymentMethod, expiredDate, dueDate, plan, isPremium, isTrial, lastPlan, isPaid} = this.props;
    const {showModalCancel, companyData, companyPrice} = this.state;
    let packet;
    let disablePlan;
    let disablePay;
    if (lastPlan === 1) packet = '1 Month';
    if (lastPlan === 3) packet = '3 Months';
    if (lastPlan === 6) packet = '6 Months';
    if (lastPlan === 12) packet = '12 Months';
    if (isPremium || isTrial) {
      if (isPremium && (getTimeDiff(expiredDate)+1)>INVOICE_PERIOD) {
        disablePlan = false;
        disablePay = true;
      } else if ((getTimeDiff(dueDate)+1)>0) {
        disablePlan = true;
        disablePay = false;
      } else {
        disablePlan = false;
        disablePay = true;
      }
    } else if (!isPremium && !isTrial) {
      if ((getTimeDiff(dueDate)+1)>0) {
        disablePlan = true;
        disablePay = false;
      } else {
        disablePlan = false;
        disablePay = true;
      }
    }
    return (
      <div>
        <h5 className={classes.cardTitle}>Premium Subscription Status</h5>
        <div
          className={`
            ${classes.subContainer} 
            ${!isPremium ? classes.red : classes.green}
          `}
        >
          { !isPremium ? <Cancel/> : <CheckCircle/> }
          <Typography className={classes.subStatus}>
            { !isPremium ? ' Your Subscription is limit to 5 user' : ' Your Premium Subscription is Active' }
          </Typography>
        </div>
        <div
          className={`
            ${classes.subContainer} 
            ${!isPremium ? classes.red : classes.green}
          `}
        >
          { !isPremium ? <Cancel/> : <CheckCircle/> }
          <Typography className={classes.subStatus}>
            Payment plan:
            { !isPremium ? !isTrial ? ' Expired' : ' Free Account' : ' ' + packet }
          </Typography>
        </div>
        {lastPlan !== plan ? <div
          className={`
            ${classes.subContainer} 
            ${classes.yellow}
          `}
        >
          { <Warning /> }
          <Typography className={classes.subStatus}>
            Next Payment plan:
            {' ' + plan + ' Month(s)' }
          </Typography>
        </div> : null}
        <Grid container xs={12} className={classes.renewSubscription}>
          <Tooltip title="Update payment info">
            <MButton
              className={classes.updateBtn}
              label="Pay Now"
              disabled={disablePay || isPaid}
              onClick={() => {
                this.toggleUpgrade(true, 'xendit');
              }}
            />
          </Tooltip>
          { <Tooltip title="Change your subscription plan">
            <MButton
              className={classes.defaultBtn}
              color="primary"
              label={!isPremium ? 'Upgrade to Premium' : 'Change Plan'}
              icon={!isPremium ? null : <Update />}
              disabled={disablePlan}
              onClick={() => {
                this.toggleUpgrade(true, 'plan');
              }}
            />
          </Tooltip>}
          <Tooltip title="Info">
            <MButton
              className={classes.infoBtn}
              color="primary"
              label = {<InfoOutlined />}
              onClick={() => {
                showPopup(
                    'info',
                    'You can change the subscription plan as long as there is no valid invoice to pay',
                );
              }}
            />
          </Tooltip>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          {companyData?.partner_id !== null ? (
            <Grid item xs={6} md={4}>
              <Paper variant='outlined' style={{borderRadius: 18, padding: 16}}>
                <Grid container spacing={2} justifyContent="space-between" style={{marginBottom: 4}}>
                  <Grid item xs={4}>
                    <Typography classname={classes.textInfoBold}>Partner</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography classname={classes.textInfo}>{companyData?.partner_name ? companyData?.partner_name : '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="space-between" style={{marginBottom: 4}}>
                  <Grid item xs={4}>
                    <Typography classname={classes.textInfoBold}>Whatsapp</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography classname={classes.textInfo}>{companyData?.partner_phone ? companyData?.partner_phone : '-'}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ): (null)}
          <Grid item xs={6} md={4}>
            <Paper variant='outlined' style={{borderRadius: 18, padding: 16, height: '100%'}}>
              <Grid container spacing={2} justifyContent="space-between" style={{marginBottom: 4}}>
                <Grid item xs={4}>
                  <Typography classname={classes.textInfoBold}>Payment Method</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography classname={classes.textInfo}>{!isPremium ? '-' : paymentMethod ? paymentMethod : 'Bank Transfer'}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={4}>
                  <Typography classname={classes.textInfoBold}>Valid Until</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography classname={classes.textInfo}>
                    {
                      isTrial ? '-' :
                      expiredDate && (getTimeDiff(expiredDate)+1) > 0 ?
                      convDate(expiredDate, 'DD MMMM YYYY') + ' (' + (getTimeDiff(expiredDate)+1) + ' days remaining)' :
                      'Expired'
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {companyData?.partner_id !== null ? (
            <Grid item xs={6} md={4}>
              <Paper variant='outlined' style={{borderRadius: 18, padding: 16, height: '100%'}}>
                <Grid container spacing={2} justifyContent="space-between" style={{marginBottom: 4}}>
                  <Grid item xs={4}>
                    <Typography classname={classes.textInfoBold}>Discount</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography classname={classes.textInfo}>{companyData?.discount_amount ? companyData?.discount_amount : '-'}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="space-between" style={{marginBottom: 4}}>
                  <Grid item xs={4}>
                    <Typography classname={classes.textInfoBold}>Valid Until Discount</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography classname={classes.textInfo}>{companyData?.valid_until ? companyData?.valid_until : '-'}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ): (null)}
        </Grid>
      </div>
    );
  }

  renderUpgradeModal = () => {
    const {showUpgrade, showMethod} = this.state;
    return (
      <UpgradeAccountModal
        isOpen={showUpgrade}
        showMethod={showMethod}
        handleParentToggle={this.toggleUpgrade}
      />
    );
  };

  toggleUpgradeModal = (status) => {
    this.setState({
      showUpgrade: status,
    });
  }

  toggleUpgrade = (status, method) => {
    const {companyData} = this.state;
    if (companyData.discount_status === 'inactive') {
      return showPopup('info', 'You still have an invitation request from partner. Please respond it first.');
      // return this.props.history.push('/dashboard');
    }
    if (!status) status = !this.state.showUpgrade;
    if (!method) method = null;
    this.setState({showUpgrade: status, showMethod: method});
  }

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  toggleResetDatatable = (status) => {
    this.setState(
        {resetDatatable: status},
        () => this.setState({resetDatatable: null}),
    );
  }

  render() {
    const {classes, isLoading, paymentData, paymentMeta, isSuperAdmin, isPremium} = this.props;
    const {resetDatatable, resetPage} = this.state;

    const columns = [
      {name: 'id', label: 'id', display: false},
      {name: 'title', label: 'Title', display: false},
      {name: 'organization', label: 'Company', display: (isSuperAdmin) ? true : false},
      {name: 'bank', label: 'Bank', display: true},
      {
        name: 'payment_date',
        label: 'Payment Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          let color;
          switch (value) {
            case 'approved':
              color = 'green';
              break;
            case 'rejected':
            case 'cancelled':
              color = 'red';
              break;
            default:
              color = 'grey';
              break;
          }
          return <strong style={{color: color, textTransform: 'capitalize'}}>{value}</strong>;
        },
      },
      {name: 'reason', label: 'Reason', display: (isSuperAdmin) ? false : false},
      {
        name: 'image',
        label: 'Payment',
        display: false,
        customBodyRender: (value) => {
          const {classes} = this.props;
          return (
            <Link href={value} target="_blank" rel="noopener noreferrer">
              <CardMedia
                className={`${classes.modalAvatar} ${classes.imgSize}`}
                alt="confirmation payment picture"
                image={value}
                title='Check Out'
                component='image'
              />
            </Link>
          );
        },
      },
      {
        name: 'plan',
        label: 'Plan',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? value + ' Month(s)' : '-'}</div>;
        },
      },
      {
        name: 'amount',
        label: 'Amount',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? 'IDR ' + value: '-'}</div>;
        },
      },
    ];

    return (
      <div className={classes.root}>
        {
          !isSuperAdmin &&
          (
            <Paper variant='outlined' className={classes.paper}>
              {this.renderSubscription()}
              {this.renderUpgradeModal()}
            </Paper>
          )
        }
        {this.renderFilter()}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Datatable
              title={'Payment History'}
              loading={isLoading}
              dataSource={paymentData}
              total={paymentMeta ? paymentMeta.total : 0}
              page={paymentMeta ? paymentMeta.page : 1}
              columns={columns}
              creatable={false}
              handleEdit={isSuperAdmin ? (params) => this.toggleModal('edit', params) : undefined}
              handleReload={(params) => this.handleReload(params)}
              handleSearch={(params) => this.handleSearch(params)}
              hideActions={!isSuperAdmin ? true : false}
              toggleResetAll={resetDatatable}
              toggleResetPage={resetPage}
            />
            {this.renderModalConfirm()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const stateToProps = ({auth, confirmPayment, upgradeAccount, invoice, organization}) => ({
  auth: auth,
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: confirmPayment.isLoading,
  isError: confirmPayment.isError,
  isSuccess: confirmPayment.isSuccess,
  message: confirmPayment.message,
  trigger: confirmPayment.trigger,
  paymentData: confirmPayment.paymentData,
  paymentMeta: confirmPayment.paymentMeta,
  upgradeLoading: upgradeAccount.isLoading,
  isPremium: upgradeAccount.premium,
  isTrial: upgradeAccount.trial,
  paymentMethod: upgradeAccount.paymentMethod,
  expiredDate: upgradeAccount.expiredDate,
  dueDate: upgradeAccount.dueDate,
  plan: upgradeAccount.plan,
  lastPlan: upgradeAccount.lastPlan,
  price: invoice.total,
  isLoadingUpgrade: upgradeAccount.isLoading,
  isErrorUpgrade: upgradeAccount.isError,
  isSuccessUpgrade: upgradeAccount.isSuccess,
  messageUpgrade: upgradeAccount.message,
  triggerUpgrade: upgradeAccount.trigger,
  isPaid: upgradeAccount.payment,

  isSuccessOrg: organization.isSuccess,
  triggerOrg: organization.trigger,
  orgStatusData: organization.orgStatusData,

  price: invoice.invoicePriceData,
  invoiceTrigger: invoice.trigger,
  invoiceIsSuccess: invoice.isSuccess,
});

const dispatchToProps = (dispatch) => ({
  getPayment: (params) => dispatch(getPayment(params)),
  createPayment: (params) => dispatch(createPayment(params)),
  updatePayment: (id, params) => dispatch(updatePayment(id, params)),
  deletePayment: (id) => dispatch(deletePayment(id)),
  getPaymentByOrg: (params) => dispatch(getPaymentByOrg(params)),
  clearState: () => dispatch(clearState()),
  createBankPayment: (params) => dispatch(createBankPayment(params)),
  clearState: () => dispatch(clearState()),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  getInvoicePrice: () => dispatch(getInvoicePrice()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(CONFIRM_PAYMENT));
