/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable keyword-spacing, comma-dangle, semi, no-trailing-spaces */
import {initialState, IMPORT_ACTIVITY_STATE as STATE} from './importActivity.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.FETCH_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        importActivityData: action.payload.data,
        importActivityMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.UPDATE_IMPORT_NOTIF:
      return {
        ...state,
        isLoading: false,
        isSuccess: null,
        trigger: action.type,
      };
    case STATE.UPDATE_IMPORT_NOTIF_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_IMPORT_NOTIF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        importNotificationData: [
          ...state.importNotificationData,
          {
          ...action.payload,
        }],
        trigger: action.type,
      };
      case STATE.UPDATE_IMPORT_NOTIF_STATUS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: true,
          importNotificationData: state.importNotificationData?.length > 1 ? (
            state.importNotificationData?.map((item, i) => {
              if(i === state.importNotificationData.length - 1) {
                return {
                  ...item,
                  file_path: action.payload.data.file_path,
                  status: action.payload.data.status,
                  id_export: action.payload.data.id_export,
                  body: action.payload.notification.body
                }
              } 
              
              return item;
            })
          ) : state.importNotificationData.map((item) => {
            console.log('payload', action.payload)
            console.log('item', item)
            return {
              ...item,
              file_path: action.payload.data.file_path,
              status: action.payload.data.status,
              id_export: action.payload.data.id_export,
              body: action.payload.notification.body
            };
          }),
          trigger: action.type,
        };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
