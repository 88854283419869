/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  tableTitle: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#656464',
    fontFamily: 'Poppins !important',
    marginTop: '-2px !important',
    marginLeft: '-8px !important',
  },
  tableTooltip: {
    fontSize: '14px',
  },
  checkInTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  checkInFalse: {
    color: '#FFCC00',
    fontSize: 20,
  },
  tableHeaderIcon: {
    fontSize: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    marginBottom: 30,
    textalign: 'center',
    fontWeight: 600,
  },
  fade: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 600,
  },
  modalItemSpacing: {
    margin: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  modalCloseBtn: {
    float: 'right',
    marginBottom: 10,
  },
  dialogContent: {
    padding: 20,
  },
  dialogContentGuide: {
    paddingTop: '20px !important',
  },
  dialogFooter: {
    padding: 20,
  },
  cardColor: {
    backgroundColor: '#ffffff',
    width: '70%',
  },
  imgSize: {
    width: 128,
    height: 128,
  },
  modalStyle1: {
    overflow: 'scroll',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterBtn: {
    'marginTop': '25px',
    'marginRight': '20px',
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#FFFFFF',
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 150,
  },
  detailBtn: {
    'backgroundColor': '#00AFF0',
    'color': '#fff',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnExport: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  tableImg: {
    height: 50,
    width: 50,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  accordionTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#656464',
  },
  accordionValue: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusRequest: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusOngoing: {
    fontSize: 14,
    fontWeight: '700',
    color: '#FFC226',
  },
  statusRejected: {
    fontSize: 14,
    fontWeight: '700',
    color: '#F00B38',
  },
  statusCompleted: {
    fontSize: 14,
    fontWeight: '700',
    color: '#008000',
  },
  timelineDate: {
    fontSize: 12,
    fontWeight: '400',
    color: '#737373',
  },
  informationTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: '#656464',
    marginBottom: 8,
  },
  informationDetail: {
    fontSize: 14,
    fontWeight: '400',
    color: '#656464',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  accordionDetail: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  historyText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#656464',
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  dropZoneArea: {
    padding: 15,
    minHeight: 160,
    minWidth: 500,
  },
  dropZoneText: {
    color: '#8D8D97',
    fontSize: '1.4em',
  },
  dropZoneIcon: {
    color: '#8D8D97',
  },
  hidden: {
    minWidth: 500,
    visibility: 'hidden',
    height: 0,
    minHeight: 0,
  },
});
